import React from 'react';

import './maxFileSizeModal.scss';
import ModalWrapper from '../../../../uiComponents/ModalWrapper';
import { ReactComponent as Exclamation } from '../../../../assets/exclamation-circle.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/navBar/close.svg';

// eslint-disable-next-line react/prop-types
const MaxFileSizeModal = ({ close, fieldForChange }) => {
  const handleCloseModal = () => {
    close(false);
  };
  return (
    <div className="maxFileSizeModal">
      <ModalWrapper
        title="max file size"
        toggleShow={close}
      >
        <div className="maxFileSize">
          <div className="exclamationBox">
            <div className="exclamation">
              <Exclamation />
            </div>
            <div className="fileSizeText">
              {fieldForChange === 'LI'
                ? (<div>the maximum file size should not exceed 350 MB</div>)
                : (<div>the maximum file size should not exceed 100 MB</div>)}
            </div>
          </div>
          <div
            className="closeButton"
            onClick={handleCloseModal}
          >
            Close
            <CloseIcon className="closeIcon" />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default MaxFileSizeModal;
