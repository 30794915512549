import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Switch = ({
  checked, onChange, name, black,
}) => {
  return (
    <div className={`app-switch ${black ? 'app-switch__black' : ''}`}>
      <label>
        <input
          hidden
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
        />
        <span className="app-switch__slide" />
      </label>
    </div>
  );
};

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  name: PropTypes.string,
  black: PropTypes.bool,
};

export default Switch;
