import React from 'react';
import shp2kml from 'utils/shp2kml';
import PropTypes from 'prop-types';
import './uploadNewFileModal.scss';

import { ReactComponent as Folders } from 'assets/creatingProject/uploadIcon.svg';
import uploadBlack from 'assets/uploadsFiles/uploadblack.svg';

import fileUploader from 'utils/fileUploader';
import ModalWrapper from 'uiComponents/ModalWrapper';
import parsingFiles from 'utils/parsingFiles';

const MAX_SIZE = 104857600;

const UploadNewFileModal = ({
  close,
  formats,
  fieldForChange,
  setNewProjectData,
  setFileCounter,
  setActiveModal,
  onChange,
  loadRef,
  setShowUploadedDone,
  setMaxFileSizeModal,
  setNotGeoInPhoto,
  setShowUploadModal,
  type,
  newProject,
}) => {
  const getAllSize2 = {
    droneImages: {
      TH: 0, //newProject.droneImages.filter((file) => file.fileType === 'TH').reduce((acc, file) => acc + file.size, 0),
      HD: 0, //newProject.droneImages.filter((file) => file.fileType === 'HD').reduce((acc, file) => acc + file.size, 0),
      LI: newProject.droneImages.filter((file) => file.fileType === 'LI').reduce((acc, file) => acc + file.size, 0),
    },
    phoneImages: {
      TH: 0, //newProject.phoneImages.filter((file) => file.fileType === 'TH').reduce((acc, file) => acc + file.size, 0),
      HD: 0, //newProject.phoneImages.filter((file) => file.fileType === 'HD').reduce((acc, file) => acc + file.size, 0),
    },
    gisFiles: newProject.gisFiles.reduce((acc, file) => acc + file.size, 0),
  };

  const isValid = (item, files) => {
    const filesSize = [...files].reduce((acc, file) => acc + file.size, 0);
    return filesSize + item < MAX_SIZE;
  };

  const updateImages = async (files) => {
    setShowUploadModal(false);
    setShowUploadedDone(true);
    const filesAfterParsing = await parsingFiles(files, type);
    if (filesAfterParsing.filter((e) => !e.geo).length) {
      setNotGeoInPhoto(true);
    }
    return [...filesAfterParsing];
  };

  const typesActions = {
    droneImages: {
      TH: updateImages,
      HD: updateImages,
      LI: (files) => {
        [...files].forEach((file) => {
          if (file.size + getAllSize2.droneImages.LI < 367001600) {
            file.fileType = 'LI';
            setShowUploadModal(false);
            setShowUploadedDone(true);
          } else {
            file.fileType = 'LI';
            setMaxFileSizeModal(true);
          }
        });
        return [...files];
      },
    },
    phoneImages: {
      TH: updateImages,
      HD: updateImages,
    },
    gisFiles: async (files) => {
      setShowUploadModal(false);
      setShowUploadedDone(true);
      let temp = [...files];
      for (let index = 0; index < temp.length; index += 1) {
        const typeMatch = temp[index].name.match(/\.([^.]*)$/);
        const fileType = typeMatch[1].toLowerCase();
        if (fileType === 'shp' || fileType === 'zip') {
          try {
            // eslint-disable-next-line no-await-in-loop
            const kml = await shp2kml(temp[index]);
            temp[index] = kml;
          } catch (err) {
            temp[index] = null;
          }
        }
      }
      temp = temp.filter((item) => item);
      await fileUploader({ temp }, 'temp', loadRef);
      return temp;
    },
  };

  const setFiles = async (files) => {
    if (!files.length) return;
    let resultFiles = [];
    setFileCounter(files.length);

    if (typesActions[fieldForChange][type]) {
      if (!isValid(getAllSize2[fieldForChange][type], files)) {
        setMaxFileSizeModal(true);
      } else {
        const filesBeforePars = await typesActions[fieldForChange][type](files);
        resultFiles.push.apply(resultFiles, [...filesBeforePars]);
      }
    }

    if (!onChange) {
      if (fieldForChange === 'gisFiles') {
        if (isValid(getAllSize2.gisFiles, files)) {
          resultFiles = await typesActions.gisFiles(files);
        } else {
          setMaxFileSizeModal(true);
        }
      }

      setNewProjectData((data) => ({
        ...data,
        [fieldForChange]: [
          ...data[fieldForChange],
          ...resultFiles,
        ],
      }));
    } else {
      onChange([...files]);
      setActiveModal('uploadingDoneModal');
    }
  };

  return (
    <div className="modalWrapper">
      <ModalWrapper
        title="Upload documents"
        toggleShow={close}
      >
        <label htmlFor="drugAndDrop" className="drugAndDrop">
          <input
            type="file"
            name="drugAndDrop"
            id="drugAndDrop"
            accept={formats}
            multiple
            onChange={(event) => {
              setFiles(event.target.files);
            }}
          />
          <div className="loadingBar">
            <div className="boxWithSvg">
              <img className="uploadBlack" alt="uploadBlack" src={uploadBlack} />
            </div>
            <div className="textButtonWrap">
              <div className="textLoadingBar">
                Please, select files to upload
              </div>
              <div className="button">
                Browse files
                <Folders />
              </div>
            </div>
          </div>
          <div className="informationFormat">
            <div>
              <div className="formatTitleBlock">
                <div className="formatTitle">
                  Supported formats:
                </div>
                <div className="format">
                  {formats.map((format, index) => (
                    <div key={index} className="formatType">
                      {format}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <div className="maxFileSizeText">Max file size:</div>
              {type === 'LI' && (<div className="sizeMb">350 Mb</div>)}
              {type === 'gisFiles' && (<div className="sizeMb">100 Mb</div>)}
              {(type !== 'gisFiles' && type !== 'LI') && (<div className="sizeMb">No limits</div>)}
            </div>
          </div>
        </label>
      </ModalWrapper>
    </div>
  );
};

UploadNewFileModal.propTypes = {
  setActiveModal: PropTypes.func,
  setFileCounter: PropTypes.func,
  close: PropTypes.func.isRequired,
  formats: PropTypes.arrayOf(PropTypes.string),
  setNewProjectData: PropTypes.func,
  fieldForChange: PropTypes.string,
  onChange: PropTypes.func,
  loadRef: PropTypes.objectOf(PropTypes.any),
  setShowUploadedDone: PropTypes.func,
  setNotGeoInPhoto: PropTypes.func,
  setShowUploadModal: PropTypes.func,
  newProject: PropTypes.objectOf(PropTypes.any),
  setMaxFileSizeModal: PropTypes.func,
  type: PropTypes.string,
};

export default UploadNewFileModal;
