export const isValidProperties = (project) => {
  let block = false;
  const errors = {};

  if (project.title.trim().length < 3) {
    block = true;
    errors.title = 'Must be at least 3 characters';
  }

  if (!project.company) {
    block = true;
    errors.company = 'Company must be selected';
  }

  if (!project.type) {
    block = true;
    errors.type = 'Type must be selected';
  }

  return { block, errors };
};

export const isValidLocation = (project) => {
  let block = false;
  const errors = {};

  if (project.location.coords?.lat === undefined || project.location.coords?.lat === null) {
    block = true;
    errors.location = 'You need to specify a location!';
  }

  return { block, errors };
};

export const isValidUpload = (project) => {
  let block = false;
  const errors = {};

  if (!project.phoneImages.length) {
    block = true;
    errors.phone = 'Files not uploaded';
  }

  if (!project.droneImages.length) {
    block = true;
    errors.drone = 'Files not uploaded';
  }

  if (!project.gisFiles.length) {
    block = true;
    errors.gis = 'Files not uploaded';
  }

  return { block, errors };
};

export const isValidInviteUsers = (project) => {
  let block = false;
  const errors = {};

  if (!project.teamMembers.length) {
    block = true;
    errors.title = 'No invited users';
  }

  return { block, errors };
};
