import React, { useState } from 'react';
import propTypes from 'views/projectCreate/Steps/_types';
import Navigation from 'views/projectCreate/Steps/Navigation';
import GeoAutocomplete from 'uiComponents/GeoAutocomplete/GeoAutocomplete';
import { ReactComponent as TickInInput } from 'assets/creatingProject/tickInInput.svg';
import { isValidLocation } from './_validation';

const Location = ({
  activeStep,
  setActiveStep,
  toggleShowMap,
  newProject,
}) => {
  const [errors, setErrors] = useState({});
  const isValid = isValidLocation(newProject.get);

  return (
    <>
      <div className="Location">
        <div className="Location__wrapper">
          <div className="Location__wrapper-title">Please, select project location:</div>
          <div className="Location__wrapper-form">
            <div style={{
              position: 'relative',
            }}
            >
              <GeoAutocomplete
                value={newProject.get.location.coords?.lat ? `${newProject.get.location.coords.lat.toFixed(6)}, ${newProject.get.location.coords.lng.toFixed(6)}` : newProject.get.location.address}
                placeholder="ex. 57.261280, 85.688346"
                className={newProject.get.location.coords?.lat ? 'Location__wrapper-form-input active' : 'Location__wrapper-form-input dis'}
                onChange={({ address, lat, lng }) => newProject.set((prevState) => ({
                  ...prevState,
                  location: {
                    address,
                    coords: { lat, lng },
                  },
                }))}
              />
              {newProject.get.location.coords?.lat && <TickInInput className="tickInInput" />}
            </div>
            {errors.location && <span className="error-alert">{errors.location}</span>}
          </div>
          <div className="Location__wrapper-choice">
            <div className="Location__wrapper-choice-line" />
            <div className="Location__wrapper-choice-text">Or</div>
            <div className="Location__wrapper-choice-line" />
          </div>
          <div className="Location__wrapper-openMapBtn" onClick={() => toggleShowMap(true)}>Select on map</div>
        </div>
      </div>
      <Navigation
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        next={!isValid.block}
        handlerError={() => setErrors(isValid.errors)}
      />
    </>
  );
};

Location.propTypes = propTypes;

export default Location;
