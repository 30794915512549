import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './infoPhotoModal.scss';

import { ReactComponent as Close } from 'assets/navBar/close2.svg';
import { ReactComponent as PhotoTitle } from 'assets/navBar/photoTitle.svg';
import { ReactComponent as StarActiveIcon } from 'assets/_v2.0/project/starActiveIcon.svg';
import { ReactComponent as StarIcon } from 'assets/project/starIcon2.svg';
import { ReactComponent as DoubleCaret } from 'assets/navBar/3dots.svg';

import useApp from 'hooks/useApp';
import MaterialCollapse from '@material-ui/core/Collapse';

const InfoPhotoModal = ({
  title, photo, activeProject, onClick,
}) => {
  const {
    favoriteImages,
    favoriteImagesAPI,
    previewProject,
  } = useApp();
  const [open, toggleOpen] = useState(false);

  const handleChangeFavorite = () => favoriteImagesAPI.change(photo, activeProject._id);

  const imgUrl = () => {
    if (!activeProject._id && !photo?._id) return false;

    let url = '';
    if (previewProject) {
      url = `/view/${activeProject._id || ''}?image=${photo._id || ''}`;
    } else {
      url = `/project/${activeProject._id || ''}?image=${photo._id || ''}`;
    }

    return url;
  };

  const statsRows = [];

  if (photo?.csv) {
    const pole = [];
    const components = [];
    const defects = [];
    const defectsRating = [];

    photo.csv.polygons.forEach((polygon) => {
      if (polygon.info.poleType) {
        pole.push(polygon.info.poleType);
      }
      if (polygon.info.poleComponent) {
        components.push(polygon.info.poleComponent);
      }
      if (polygon.info.defectList) {
        defects.push(polygon.info.defectList);
      }
      if (polygon.info.defectRating) {
        defectsRating.push(polygon.info.defectRating);
      }
    });

    statsRows.push.apply(statsRows, [
      { field: 'Count of pole:', value: pole.length },
      { field: 'Components:', value: components.length },
      { field: 'Defects:', value: defects.length },
    ]);

    if (defectsRating.length) {
      switch (Math.max(...defectsRating)) {
        case 1:
          statsRows.push({ field: 'Defect Rating:', value: 'Low' });
          break;
        case 2:
          statsRows.push({ field: 'Defect Rating:', value: 'Medium' });
          break;
        case 3:
          statsRows.push({ field: 'Defect Rating:', value: 'High' });
          break;
        default:
          statsRows.push({ field: 'Defect Rating:', value: 'N/A' });
      }
    }
  }

  return (
    <div className="infoPhotoModal">
      <div className="photoMarkerInfo">
        <div className="photoTitle">
          <div className="containerTitleIcon">
            <div
              className="photoTitleIcon"
            >
              <PhotoTitle />
            </div>
            Photo Thumbnail
          </div>
          <div
            className="closeIcon"
            onClick={onClick}
          >
            <Close />
          </div>
        </div>
        <div className="containerWithPhoto">
          <div
            className="photo"
            style={{ backgroundImage: `url(${photo?.thumb || photo})` }}
          />
          <div className="containerUnderPhoto">
            <div className="containerUnderPhoto_start">
              {!previewProject && activeProject?._id && photo?._id ? (
                <label className="projectImage__favorite">
                  <input
                    type="checkbox"
                    checked={favoriteImages[activeProject?._id]?.includes(photo?._id)}
                    onChange={handleChangeFavorite}
                  />
                  <StarActiveIcon className="active-star" />
                  <StarIcon className="star" />
                </label>
              ) : null}
              <div className="line" />
              {title}
            </div>
            {statsRows.length ? (
              <div className="infoPhotoModal__polygonInfoBtn">
                <div
                  className="infoPhotoModal__polygonInfoBtn-head"
                  onClick={() => toggleOpen(!open)}
                >
                  <DoubleCaret />
                </div>
              </div>
            ) : null}
          </div>
          {statsRows.length ? (
            <div className="infoPhotoModal__polygonInfo">
              <MaterialCollapse in={open} timeout="auto" unmountOnExit>
                {statsRows.map((row) => (
                  <div className="infoPhotoModal__polygonInfo-row" key={row.field}>
                    <div className="infoPhotoModal__polygonInfo-col">{row.field}</div>
                    <div className="infoPhotoModal__polygonInfo-col"><span title={row.value}>{row.value}</span></div>
                  </div>
                ))}
              </MaterialCollapse>
            </div>
          ) : null}
        </div>
        <div className="buttonsBottom">
          <div className="linkClass">
            <div
              className="cancelBtn"
              onClick={onClick}
            >
              Cancel
            </div>
          </div>
          {activeProject ? (
            <a className="linkClass view" href={imgUrl()}>
              <div
                className="viewBtn"
              >
                View details
              </div>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

InfoPhotoModal.propTypes = {
  photo: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
  ]),
  activeProject: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default InfoPhotoModal;
