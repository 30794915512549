import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as ResetPasswordIcon } from 'assets/resetPasswordIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/adminTable/infoIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/adminTable/closeIcon.svg';

import ModalWrapper from 'uiComponents/ModalWrapper';
import isValidPassword from './validation';

// eslint-disable-next-line no-unused-vars
const ResetPasswordConfirm = ({ toggleShow, user, usersAPI }) => {
  const [error, setError] = useState(null);
  const [password, setPassword] = useState({
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  const handleInputChange = (e) => {
    setPassword((s) => ({ ...s, [e.target.name]: e.target.value }));
  };

  const handleResetDone = () => {
    const valid = isValidPassword(password);

    if (valid.block) {
      setError(valid.errors);
      return false;
    }

    usersAPI.changePassword(user, password.oldPassword, password.newPassword);
    ReactDOM.unmountComponentAtNode(document.getElementById('modal-portal'));

    return false;
  };

  return (
    <div className="ResetPasswordConfirm">
      <ModalWrapper toggleShow={toggleShow} title="Reset Password">
        <div className="ModalConfirm__content-info">
          <div className="info-icon">
            <InfoIcon />
          </div>
          <div className="accent">
            <input
              type="password"
              name="oldPassword"
              placeholder="Old password"
              value={password.oldPassword}
              onChange={handleInputChange}
            />
            {error?.oldPassword ? <span className="error-alert">{error.oldPassword}</span> : null}
            <input
              type="password"
              name="newPassword"
              placeholder="New password"
              value={password.newPassword}
              onChange={handleInputChange}
            />
            {error?.newPassword ? <span className="error-alert">{error.newPassword}</span> : null}
            <input
              type="password"
              name="repeatNewPassword"
              placeholder="Repeat new password"
              value={password.repeatNewPassword}
              onChange={handleInputChange}
            />
            {error?.repeatNewPassword ? <span className="error-alert">{error.repeatNewPassword}</span> : null}
          </div>
        </div>
        <div className="ModalConfirm__content-action">
          <div className="action-btn delete" onClick={handleResetDone}>Reset <ResetPasswordIcon /></div>
          <div className="action-btn cancel" onClick={toggleShow}>Cancel <CloseIcon /></div>
        </div>
      </ModalWrapper>
    </div>
  );
};

ResetPasswordConfirm.propTypes = {
  toggleShow: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  usersAPI: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ResetPasswordConfirm;
