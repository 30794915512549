import React, { useState } from 'react';
import types from './_types';

import Properties from './Properties';
import Location from './Location';
import Upload from './Upload';
import Invite from './Invite';
import GeneralInfo from './GeneralInfo';

const Steps = ({
  newProject, toggleShowMap, setNewProject, setConfirmDeleteProject,
}) => {
  const steps = {
    properties: Properties,
    location: Location,
    upload: Upload,
    invite: Invite,
    generalInfo: GeneralInfo,
  };
  const [activeStep, setActiveStep] = useState('properties');

  if (typeof steps[activeStep] === 'undefined') return null;

  return React.createElement(steps[activeStep], {
    activeStep,
    setActiveStep,
    toggleShowMap,
    newProject,
    setNewProject,
    setConfirmDeleteProject,
  });
};

Steps.propTypes = types;

export default Steps;
