import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getArea, getDistance } from '../../helpers';
import AreaInfo from './AreaInfo';

const Area = ({
  mapApi, drawing, setDrawing, toggle,
}) => {
  if (!mapApi.maps) {
    return null;
  }

  const [showAreaInfo, toggleAreaInfo] = useState(false);
  const [unit, setUnit] = useState('m');
  const [unitSquare, setUnitSquare] = useState('m');
  const [info, setInfo] = useState({
    area: 0,
    length: 0,
  });

  const compute = (overlay, u, s) => ({
    area: getArea(mapApi.maps.geometry.spherical.computeArea(overlay.getPath()), s),
    length: getDistance(mapApi.maps.geometry.spherical.computeLength(overlay.getPath()), u),
  });

  useEffect(() => {
    if (!drawing) {
      const newDrawing = new mapApi.maps.drawing.DrawingManager({
        drawingMode: mapApi.maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        polygonOptions: {
          editable: true,
          fillColor: '#EAE5FE',
          fillOpacity: 0.35,
          strokeColor: '#003FFF',
          strokeOpacity: 1,
        },
      });
      newDrawing.setMap(mapApi.map);
      setDrawing({
        manager: newDrawing,
      });
      mapApi.maps.event.addListener(newDrawing, 'overlaycomplete', (event) => {
        setInfo(compute(event.overlay, unit, unitSquare));
        newDrawing.setDrawingMode(null);
        setDrawing({
          overlay: event.overlay,
          manager: newDrawing,
        });
        toggleAreaInfo(true);

        mapApi.maps.event.addListener(event.overlay.getPath(), 'set_at', () => {
          setInfo(compute(event.overlay, unit, unitSquare));
        });

        mapApi.maps.event.addListener(event.overlay.getPath(), 'insert_at', () => {
          setInfo(compute(event.overlay, unit, unitSquare));
        });
      });
    }
  }, [mapApi]);

  const handleChangeUnit = (value) => {
    setUnit(value);
    setInfo(compute(drawing.overlay, value, unitSquare));
  };

  const handleChangeUnitSquare = (value) => {
    setUnitSquare(value);
    setInfo(compute(drawing.overlay, unit, value));
  };

  const handleCloseInfo = () => {
    toggleAreaInfo(false);
    drawing.overlay.setMap(null);
    setDrawing(null);
    setInfo({
      area: 0,
      length: 0,
    });
    toggle(false);
  };

  if (showAreaInfo) {
    return (
      <AreaInfo
        unit={unit}
        unitSquare={unitSquare}
        info={info}
        switchUnit={handleChangeUnit}
        switchUnitSquare={handleChangeUnitSquare}
        close={handleCloseInfo}
      />
    );
  }
  return null;
};

Area.propTypes = {
  mapApi: PropTypes.objectOf(PropTypes.any).isRequired,
  drawing: PropTypes.objectOf(PropTypes.any),
  setDrawing: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default Area;
