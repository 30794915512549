export const polygonFields = {
  poleType: ['Monopole', 'Bipole', 'Lattice', 'H-Frame'],
  poleComponent: [
    'Insulator String',
    'Top Crossarm',
    'Middle Crossarm',
    'Bottom Crossarm',
    'Spacer Pendulum',
    'OPGW Tension',
    'OPGW Suspension',
    'Dervaux Marking Light',
    'OBSTA Marking Light',
    'Warning Sphere',
    'Fittings',
    // old
    // 'Insulators',
    // 'Spacers',
    // 'Conductor OPGW',
  ],
  defectList: [
    'None',
    'Low',
    'Medium',
    'High',
    // old
    // 'Pollution',
    // 'Cracks on Sheds',
    // 'Cracks on Housing',
    // 'Broken Housing',
    // 'Corona',
    // 'Rust',
    // 'Loose Bolts/nuts',
    // 'Broken Fitting',
    // 'Loose Clamp',
    // 'Missing Clamp',
    // 'Wrongly Tight Clamp',
    // 'Cut Strand',
    // 'Hot Spot',
  ],
  defectRating: ['low', 'medium', 'high', 'N/A'],
};

export const filterOptions = {
  ...polygonFields,
  imageType: ['droneImages', 'phoneImages', 'favoriteImages'],
};

export const initialFilterImage = Object.keys(filterOptions).reduce((pre, key) => {
  pre.push.apply(pre, [...filterOptions[key].map((item, index) => {
    if (key === 'defectRating') {
      return `${key}|${index + 1}`;
    }

    return `${key}|${item}`;
  })]);

  return pre;
}, []).sort();

// Statistic
export const toNormalString = (str) => {
  const _str = str.replace(/([A-Z])/g, ' $1');
  return _str.charAt(0).toUpperCase() + _str.slice(1);
};

export const optionsParent = [
  { value: 'User Types', label: 'User Types' },
  { value: 'Project Types', label: 'Project Types' },
  { value: 'Project Status', label: 'Project Status' },
  { value: 'Data Types', label: 'Data Types' },
  { value: 'Data', label: 'Data' },
  { value: 'Polygons', label: 'Polygons' },
];
export const optionsChildren = {
  'User Types': [
    { value: 'Super Admin', label: 'Super Admin' },
    { value: 'Admin', label: 'Admin' },
    { value: 'Project Manager', label: 'Project Manager' },
    { value: 'Engineer', label: 'Engineer' },
    { value: 'Field Staff', label: 'Field Staff' },
  ],
  'Project Types': [
    { value: 'Solar', label: 'Solar' },
    { value: 'Transmission', label: 'Transmission' },
    { value: 'Wind', label: 'Wind' },
  ],
  'Project Status': [
    { value: 'Draft', label: 'Draft' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'On Hold', label: 'On Hold' },
    { value: 'Completed', label: 'Completed' },
    { value: 'Cancelled', label: 'Cancelled' },
  ],
  'Data Types': [
    { value: 'Drone', label: 'Drone' },
    { value: 'Phone', label: 'Phone' },
  ],
  Data: [
    { value: 'HD', label: 'HD' },
    { value: 'LiDAR', label: 'LiDAR' },
    { value: 'TH', label: 'TH' },
    { value: 'gisFiles', label: 'gisFiles' },
  ],
  Polygons: [
    { value: 'All', label: 'All' },
    { value: 'Boxes', label: 'Boxes' },
    { value: 'Polygons', label: 'Polygons' },
  ],
};
export const dataTypeOptions = [
  { value: 'All', label: 'All' },
  { value: 'Total', label: 'Total' },
  { value: 'Change on day', label: 'Change on day' },
];

export const optionsPolygonFiledParent = Object.keys(polygonFields).map((name) => ({ value: name, label: toNormalString(name) }));

// const polygonsFields = [...new Set(data.reduce((acc, item) => {
//   Object.keys(item.Polygons).forEach((type) => Object.keys(item.Polygons[type]).forEach((field) => {
//     if (typeof item.Polygons[type][field] === 'object') {
//       acc.push(field);
//     }
//   }));
//   return acc;
// }, []))].reverse().map((key) => ({ value: key, label: toNormalString(key) }));
