import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import useApp from 'hooks/useApp';
import ProjectCreate from 'views/projectCreate';
import NavBar from './NavBar';
import UserSetting from './UserSetting';
import UserNotifications from './UserNotifications';

const MainLayout = ({
  children,
  setCreateProjectModal,
  match,
}) => {
  const {
    longBar,
    toggleLongBar,
    showUserSetting,
    showUserNotifications,
    showModalCreateProject,
  } = useApp();

  return (
    <div className="mainLayout">
      <NavBar
        longBar={longBar}
        toggleLong={toggleLongBar}
        setCreateProjectModal={setCreateProjectModal}
        match={match}
      />
      <div className="page-content">{children}</div>
      {showUserSetting ? (
        <UserSetting />
      ) : null}
      {showUserNotifications ? (
        <UserNotifications />
      ) : null}
      {showModalCreateProject ? (
        <ProjectCreate id />
      ) : null}
      <div id="modal-portal" />
      <div id="offCanvas-portal" />
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
  setCreateProjectModal: PropTypes.func,
  match: PropTypes.objectOf(PropTypes.any),
};

export default MainLayout;
