import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getDistance } from 'views/home/helpers';
import DistanceInfo from './DistanceInfo';

const Ruler = ({
  mapApi, rulerMarkers, rulerLine, setRulerLine, toggle,
}) => {
  const [unit, setUnit] = useState('m');
  const [showDistanceInfo, toggleDistanceInfo] = useState(false);
  const [distance, setDistance] = useState({
    label: null,
    position: null,
  });

  useEffect(() => {
    if (!rulerLine && rulerMarkers.length === 2) {
      const path = rulerMarkers.map((marker) => ({
        lat: marker.getPosition().lat(),
        lng: marker.getPosition().lng(),
      }));
      setRulerLine(new mapApi.maps.Polyline({
        path,
        strokeOpacity: 0,
        zIndex: 10,
        icons: [
          {
            icon: {
              path: 'M 0,-1 0,1',
              strokeOpacity: 1,
              scale: 3,
              strokeColor: '#003FFF',
            },
            offset: '0',
            repeat: '15px',
          },
        ],
      }));
    }
  }, [rulerLine, rulerMarkers]);

  useEffect(() => {
    if (rulerLine) {
      rulerLine.setMap(mapApi.map);

      setDistance(getDistance(
        mapApi.maps.geometry.spherical.computeLength(rulerLine.getPath()),
        unit,
      ));
      toggleDistanceInfo(true);

      //listen
      const markerDrag = () => {
        rulerLine.setPath([
          rulerMarkers[0].getPosition(),
          rulerMarkers[1].getPosition()]);
      };
      const markerEndDrag = () => {
        setDistance(getDistance(
          mapApi.maps.geometry.spherical.computeLength(rulerLine.getPath()),
          unit,
        ));
        toggleDistanceInfo(true);
      };

      rulerMarkers.forEach((marker) => {
        mapApi.maps.event.addListener(marker, 'drag', markerDrag);
        mapApi.maps.event.addListener(marker, 'dragend', markerEndDrag);
      });
    }

    return () => {
      rulerMarkers.forEach((marker) => {
        mapApi.maps.event.clearListeners(marker, 'drag');
        mapApi.maps.event.clearListeners(marker, 'dragend');
      });
    };
  }, [rulerLine, unit]);

  const handleChangeUnit = (value) => {
    setUnit(value);
    setDistance(getDistance(
      rulerMarkers[0].getPosition().lat(),
      rulerMarkers[0].getPosition().lng(),
      rulerMarkers[1].getPosition().lat(),
      rulerMarkers[1].getPosition().lng(),
      unit,
    ));
  };

  if (showDistanceInfo) {
    return (
      <DistanceInfo
        active={unit}
        label={distance}
        switchUnit={handleChangeUnit}
        close={() => {
          toggleDistanceInfo(false);
          toggle(false);
        }}
      />
    );
  }
  return null;
};

Ruler.propTypes = {
  mapApi: PropTypes.objectOf(PropTypes.any),
  rulerMarkers: PropTypes.arrayOf(PropTypes.object),
  rulerLine: PropTypes.objectOf(PropTypes.any),
  setRulerLine: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default Ruler;
