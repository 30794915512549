import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { ReactComponent as SelectIcon } from 'assets/adminFilters/selectIcon.svg';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import SelectUi from '@material-ui/core/Select';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

const THEME = createMuiTheme({
  typography: {
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
});

const Select = ({
  items, action, selected, name,
}) => {
  const [value, setValue] = useState(selected);

  useEffect(() => { setValue(selected); }, [selected]);

  const handleSelect = (e) => {
    const { value: val, name: n } = e.target;

    if (val !== '0') {
      setValue(val);
      action(val, n);
    } else {
      setValue(val);
      action(undefined);
    }
  };

  return (
    <>
      <div className="filterSelect">
        <MuiThemeProvider theme={THEME}>
          <FormControl>
            <SelectUi
              name={name}
              autoWidth
              value={value}
              onChange={handleSelect}
              IconComponent={SelectIcon}
              className="filterSelect__list"
            >
              {items.length && items.map((item) => (
                <MenuItem
                  key={item.value}
                  className="filterSelect__list-item"
                  value={item.value}
                  disabled={item.disabled}
                >
                  {item.label}
                </MenuItem>
              ))}
            </SelectUi>
          </FormControl>
        </MuiThemeProvider>
      </div>
    </>
  );
};

Select.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.func,
  selected: PropTypes.string,
  name: PropTypes.string,
};

Select.defaultProps = {
  action: null,
};

export default Select;
