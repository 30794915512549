import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as CloseIcon } from 'assets/_v2.0/tools/closeIcon.svg';
import { ReactComponent as CopyIcon } from 'assets/_v2.0/tools/copyIcon.svg';
import { useSnackbar } from 'notistack';

const AreaInfo = ({
  unit, unitSquare, info, switchUnit, switchUnitSquare, close,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const area = useRef();
  const per = useRef();
  const types = {
    m: 'Meters',
    km: 'Kilometers',
    mi: 'Miles',
    ft: 'Feet',
  };

  const handleCopy = (text) => () => {
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar(`Copy "${text}" Success!`, { variant: 'success' });
    });
  };

  const handleClickArea = () => {
    if (area && area.current) {
      area.current.classList.toggle('active');
    }
  };

  const handleChangeArea = (e) => {
    switchUnitSquare(e.target.value);
    handleClickArea();
  };

  const handleClickPer = () => {
    if (per && per.current) {
      per.current.classList.toggle('active');
    }
  };

  const handleChangePer = (e) => {
    switchUnit(e.target.value);
    handleClickPer();
  };

  return (
    <div className="areaInfo">
      <div className="areaInfo__content">

        <div className="areaInfo__content__item">
          <div className="areaInfo__content-head">
            <div className="areaInfo__content-head__title">Area:</div>
            <div className="areaInfo__content-head__close" onClick={close}><CloseIcon /></div>
          </div>
          <div className="areaInfo__content-label">
            <div className="areaInfo__content__unit-list" ref={area}>
              <div className="areaInfo__content__unit-list__selected" onClick={handleClickArea}>{types[unitSquare]}<sup>2</sup></div>
              <div className="areaInfo__content__unit-list__options">
                {Object.keys(types).map((key) => (
                  <label className="areaInfo__content__unit-list__item" key={key}>
                    <input
                      type="radio"
                      name="unitSquare"
                      hidden
                      value={key}
                      defaultChecked={unitSquare === key}
                      onChange={handleChangeArea}
                    />
                    <span>{types[key]}<sup>2</sup></span>
                  </label>
                ))}
              </div>
            </div>
            <span>{`${info.area} ${unitSquare}`}<sup>2</sup></span>
            <button type="button" onClick={handleCopy(`${info.area} ${unitSquare}2`)}><CopyIcon /> </button>
          </div>
        </div>

        <div className="areaInfo__content__item">
          <div className="areaInfo__content-head">
            <div className="areaInfo__content-head__title">Perimeter:</div>
          </div>
          <div className="areaInfo__content-label">
            <div className="areaInfo__content__unit-list" ref={per}>
              <div className="areaInfo__content__unit-list__selected" onClick={handleClickPer}>{types[unit]}</div>
              <div className="areaInfo__content__unit-list__options">
                {Object.keys(types).map((key) => (
                  <label className="areaInfo__content__unit-list__item" key={key}>
                    <input
                      type="radio"
                      name="unit"
                      hidden
                      value={key}
                      defaultChecked={unit === key}
                      onChange={handleChangePer}
                    />
                    <span>{types[key]}</span>
                  </label>
                ))}
              </div>
            </div>
            <span>{`${info.length} ${unit}`}</span>
            <button type="button" onClick={handleCopy(`${info.length} ${unit}`)}><CopyIcon /> </button>
          </div>
        </div>

      </div>
    </div>
  );
};

AreaInfo.propTypes = {
  unit: PropTypes.string.isRequired,
  unitSquare: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  switchUnit: PropTypes.func.isRequired,
  switchUnitSquare: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default AreaInfo;
