import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
// Layouts
import AuthLayout from 'layouts/AuthLayout';
import MainLayout from 'layouts/MainLayout';
import ViewLayout from 'layouts/ViewLayout';
// Guards
import AuthGuard from 'components/Guards/authGuard';
import GuestGuard from 'components/Guards/guestGuard';
import AdminGuard from 'components/Guards/adminGuard';
// Components
import LoadingScreen from 'components/LoadingScreen';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={(props) => {
              const fragmentProps = route.layout ? props : {}; // fix React.Fragment can only have `key`
              return (
                <Guard>
                  <Layout {...fragmentProps}>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              );
            }}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  // {
  //   exact: true,
  //   path: '/404',
  //   component: lazy(() => import('views/error/NotFoundView')),
  // },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    layout: AuthLayout,
    component: lazy(() => import('views/auth/Login')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    layout: AuthLayout,
    component: lazy(() => import('views/auth/Forgot')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset/:token',
    layout: AuthLayout,
    component: lazy(() => import('views/auth/ResetPassword')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/',
    layout: MainLayout, // MainLayout
    component: lazy(() => import('views/home')),
  },
  {
    exact: true,
    guard: AuthGuard,
    path: '/project/:id?',
    layout: MainLayout, // MainLayout
    component: lazy(() => import('views/project')),
  },
  {
    exact: true,
    path: '/view/:id/:image?',
    layout: ViewLayout,
    component: lazy(() => import('views/projectView/')),
  },
  {
    path: '/admin',
    guard: AdminGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/admin/company/:id?',
        component: lazy(() => import('views/admin/')),
      },
    ],
  },
  {
    exact: true,
    path: '/dashboard',
    guard: AuthGuard,
    layout: MainLayout,
    component: lazy(() => import('views/admin/pages/Dashboard')),
  },
];

export default routes;
