export const validateEmail = (email) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  .test(String(email).toLowerCase());

export const isValidUser = (user, users, type) => {
  let block = false;
  const errors = {};

  if ('email' in user) {
    if (!validateEmail(user.email)) {
      errors.email = 'Email is not valid! Example: email@site.com';
      block = true;
    }

    if (user._id) {
      if (users.find((x) => x.email === user.email && user._id !== x._id)) {
        errors.email = 'Email Already Exists!';
        block = true;
      }
    } else if (users.find((x) => x.email === user.email)) {
      errors.email = 'Email Already Exists!';
      block = true;
    }
  } else {
    errors.email = 'Email is Required';
    block = true;
  }

  if (!('name' in user)) {
    errors.name = 'First name is Required';
    block = true;
  } else if (user.name.length < 3) {
    errors.name = 'First name must be at least 3 characters long.';
    block = true;
  }

  if (!('surName' in user)) {
    errors.surName = 'Last name is Required';
    block = true;
  } else if (user.surName.length < 3) {
    errors.surName = 'Last name must be at least 3 characters long.';
    block = true;
  }

  if (type && type !== 'edit') {
    if (!('phone' in user)) {
      errors.phone = 'Phone is Required';
      block = true;
    } else if (/\D/.test(user.phone)) {
      errors.phone = 'Phone not valid.';
      block = true;
    }
  }

  if (!('role' in user)) {
    errors.role = 'Select User Role';
    block = true;
  }

  return { block, errors };
};

export const isValidTeam = (team, teams) => {
  let block = false;
  const errors = {};

  if ('name' in team) {
    if (team._id) {
      if (teams.find((x) => x.name === team.name && team._id !== x._id)) {
        errors.name = 'Team Already Exists!';
        block = true;
      } else if (team.name.length < 3) {
        errors.name = 'Name must be at least 3 characters long.';
        block = true;
      }
    } else if (teams.find((x) => x.name === team.name)) {
      errors.name = 'Team Already Exists!';
      block = true;
    } else if (team.name.length < 3) {
      errors.name = 'Name must be at least 3 characters long.';
      block = true;
    }
  } else {
    errors.name = 'Name is Required';
    block = true;
  }

  return { block, errors };
};

export const isValidCompany = (company, companies) => {
  let block = false;
  const errors = {};

  if ('name' in company) {
    if (company._id) {
      if (companies.find((x) => x.name === company.name && company._id !== x._id)) {
        errors.name = 'Company Already Exists!';
        block = true;
      } else if (company.name.length < 3) {
        errors.name = 'Name must be at least 3 characters long.';
        block = true;
      }
    } else if (companies.find((x) => x.name === company.name)) {
      errors.name = 'Company Already Exists!';
      block = true;
    } else if (company.name.length < 3) {
      errors.name = 'Name must be at least 3 characters long.';
      block = true;
    }
  } else {
    errors.name = 'Name is Required';
    block = true;
  }

  if (!company.access || !company.access.length) {
    errors.access = 'Type is Required';
    block = true;
  }

  return { block, errors };
};
