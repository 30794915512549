import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AuthProvider } from 'contexts/AuthContext';
import { AppProvider } from 'contexts/AppContext';
import { NotificationsProvider } from 'contexts/NotificationsContext';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SnackbarProvider } from 'notistack';
import routes, { renderRoutes } from './routes';

import './App.css';

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://769904087fc047c7a46608b4aaa9ed8d@o823994.ingest.sentry.io/5810615',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: 1.0,
});

const App = () => {
  return (
    <Router history={history}>
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <AuthProvider>
          <AppProvider>
            <NotificationsProvider>
              {renderRoutes(routes)}
            </NotificationsProvider>
          </AppProvider>
        </AuthProvider>
      </SnackbarProvider>
    </Router>
  );
};

export default App;
