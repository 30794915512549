import React, { useState } from 'react';
import { ReactComponent as Arrow } from 'assets/creatingProject/caretDown.svg';

import './teamComponent.scss';
import { ReactComponent as Plusaround } from 'assets/creatingProject/plusaround.svg';
import { ReactComponent as MinusCircle } from 'assets/creatingProject/minusCircle.svg';
import PropTypes from 'prop-types';
import UserComponent from '../UserComponent';
import useApp from '../../../../hooks/useApp';

const TeamComponent = ({
  team, indx, newProject,
}) => {
  const { users } = useApp();
  const [viewUsers, setViewUsers] = useState(false);
  const [inviteTeam, setInviteTeam] = useState(true);
  const handleArrowChange = () => {
    setViewUsers(!viewUsers);
    document.getElementById(indx).classList.toggle('activeArrow');
  };
  const dif = team.users.filter((id) => !newProject.get.teamMembers.includes(id));
  const handleSelectAllUsersTeam = (teamUsers) => {
    setInviteTeam(!inviteTeam);

    const difference = teamUsers.filter((id) => !newProject.get.teamMembers.includes(id));
    const result = difference.length ? [...newProject.get.teamMembers, ...difference]
      : newProject.get.teamMembers.filter((id) => !teamUsers.includes(id));

    newProject.set((prevState) => ({
      ...prevState,
      teamMembers: result,
    }));
  };

  return (
    <div className="Invite__teamView">
      <div className="Invite__teamView-teamName">
        <div
          className="Invite__teamView-teamName-btn"
          onClick={handleArrowChange}
        >
          <Arrow id={indx} className="arrowIcon" />
          <div className="teemNum">{team.name}</div>
        </div>
        {dif.length ? (
          <div
            className="Invite__teamView-teamName-button active"
            onClick={() => handleSelectAllUsersTeam(team.users)}
          >
            <Plusaround />
          </div>
        ) : (
          <div
            className="Invite__teamView-teamName-button disable"
            onClick={() => handleSelectAllUsersTeam(team.users)}
          >
            Cancel <MinusCircle className="minusCircle" />
          </div>
        )}

      </div>
      <div className={viewUsers ? 'usersActive' : 'users'}>
        {users?.filter((item) => team.users.includes(item._id)).map((user) => (
          <UserComponent key={user._id} user={user} newProject={newProject} />
        ))}
      </div>
    </div>
  );
};

TeamComponent.propTypes = {
  newProject: PropTypes.objectOf(PropTypes.any),
  team: PropTypes.objectOf(PropTypes.any),
  indx: PropTypes.number,
};

export default TeamComponent;
