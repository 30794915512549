import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const ViewLayout = ({ children }) => {
  return (
    <div className="preview-page">
      {children}
    </div>
  );
};

ViewLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ViewLayout;
