import React from 'react';
import './style.scss';

import { ReactComponent as AddUserIcon } from 'assets/creatingProject/addUserIcon.svg';
import { ReactComponent as DellUser } from 'assets/creatingProject/dellUser.svg';
import { url } from 'config';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types,no-unused-vars
const UserComponent = ({ user, newProject }) => {
  const handleInviteUsers = () => {
    let userState = [];

    if (!newProject.get.teamMembers.includes(user._id)) {
      userState = [...newProject.get.teamMembers, user._id];
    } else {
      userState = newProject.get.teamMembers.filter((item) => item !== user._id);
    }

    newProject.set((prevState) => ({
      ...prevState,
      teamMembers: userState,
    }));
  };
  if (!user) return null;
  return (
    <div className="User">
      <div className="User__wrap">
        {user?.avatar
          ? (
            <img alt={user.name || ''} src={`${url}/${user.avatar}`} className="User__wrap-avatar" />
          ) : (
            <span className="spanEl">{user.name[0].toUpperCase()}</span>
          )}

        <div className="User__wrap-desc">
          <div className="User__wrap-desc-name">{user.name}</div>
          <div className="User__wrap-desc-status">{user.role}</div>
        </div>
      </div>
      {!newProject.get.teamMembers.includes(user._id) ? (
        <div
          className="User__btn active"
          onClick={handleInviteUsers}
        >
          <AddUserIcon />
        </div>
      ) : (
        <div
          className="User__btn disable"
          onClick={handleInviteUsers}
        >
          Cancel
          <DellUser className="dellUserIcon" />
        </div>
      )}
    </div>
  );
};

UserComponent.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  newProject: PropTypes.objectOf(PropTypes.any),
};

export default UserComponent;
