import React, { useState } from 'react';

import { ReactComponent as PhoneIcon } from 'assets/creatingProject/phoneIcon.svg';
import { ReactComponent as DroneIcon } from 'assets/creatingProject/droneIcon.svg';
import { ReactComponent as GisFilesIcon } from 'assets/creatingProject/gisFilesIcon.svg';
import { ReactComponent as Discharging } from 'assets/creatingProject/discharging.svg';
import { ReactComponent as SizeIcon } from 'assets/creatingProject/iconSize.svg';

import convertSize from 'utils/convertSize';
import propTypes from './_types';
import Navigation from './Navigation';
import UploadFiles from '../Components/UploadFiles';
import { isValidUpload } from './_validation';

const Upload = ({
  activeStep,
  setActiveStep,
  toggleShowMap,
  newProject,
  navigation,
}) => {
  const [errors, setErrors] = useState({});
  const [choiceOfUploadType, setChoiceOfUploadType] = useState('');
  const isValid = isValidUpload(newProject.get);
  const [uploadModalShow, setUploadModalShow] = useState(false);
  const [hoverEffect, setHoverEffect] = useState(false);
  const [hoverEffect2, setHoverEffect2] = useState(false);
  const [hoverEffect3, setHoverEffect3] = useState(false);

  const showUploadModal = (modalType) => {
    setChoiceOfUploadType(modalType);
    setUploadModalShow(true);
  };
  const getAllSize = (files) => {
    if (!files.length) return 0;
    return convertSize(files.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0));
  };

  const onMouseMove = (type) => {
    if (type === 'phone') {
      setHoverEffect(true);
    } else if (type === 'drone') {
      setHoverEffect2(true);
    } else if (type === 'gis') {
      setHoverEffect3(true);
    }
  };

  const onMouseLeave = (type) => {
    if (type === 'phone') {
      setHoverEffect(false);
    } else if (type === 'drone') {
      setHoverEffect2(false);
    } else if (type === 'gis') {
      setHoverEffect3(false);
    }
  };

  return (
    <>
      <div className="Upload">
        {uploadModalShow && (
          <UploadFiles
            choiceOfUploadType={choiceOfUploadType}
            setUploadModalShow={setUploadModalShow}
            setFiles={newProject.set}
            toggleShowMap={toggleShowMap}
            newProject={newProject.get}
          />
        )}
        <div className="Upload__title">Project files:</div>
        <div className="Upload__infoAboutUpload">
          {errors.phone && <span className="Upload__infoAboutUpload-spanPhone">{errors.phone}</span>}
          {errors.drone && <span className="Upload__infoAboutUpload-spanDrone">{errors.drone}</span>}
          {errors.gis && <span className="Upload__infoAboutUpload-spanGis">{errors.gis}</span>}
        </div>
        <div className="Upload__uploadBlock">
          <div
            className="Upload__uploadBlock-imagesStorage"
            onClick={() => showUploadModal({
              title: 'Phone Images',
              type: 'phoneImages',
            })}
            onMouseEnter={() => onMouseMove('phone')}
            onMouseLeave={() => onMouseLeave('phone')}
          >
            <PhoneIcon className={hoverEffect ? 'phoneIconActive' : 'phoneIcon'} />
            <div className={hoverEffect ? 'Upload__uploadBlock-imagesStorage-title activeColor' : 'Upload__uploadBlock-imagesStorage-title disableColor'}>Phone Images</div>
            <div className="Upload__uploadBlock-imagesStorage-information">
              <div className={hoverEffect ? 'filesNumber activeColor' : 'filesNumber'}>{newProject.get.phoneImages.length} files</div>
              <div className={hoverEffect ? 'sizeIconBlock activeColor' : 'sizeIconBlock'}>
                <SizeIcon className={hoverEffect ? 'sizeIcon whiteIcon' : 'sizeIcon'} />
                {getAllSize(newProject.get.phoneImages)}
              </div>
            </div>
          </div>
          <div
            className="Upload__uploadBlock-imagesStorage margin"
            onClick={() => showUploadModal({
              title: 'Drone Images',
              type: 'droneImages',
            })}
            onMouseEnter={() => onMouseMove('drone')}
            onMouseLeave={() => onMouseLeave('drone')}
          >
            <DroneIcon className={hoverEffect2 ? 'phoneIconActive' : 'phoneIcon'} />
            <div className={hoverEffect2 ? 'Upload__uploadBlock-imagesStorage-title activeColor' : 'Upload__uploadBlock-imagesStorage-title disableColor'}>Drone Images</div>
            <div className="Upload__uploadBlock-imagesStorage-information">
              <div className={hoverEffect2 ? 'filesNumber activeColor' : 'filesNumber'}>{newProject.get.droneImages.length} files</div>
              <div className={hoverEffect2 ? 'sizeIconBlock activeColor' : 'sizeIconBlock'}>
                <SizeIcon className={hoverEffect2 ? 'sizeIcon whiteIcon' : 'sizeIcon'} />
                {getAllSize(newProject.get.droneImages)}
              </div>
            </div>
          </div>
          <div
            className="Upload__uploadBlock-imagesStorage"
            onClick={() => showUploadModal({
              title: 'GIS Line Files',
              type: 'gisFiles',
            })}
            onMouseEnter={() => onMouseMove('gis')}
            onMouseLeave={() => onMouseLeave('gis')}
          >
            <GisFilesIcon className={hoverEffect3 ? 'phoneIconActive' : 'phoneIcon'} />
            <div className={hoverEffect3 ? 'Upload__uploadBlock-imagesStorage-title activeColor' : 'Upload__uploadBlock-imagesStorage-title disableColor'}>GIS Line Files</div>
            <div className="Upload__uploadBlock-imagesStorage-information">
              <div className={hoverEffect3 ? 'filesNumber activeColor' : 'filesNumber'}>{newProject.get.gisFiles.length} files</div>
              <div className={hoverEffect3 ? 'sizeIconBlock activeColor' : 'sizeIconBlock'}>
                <SizeIcon className={hoverEffect3 ? 'sizeIcon whiteIcon' : 'sizeIcon'} />
                {getAllSize(newProject.get.gisFiles)}
              </div>
            </div>
          </div>
        </div>
        <div className="Upload__uploadBtn">
          <div
            className="Upload__uploadBtn-btn"
            onClick={() => showUploadModal({
              title: 'Phone Images',
              type: 'phoneImages',
              files: newProject.get.phoneImages,
            })}
          >
            Upload images
            <Discharging />
          </div>
          <div
            className="Upload__uploadBtn-btn margin"
            onClick={() => showUploadModal({
              title: 'Drone Images',
              type: 'droneImages',
              files: newProject.get.droneImages,
            })}
          >
            Upload
            <Discharging />
          </div>
          <div
            className="Upload__uploadBtn-btn"
            onClick={() => showUploadModal({
              title: 'GIS Line Files',
              type: 'gisFiles',
              files: newProject.get.gisFiles,
            })}
          >
            Upload
            <Discharging />
          </div>
        </div>
      </div>
      { !uploadModalShow && !navigation ? (
        <Navigation
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          // next={!isValid.block}
          next="строка сверху проверяет загрузку файлов"
          handlerError={() => setErrors(isValid.errors)}
        />
      ) : <div /> }
      { !uploadModalShow && navigation
        ? navigation
        : <div /> }
    </>
  );
};

Upload.propTypes = propTypes;

export default Upload;
