import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import './googleMap.scss';
import { ApiKey } from 'config';
import GoogleMapReact from 'google-map-react';
import InfoWindow from 'uiComponents/infoWindow';
import Ruler from 'views/home/components/Ruler';
import Area from 'views/home/components/Area';
import ProjectMarker from 'views/home/uiComponents/ProjectMarker';
import AnnotationsMarker from 'views/project/uiComponents/AnnotationsMarker';
import useApp from 'hooks/useApp';
import { imageSort } from 'views/project/helpers';
import { initialFilterImage } from 'staticFields';
import NewPhotoMarker from '../components/NewPhotoMarker';
import InfoPhotoModal from '../components/InfoPhotoModal';
import NewMarker from '../../projectCreate/Components/NewMarker';

const GoogleMap = ({
  ruler,
  area,
  projects,
  activeProject,
  setActiveProject,
  annotations,
  pointLine,
  heatMap,
  imageCenter,
  searchGeo,
  createProject,
}) => {
  const { imageFilters, mapType } = useApp();
  const [mapApi, setMapApi] = useState({});
  const [showInfoWindow, setShowInfoWindow] = useState(null);
  const [showInfoPhotoModal, setShowInfoPhotoModal] = useState(imageCenter);
  const [layers, setLayers] = useState([]);
  const [searchGeoMarker, setSearchGeoMarker] = useState(null);

  //Annotations
  const [newAnnotation, setNewAnnotation] = useState(null);
  const [editAnnotation, toggleEditAnnotation] = useState(false);

  const handleCancelAddAnnotation = () => {
    annotations.toggleAddAnnotations(false);
    setNewAnnotation(null);
  };
  const handleAddAnnotation = (index, item) => {
    if (annotations.list.length <= index) {
      annotations.add(annotations.id, item).then(() => setNewAnnotation(null));
    } else {
      annotations.edit(annotations.id, item, index);
    }
  };
  const handleDeleteAnnotation = (item) => {
    annotations.delete(annotations.id, item);
  };

  useEffect(() => {
    if (!annotations?.addAnnotations) {
      setNewAnnotation(null);
    }
  }, [annotations]);

  useEffect(() => {
    if (mapApi.map) {
      mapApi.map.set('draggable', !editAnnotation);
    }
  }, [editAnnotation, mapApi]);

  // Area
  const [drawing, setDrawing] = useState(null);
  useEffect(() => {
    if (!area?.active && drawing) {
      drawing?.overlay?.setMap(null);
      drawing.manager.setMap(null);
      setDrawing(null);
    }
  }, [area]);

  // Ruler
  const [rulerMarkers, setRulerMarkers] = useState([]);
  const [rulerLine, setRulerLine] = useState(null);
  useEffect(() => {
    if (!ruler?.active && rulerMarkers.length) {
      rulerMarkers.forEach((marker) => marker.setMap(null));
      setRulerMarkers([]);

      if (rulerLine) {
        rulerLine.setMap(null);
        setRulerLine(null);
      }
    }
  }, [ruler?.active, rulerMarkers]);

  // Project
  const ProjectsMarkers = () => {
    if (activeProject && activeProject._id) {
      return (
        <ProjectMarker
          onClick={() => {
            document.getElementById(activeProject._id)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
          }}
          project={activeProject}
          key={activeProject._id}
          lat={activeProject.location.coords.lat}
          lng={activeProject.location.coords.lng}
          active
        />
      );
    }
    return projects?.length ? projects.map((project) => (
      <ProjectMarker
        onClick={() => {
          document.getElementById(project._id)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
          setActiveProject(project);
        }}
        project={project}
        key={project._id}
        lat={project.location.coords.lat}
        lng={project.location.coords.lng}
      />
    )) : null;
  };

  useEffect(() => {
    if (!mapApi.map || !mapApi.maps) return;
    if (pointLine && activeProject?.timelines?.[0]?.gisFiles) {
      if (layers) {
        layers.forEach((item) => {
          item.setMap(null);
        });
      }
      const temp = activeProject.timelines[0].gisFiles.map((item) => (
        new mapApi.maps.KmlLayer({
          url: item.url,
          map: mapApi.map,
          preserveViewport: true,
        })
      ));
      setLayers(temp);
    } else if (layers) {
      layers.forEach((item) => {
        item.setMap(null);
      });
      setLayers([]);
    }
  }, [activeProject, pointLine, mapApi]);

  const handleMapClick = ({ lat, lng, event }) => {
    if (event.target.classList.length) return;
    if (createProject) createProject.set({ lat, lng });
    if (event.target.classList.length) return;
    // Ruler
    if (ruler?.active && rulerMarkers.length < 2) {
      const rulerMarker = new mapApi.maps.Marker({
        position: { lat, lng },
        map: mapApi.map,
        draggable: true,
        icon: {
          url: "data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.539062' width='24' height='24' rx='12' fill='%23353638'/%3E%3Crect x='6' y='6.53906' width='12' height='12' rx='6' fill='%23003FFF'/%3E%3C/svg%3E%0A",
          size: new mapApi.maps.Size(24, 24),
          origin: new mapApi.maps.Point(0, 0),
          anchor: new mapApi.maps.Point(12, 12),
        },
      });
      setRulerMarkers(((prevState) => [...prevState, rulerMarker]));
    }
    //Annotations
    if (
      annotations
      && Object.prototype.hasOwnProperty.call(annotations, 'addAnnotations')
      && annotations.addAnnotations
    ) {
      setNewAnnotation({
        geo: { lat, lng },
        visible: true,
      });
    }
  };

  const imageMarkers = () => {
    if (!activeProject) return false;
    if (!activeProject.timelines) return false;

    if (JSON.stringify(imageFilters) !== JSON.stringify(initialFilterImage)) {
      return activeProject.timelines[0]?.images?.filter((item) => {
        return item.geo && imageFilters.some((el) => {
          if (!item.csv) return false;
          return imageSort(el, item);
        });
      });
    }

    return activeProject?.timelines[0]?.images?.filter((item) => item.geo);
  };

  const center = () => {
    if (imageCenter?.geo) {
      return {
        lat: imageCenter.geo.latitude,
        lng: imageCenter.geo.longitude,
      };
    }

    if (activeProject?.location?.coords) {
      return activeProject?.location?.coords;
    }

    if (createProject?.get) {
      return createProject?.get;
    }

    return null;
  };

  useEffect(() => {
    setShowInfoWindow(null);
  }, [activeProject]);

  useEffect(() => {
    if (!mapApi.map || !mapApi.maps) return;
    if (searchGeoMarker) searchGeoMarker.setMap(null);
    if (searchGeo?.coords?.lat && searchGeo?.coords?.lng) {
      mapApi.map.setCenter({
        lat: searchGeo.coords.lat,
        lng: searchGeo.coords.lng,
      });
      mapApi.map.setZoom(15);
      setSearchGeoMarker(new mapApi.maps.Marker({
        position: {
          lat: searchGeo.coords.lat,
          lng: searchGeo.coords.lng,
        },
        map: mapApi.map,
      }));
    }
  }, [mapApi, searchGeo]);

  return (
    <div className="googleMap">
      <GoogleMapReact
        onGoogleApiLoaded={({ map, maps }) => setMapApi({ map, maps })}
        bootstrapURLKeys={{ key: ApiKey, libraries: ['places', 'drawing', 'geometry'] }}
        yesIWantToUseGoogleMapApiInternals
        defaultCenter={{
          lat: 25.16,
          lng: 55.18,
        }}
        center={center()}
        onClick={handleMapClick}
        defaultZoom={11}
        options={() => {
          return {
            scrollwheel: true,
            disableDoubleClickZoom: true,
            draggable: true,
            mapTypeId: mapType,
            zoomControl: false,
            fullscreenControl: false,
          };
        }}
      >
        {
          createProject?.get
          && (
            <NewMarker
              newProjectStep="setLocation"
              setNewProjectData={() => {}}
              {...createProject.get}
            />
          )
        }
        {pointLine && imageMarkers() ? imageMarkers().map((photo) => (
          <NewPhotoMarker
            key={photo._id}
            lat={photo.geo.latitude}
            lng={photo.geo.longitude}
            photo={photo}
            heatMap={heatMap}
            onClick={() => setShowInfoPhotoModal(photo)}
          />
        )) : null}

        {showInfoPhotoModal ? (
          <InfoPhotoModal
            title={showInfoPhotoModal.title}
            photo={showInfoPhotoModal}
            lat={showInfoPhotoModal.geo.latitude}
            lng={showInfoPhotoModal.geo.longitude}
            activeProject={activeProject}
            setActiveProject={setActiveProject}
            onClick={() => setShowInfoPhotoModal(null)}
          />
        ) : null}

        {/* { gis } */}

        {showInfoWindow && pointLine && (
          <InfoWindow
            lat={showInfoWindow.lat}
            lng={showInfoWindow.lng}
            data={showInfoWindow}
            close={() => setShowInfoWindow(null)}
          />
        )}

        {ProjectsMarkers()}
        {annotations?.showAnnotations && annotations.list?.length
          ? annotations.list.map((annotation, index) => (
            <AnnotationsMarker
              index={index}
              annotation={annotation}
              key={annotation.geo.lng + annotation.geo.lat}
              lat={annotation.geo.lat}
              lng={annotation.geo.lng}
              addAction={handleAddAnnotation}
              deleteAction={handleDeleteAnnotation}
              edit={editAnnotation}
              toggleEdit={toggleEditAnnotation}
            />
          )) : null}
        {annotations?.showAnnotations && newAnnotation ? (
          <AnnotationsMarker
            index={annotations.list?.length}
            annotation={newAnnotation}
            lat={newAnnotation.geo.lat}
            lng={newAnnotation.geo.lng}
            create
            cancelAction={handleCancelAddAnnotation}
            addAction={handleAddAnnotation}
          />
        ) : null}
      </GoogleMapReact>
      {ruler && ruler?.active ? (
        <Ruler
          mapApi={mapApi}
          rulerMarkers={rulerMarkers}
          setRulerMarkers={setRulerMarkers}
          rulerLine={rulerLine}
          setRulerLine={setRulerLine}
          toggle={ruler.toggleRuler}
        />
      ) : null }
      {area && area?.active ? (
        <Area
          mapApi={mapApi}
          drawing={drawing}
          setDrawing={setDrawing}
          toggle={area.toggleArea}
        />
      ) : null }
    </div>
  );
};

GoogleMap.propTypes = {
  pointLine: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.objectOf(PropTypes.any),
  ]),
  ruler: PropTypes.objectOf(PropTypes.any),
  area: PropTypes.objectOf(PropTypes.any),
  heatMap: PropTypes.bool,
  projects: PropTypes.arrayOf(PropTypes.object),
  imageCenter: PropTypes.objectOf(PropTypes.any),
  activeProject: PropTypes.objectOf(PropTypes.any),
  setActiveProject: PropTypes.func,
  annotations: PropTypes.objectOf(PropTypes.any),
  searchGeo: PropTypes.objectOf(PropTypes.any),
  createProject: PropTypes.objectOf(PropTypes.any),
};

export default GoogleMap;
