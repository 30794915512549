import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as LiveIcon } from 'assets/adminTable/liveIcon.svg';

const TeamsList = ({
  teams, action, active, setActive,
}) => {
  if (!teams.length) return null;

  return (
    <div className="teams-list">
      {teams.map((team) => (
        <div
          className={setActive ? 'teams-item pointer' : 'teams-item'}
          key={team._id}
          data-active={active?._id === team._id}
          onClick={() => setActive && setActive(team)}
        >
          <div className="teams-item-info" title={team.name}>{team.name} <span>{team.users.length} users</span></div>
          {action ? (
            <button
              type="button"
              className="teams-item-live"
              onClick={action(team._id)}
            >
              <LiveIcon />
            </button>
          ) : null}
        </div>
      ))}
    </div>
  );
};

TeamsList.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.func,
  active: PropTypes.objectOf(PropTypes.any),
  setActive: PropTypes.func,
};

export default TeamsList;
