import React from 'react';

import { ReactComponent as PlusIcon } from 'assets/creatingProject/plus.svg';
import { ReactComponent as AddFileIcon } from 'assets/creatingProject/addFileIcon.svg';

import './confirmSaveModal.scss';
import PropTypes from 'prop-types';
import ModalWrapper from '../../../../uiComponents/ModalWrapper';
import useApp from '../../../../hooks/useApp';
// import { abortUpload } from '../../../../utils/fileUploader';

// eslint-disable-next-line react/prop-types
const ConfirmSaveModal = ({
  // eslint-disable-next-line react/prop-types,no-unused-vars
  close, newProject, setSaveProjectModal, setSuccessfully, loadRef,
}) => {
  const { projectAPI } = useApp();

  const createProject = () => {
    close();
    // показать загрузку
    setSaveProjectModal(true);
    // eslint-disable-next-line react/prop-types
    projectAPI.add(newProject.get, loadRef).then(() => {
      //закрыть загрузку, проект сохранен
      setSaveProjectModal(false);
      setSuccessfully(true);
    });
  };
  const handleCloseModal = () => {
    close();
  };
  return (
    <div className="confirmSaveModal">
      <ModalWrapper
        toggleShow={handleCloseModal}
        title={(<div className="titleWrap"><AddFileIcon />Create project</div>)}
      >
        <div className="confirmText">
          {/* eslint-disable-next-line react/prop-types */}
          Do you want to create a new project with title: <span className="projectTitle">{newProject.get.title}</span>
        </div>
        <div className="buttonsWrap">
          <div className="disagreeBtn" onClick={handleCloseModal}>No, check data</div>
          <div
            className="agreeBtn"
            onClick={createProject}
          >
            Create
            <PlusIcon className="plusIcon" />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

ConfirmSaveModal.propTypes = {
  close: PropTypes.func,
};

export default ConfirmSaveModal;
