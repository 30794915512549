import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as TransmissionsIcon } from 'assets/_v2.0/project/transmissionsIcon.svg';
import { ReactComponent as WindIcon } from 'assets/_v2.0/project/windIcon.svg';
import { ReactComponent as SolarIcon } from 'assets/_v2.0/project/solarIcon.svg';

import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: '#003FFF',
  },
  customArrow: {
    color: '#003FFF',
  },
}));

const ProjectMarker = ({ onClick, project, active }) => {
  const classes = useStyles();
  const types = {
    transmission: <TransmissionsIcon />,
    solar: <SolarIcon />,
    wind: <WindIcon />,
  };

  const statusClass = {
    Draft: 'draft',
    'In Progress': 'inProgress',
    'On Hold': 'onHold',
    Completed: 'completed',
    Cancelled: 'cancelled',
  };

  const styleTooltip = {
    tooltip: classes.customTooltip,
    arrow: classes.customArrow,
  };

  return (
    <div className="projectMarker" onClick={onClick}>
      <Tooltip
        classes={styleTooltip}
        title={project.title}
        placement="top"
        arrow
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 200 }}
      >
        <div
          className={`projectMarker__target ${statusClass[project.status]} ${active ? 'active' : ''}`}
        >
          {types[project.type]}
        </div>
      </Tooltip>
    </div>
  );
};

ProjectMarker.propTypes = {
  onClick: PropTypes.func,
  project: PropTypes.objectOf(PropTypes.any),
  active: PropTypes.bool,
};

export default React.memo(ProjectMarker);
