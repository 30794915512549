import React from 'react';
import PropTypes from 'prop-types';
import './uploadedImageFile.scss';

import { ReactComponent as IconMapMarker } from 'assets/creatingProject/iconMapMarker.svg';
import { ReactComponent as UrnIcon } from 'assets/creatingProject/urnIcon.svg';
import { ReactComponent as TickIcon } from 'assets/tick.svg';

import convertSize from 'utils/convertSize';

const UploadedImageFile = ({
  item, active, onChange, i, onClick,
}) => {
  return (
    <>
      <div className="UploadedImageFile">
        <div className="UploadedImageFile-wrapper">
          <div className="UploadedImageFile-wrapper-typeFileBox">
            <label className="UploadFiles__containerWithPhoneFiles-checkLabel">
              <input
                value={item}
                type="checkbox"
                checked={active}
                onChange={onChange}
              />
              <i><TickIcon /></i>
            </label>
            <div className="UploadedImageFile-wrapper-typeFileBox-typeFile">
              {i.fileType === 'HD' && <div className="fileType">HD</div> }
              {i.fileType === 'LI' && <div className="fileType">LI</div>}
              {i.fileType === 'TH' && <div className="fileType">TH</div>}
            </div>
          </div>
          <div className="UploadedImageFile-wrapper-title">
            <div className="titleName">{i.title || i.name}</div>
          </div>
          <div className="UploadedImageFile-wrapper-fileSize">{convertSize(i.size)}</div>
          <div className="UploadedImageFile-wrapper-funcIcon">
            <div className={i.geo ? 'UploadedImageFile-wrapper-funcIcon-iconMap' : 'UploadedImageFile-wrapper-iconMap hidden'}>
              <IconMapMarker className="iconMapMarker" />
            </div>
            <div
              className="UploadedImageFile-wrapper-funcIcon-iconMore"
              onClick={onClick(item)}
            >
              <UrnIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UploadedImageFile.propTypes = {
  item: PropTypes.number,
  active: PropTypes.bool,
  onChange: PropTypes.func,
  i: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
};

export default UploadedImageFile;
