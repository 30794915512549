import exifr from 'exifr';

export default (files, type) => {
  return Promise.all(Array.from(files).map((file) => {
    return new Promise((res) => {
      exifr.parse(file, { ifd0: false, exif: true, gps: true }).then((temp) => {
        const result = file;

        if (temp) {
          if (temp?.latitude && temp?.longitude) {
            result.geo = {
              latitude: temp?.latitude,
              longitude: temp?.longitude,
            };
          }
          if (temp?.ExifImageWidth && temp?.ExifImageHeight) {
            result.resolution = `${temp.ExifImageWidth} x ${temp.ExifImageHeight}`;
          }
          result.exif = temp;
        }

        result.fileType = type;
        res(result);
      });
    });
  }));
};
