import React from 'react';

import { ReactComponent as UrnIcon } from 'assets/creatingProject/urnIcon.svg';

import './confirmDeleteModal.scss';
import PropTypes from 'prop-types';
import ModalWrapper from '../../../../uiComponents/ModalWrapper';
import useApp from '../../../../hooks/useApp';

// eslint-disable-next-line react/prop-types,no-unused-vars
const ConfirmDeleteModal = ({ close, setNewProject }) => {
  const {
    // eslint-disable-next-line no-unused-vars
    toggleModalCreateProject,
  } = useApp();

  const handleCloseModal = () => {
    close(false);
  };

  const handleClose = () => {
    toggleModalCreateProject();
    close();
    // setNewProject(initProject);
    setNewProject({
      title: '',
      location: {
        coords: null,
        address: '',
      },
      gisFiles: [],
      droneImages: [],
      phoneImages: [],
      teamMembers: [],
      company: null,
      type: null,
    });
  };

  return (
    <div className="confirmDeleteModal">
      <ModalWrapper
        toggleShow={handleCloseModal}
        title={(<div className="titleWrap"><UrnIcon />Delete project</div>)}
      >
        <div className="confirmText">
          {/* eslint-disable-next-line react/prop-types */}
          Do you want to delete a new project with title:
          {/*<span>{newProject.get.title}</span>*/}
        </div>
        <div className="buttonsWrap">
          <div className="disagreeBtn" onClick={handleCloseModal}>No, check data</div>
          <div
            className="agreeBtn"
            onClick={handleClose}
          >
            Delete <UrnIcon className="plusIcon" />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

ConfirmDeleteModal.propTypes = {
  close: PropTypes.func,
};

export default ConfirmDeleteModal;
