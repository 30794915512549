import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowRightIcon } from 'assets/creatingProject/arrowRight.svg';
import { ReactComponent as Plus } from 'assets/creatingProject/plus.svg';

import ConfirmSaveModal from '../Modal/ConfirmSaveModal';
import SavedProjectModal from '../Modal/SavedProjectModal';
import SuccessfullySavedModal from '../Modal/SuccessfullySavedModal';
import types from './_types';

const Navigation = ({
  activeStep,
  setActiveStep,
  next,
  handlerError,
  newProject,
  setConfirmDeleteProject,
}) => {
  const loadRef = useRef();
  const [confirmCreateModal, setConfirmCreateModal] = useState(false);
  const [saveProjectModal, setSaveProjectModal] = useState(false);
  const [successfully, setSuccessfully] = useState(false);

  const steps = {
    properties: <div className="step">Step 1/5 <div className="stepType"><ArrowRightIcon />Project Properties</div></div>,
    location: <div className="step">Step 2/5 <div className="stepType">Project Location</div></div>,
    upload: <div className="step">Step 3/5 <div className="stepType">File Upload</div></div>,
    invite: <div className="step">Step 4/5 <div className="stepType">Invite Users</div></div>,
    generalInfo: <div className="step">Step 5/5 <div className="stepType">General Project Info</div></div>,
  };

  const index = Object.keys(steps).findIndex((step) => step === activeStep);

  const handlePrev = () => {
    if (activeStep !== 'properties') {
      setActiveStep(Object.keys(steps)[index - 1]);
    }
  };

  const handleDeleteProject = () => {
    setConfirmDeleteProject(true);
  };

  const handleNext = () => {
    if (next) {
      setActiveStep(Object.keys(steps)[index + 1]);
    } else {
      handlerError();
    }
  };

  return (
    <div className="Navigation">
      <div className="Navigation__history">
        <div className="Navigation__history-dots">
          {Object.keys(steps).map((key) => (
            <span key={key} className={key === activeStep ? 'active' : ''} />
          ))}
        </div>
        <div className="Navigation__history-count">
          <div className="Navigation__history-count-stepBlock">
            <div className={activeStep === 'properties' ? 'enable' : 'disable'} />
            <div className={activeStep === 'location' ? 'enable' : 'disable'} />
            <div className={activeStep === 'upload' ? 'enable' : 'disable'} />
            <div className={activeStep === 'invite' ? 'enable' : 'disable'} />
            <div className={activeStep === 'generalInfo' ? 'enable' : 'disable'} />
          </div>
          {steps[activeStep]}
        </div>
      </div>
      <div className="Navigation__action">
        {activeStep === 'properties'
          ? (<div className="Navigation__action-backBtn" onClick={handleDeleteProject}>Cancel</div>)
          : (<div className="Navigation__action-backBtn" onClick={handlePrev}>Back</div>)}
        {activeStep === 'generalInfo' ? (
          <div className="Navigation__action-continueBtn" onClick={() => { setConfirmCreateModal(true); }}>
            Create project
            <Plus />
          </div>
        ) : (
          <div className="Navigation__action-continueBtn" onClick={handleNext}>
            Continue
            <ArrowRightIcon />
          </div>
        )}
      </div>
      {confirmCreateModal ? (
        <ConfirmSaveModal
          style={{ position: 'absolut' }}
          loadRef={loadRef}
          newProject={newProject}
          close={setConfirmCreateModal}
          setSaveProjectModal={setSaveProjectModal}
          setSuccessfully={setSuccessfully}
        />
      ) : null}
      {saveProjectModal ? (
        <SavedProjectModal setSuccessfully={successfully} close={setSaveProjectModal} loadRef={loadRef} />
      ) : null}
      {successfully ? (
        <SuccessfullySavedModal newProject={newProject} close={setSuccessfully} />
      ) : null}
    </div>
  );
};

Navigation.propTypes = {
  ...types,
  next: PropTypes.string.isRequired,
  handlerError: PropTypes.func,
};

export default Navigation;
