import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as AddUserIcon } from 'assets/notification/addUserIcon.svg';
import { ReactComponent as InviteIcon } from 'assets/_v2.0/navBar/invitiIcon.svg';

import useApp from 'hooks/useApp';
import useNotifications from 'hooks/useNotifications';
import Avatar from 'layouts/MainLayout/UserNotifications/uiComponents/Avatar';
import Time from 'layouts/MainLayout/UserNotifications/uiComponents/Time';
// import { url } from 'config';

const InvitedType = ({ info }) => {
  const { confirmationProject } = useNotifications();
  const { projectAPI } = useApp();

  const confirm = (accept) => {
    projectAPI.confirmation(info._id, accept).then(confirmationProject);
  };

  if (info.accept === 'accept') {
    return (
      <div className="InvitedType-accept">
        <div className="InvitedType-accept__icon">
          <div className="Notification__avatar">
            <Avatar info={info} />
          </div>
          <div className="avatarMarker">
            <InviteIcon />
          </div>
        </div>
        <div>
          <div className="InvitedType-accept__info">
            <p>
              You have accepted the invitation to join the Project
              <strong>“{info.project?.title}”</strong>
            </p>
          </div>
          <Time info={info} />
        </div>
      </div>
    );
  }

  if (info.accept === 'decline') {
    return (
      <div className="InvitedType-accept" style={{ backgroundColor: '#ff2681' }}>
        <div className="InvitedType-accept__icon"><AddUserIcon /></div>
        <div>
          <div className="InvitedType-accept__info">
            <p>
              You have rejected the invitation to join the
              <strong>“{info.project?.title}”</strong>
            </p>
          </div>
          <Time info={info} />
        </div>
      </div>
    );
  }

  return (
    <div className="Notification">
      <div className="Notification__avatar">
        <Avatar info={info} />
      </div>
      <div className="Notification__content">
        <div className="Notification__content-head">
          <div>
            <div className="user-name">{`${info.user?.name} ${info.user?.surName}`}</div>
            <div>
              <div className="notification-desc">
                You have been invited to a new project
                <span> {info.project?.title}</span> by
                <span> {`${info.user.name} ${info.user.surName || ''}`}</span>.
              </div>
              <div className="InvitedType">
                <button
                  type="button"
                  className="actions-btn accept"
                  onClick={() => confirm(true)}
                >
                  Accept
                </button>
                <button
                  type="button"
                  className="actions-btn cancel"
                  onClick={() => confirm(false)}
                >
                  Decline
                </button>
              </div>
              <Time info={info} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

InvitedType.propTypes = {
  info: PropTypes.objectOf(PropTypes.any),
};

export default InvitedType;
