import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Select = ({
  name,
  onChange = () => {},
  options = [],
  selected,
  placeholder = '',
}) => {
  const wr = useRef();

  const handleStatusClick = () => {
    if (wr && wr.current) {
      wr.current.classList.toggle('active');
    }
  };

  const handleStatusChange = (e) => {
    onChange(e.target.value, e.target.name);
    handleStatusClick();
  };

  return (
    <div className="Select-wr" ref={wr}>
      <div className="Select-active" onClick={handleStatusClick}>
        <span className="Select-options-item-label">
          {options.find((option) => option.value === selected)?.label || placeholder}
        </span>
      </div>
      <div className="Select-options scroll-wrapper">
        {options.map((option) => (
          <label className="Select-options-item" key={option.value}>
            <input
              type="radio"
              name={name}
              hidden
              value={option.value}
              defaultChecked={option.value === selected}
              onChange={handleStatusChange}
            />
            <span className="Select-options-item-label">
              {option.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Select;
