import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './style.scss';

import { ReactComponent as CloseIcon } from 'assets/adminTable/closeIcon.svg';
import { ReactComponent as SaveIcon } from 'assets/adminTable/saveIcon.svg';

import OffCanvasWrapper from 'uiComponents/OffCanvasWrapper';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import Collapse from 'uiComponents/Collapse';
import ProjectItem from 'uiComponents/ProjectItem';
import TeamsList from 'uiComponents/TeamsList';
import UserInfoForm from './UserInfoForm';
import Notifications from './Notifications';
import ResetPasswordConfirm from '../../../uiComponents/ResetPasswordConfirm';

const UserSetting = () => {
  const {
    toggleUserSetting, projects, teams, usersAPI,
  } = useApp();
  const { user: userLogin, updateUser } = useAuth();
  const [user, changeUser] = useState(userLogin);

  const handleSaveChange = async () => {
    const newUser = await usersAPI.edit(user);
    updateUser(newUser);
  };

  const userTeams = teams.filter((team) => team.users.includes(userLogin._id));
  const userProjects = projects.filter((project) => project.users.includes(userLogin._id));

  // eslint-disable-next-line no-unused-vars
  const handleResetPassword = () => {
    ReactDOM.render((
      <ResetPasswordConfirm
        user={userLogin}
        usersAPI={usersAPI}
        toggleShow={() => ReactDOM.unmountComponentAtNode(document.getElementById('modal-portal'))}
      />
    ), document.getElementById('modal-portal'));
  };

  return (
    <div className="UserSetting">
      <OffCanvasWrapper
        toggleShow={toggleUserSetting}
        title="SETTINGS"
        bottomAction={(
          <>
            <button type="button" className="actions-btn clean" onClick={toggleUserSetting}>Cancel <CloseIcon /></button>
            <button type="button" className="actions-btn saveUser" onClick={handleSaveChange}>Save <SaveIcon /></button>
          </>
        )}
      >
        <Collapse title="Profile settings">
          <UserInfoForm user={user} usersAPI={usersAPI} changeUser={changeUser} />
        </Collapse>
        {userProjects.length ? (
          <Collapse title="Active Projects">
            {userProjects.map((project) => (
              <div key={project._id}>
                <ProjectItem
                  project={project}
                />
              </div>
            ))}
          </Collapse>
        ) : null}
        {userTeams.length ? (
          <Collapse title="Departments">
            <TeamsList teams={userTeams} />
          </Collapse>
        ) : null}
        <Collapse title="Notifications">
          <Notifications user={user} changeUser={changeUser} projects={userProjects} />
        </Collapse>
      </OffCanvasWrapper>
    </div>
  );
};

export default UserSetting;
