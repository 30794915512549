import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as CloseIcon } from 'assets/_v2.0/tools/closeIcon.svg';
import { ReactComponent as CopyIcon } from 'assets/_v2.0/tools/copyIcon.svg';
import { ReactComponent as LinageIcon } from 'assets/_v2.0/headerIcons/linage.svg';

import { useSnackbar } from 'notistack';

const DistanceInfo = ({
  active, label, switchUnit, close,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const ruler = useRef();

  const handleCopy = () => {
    navigator.clipboard.writeText(`${label} ${active}`).then(() => {
      enqueueSnackbar(`Copy "${label} ${active}" Success!`, { variant: 'success' });
    });
  };

  const types = {
    m: 'Meters',
    km: 'Kilometers',
    mi: 'Miles',
    ft: 'Feet',
  };

  const handleClick = () => {
    if (ruler && ruler.current) {
      ruler.current.classList.toggle('active');
    }
  };

  const handleChange = (e) => {
    switchUnit(e.target.value);
    handleClick();
  };

  return (
    <div className="distanceInfo">
      <div className="distanceInfo__content">

        <div className="distanceInfo__content-head">
          <div className="distanceInfo__content-head__title"><LinageIcon /> Distance:</div>
          <div className="distanceInfo__content-head__close" onClick={close}><CloseIcon /></div>
        </div>

        <div className="distanceInfo__content-label">
          <div className="distanceInfo__content__unit-list" ref={ruler}>
            <div className="distanceInfo__content__unit-list__selected" onClick={handleClick}>{types[active]}</div>
            <div className="distanceInfo__content__unit-list__options">
              {Object.keys(types).map((key) => (
                <label className="distanceInfo__content__unit-list__item" key={key}>
                  <input
                    type="radio"
                    name="unit"
                    hidden
                    value={key}
                    defaultChecked={active === key}
                    onChange={handleChange}
                  />
                  <span>{types[key]}</span>
                </label>
              ))}
            </div>
          </div>
          <span>{`${label} ${active}`}</span>
          <button type="button" onClick={handleCopy}><CopyIcon /> </button>
        </div>

      </div>
    </div>
  );
};

DistanceInfo.propTypes = {
  active: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  switchUnit: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default DistanceInfo;
