import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as DotsIcon } from 'assets/_v2.0/project/dotsIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/_v2.0/project/deleteIcon.svg';
import { ReactComponent as SaveIcon } from 'assets/_v2.0/project/saveIcon.svg';

import { ReactComponent as CloseIcon } from 'assets/adminTable/closeIcon.svg';
import { ReactComponent as ClockIcon } from 'assets/project/clockIcon.svg';
import { ReactComponent as PenIcon } from 'assets/creatingProject/penIcon.svg';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ConfirmDelete from 'uiComponents/ConfirmDelete';
import useAuth from 'hooks/useAuth';
import { formatRelative } from 'date-fns';
import { useSnackbar } from 'notistack';
import useApp from 'hooks/useApp';
import { url } from 'config';

const AnnotationsMarker = ({
  index,
  annotation,
  create,
  addAction,
  cancelAction,
  deleteAction,
  toggleEdit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const { previewProject } = useApp();
  const [item, changeItem] = useState(annotation);
  const [showModal, toggleShowModal] = useState(create);
  const marker = useRef(null);
  const [errors, setError] = useState({});
  const [focus, setFocus] = useState(false);
  const [editMarker, toggleEditMarker] = useState(false);
  const [showSubMenu, toggleShowSubMenu] = useState(false);

  useEffect(() => {
    changeItem(annotation);
  }, [annotation]);

  useEffect(() => {
    if (showModal && marker && marker.current) {
      marker.current.parentElement.style.zIndex = 1;
    }

    return () => {
      if (showModal && marker && marker.current) {
        marker.current.parentElement.style.zIndex = 0;
      }
    };
  }, [showModal, marker]);

  const handleOnFocus = () => {
    setFocus(!focus);
  };

  const handleDeleteDone = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('Project-modal'));
    deleteAction(item);
    toggleShowModal(false);
    toggleEditMarker(false);
  };

  const handleDeleteClick = () => {
    toggleShowSubMenu(false);
    ReactDOM.render((
      <ConfirmDelete
        title="comment"
        name={item.title}
        toggleShow={() => ReactDOM.unmountComponentAtNode(document.getElementById('Project-modal'))}
        action={handleDeleteDone}
      />
    ), document.getElementById('Project-modal'));
  };

  const handleEditClick = () => {
    toggleShowSubMenu(false);
    toggleEditMarker(true);
    toggleEdit(true);
  };

  const handleCancel = () => {
    if (create) {
      cancelAction();
    } else {
      changeItem(annotation);
      toggleEditMarker((s) => !s);
      toggleEdit((s) => !s);
    }
  };

  const handleChangeInput = (e) => {
    changeItem((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleSave = () => {
    let block = false;

    if (!item.content) {
      setError((s) => ({ ...s, content: 'Content is Required' }));
      block = true;
    }

    if (block) {
      enqueueSnackbar('Not valid!', { variant: 'error' });
      return false;
    }

    toggleShowModal(false);

    if (create) {
      addAction(index, {
        ...item,
        create: Date.now(),
        update: Date.now(),
        author: user,
      });
    } else {
      const newItem = {
        ...item,
        update: Date.now(),
      };
      addAction(index, newItem);
      toggleEditMarker((s) => !s);
      toggleEdit((s) => !s);
    }

    return false;
  };

  const isCompleted = annotation.content && !create;

  const isAuthor = !previewProject
    ? !create && user._id === annotation.author._id
    : false;

  return (
    <div className="AnnotationsMarker" ref={marker}>
      <div
        className="AnnotationsMarker__icon"
        data-show-modal={showModal}
        onClick={() => toggleShowModal((s) => !s)}
      >
        {index + 1}
      </div>
      {showModal ? (
        <div className="AnnotationsMarker__modal">
          <div className="AnnotationsMarker__modal__head">
            {isAuthor || create ? (
              <div className="AnnotationsMarker__modal__head-actions">
                {user?.avatar
                  ? (
                    <img alt={user.name || ''} src={`${url}/${user.avatar}`} className="User__wrap-avatar" />
                  ) : (
                    <span className="spanEl">{user.name[0].toUpperCase()}</span>
                  )}
                <div className="userNameWrap">{user.name} {user.surName}</div>
              </div>
            ) : (
              <div className="AnnotationsMarker__modal__head-actions">
                {annotation.author?.avatar
                  ? (
                    <img alt={annotation.author.name || ''} src={`${url}/${annotation.author?.avatar}`} className="User__wrap-avatar" />
                  ) : (
                    <span className="spanEl">{user.name[0].toUpperCase()}</span>
                  )}
                <div className="userNameWrap">
                  <div>{annotation.author.name}</div>
                  <div>{annotation.author.surName}</div>
                </div>
              </div>
            )}
            {isCompleted ? (
              <div className="AnnotationsMarker__modal__head-submenu">
                <button
                  className={`btn ${showSubMenu ? 'active' : ''}`}
                  type="button"
                  onClick={() => toggleShowSubMenu(!showSubMenu)}
                >
                  <DotsIcon />
                </button>
                {showSubMenu ? (
                  <div className="AnnotationsMarker__modal__head-submenu__modal">
                    <div className="submenu_btn" onClick={handleEditClick}>
                      <PenIcon /> Edit text
                    </div>
                    <div className="submenu_line" />
                    <div className="submenu_btn" onClick={handleDeleteClick}>
                      <DeleteIcon /> Delete comment
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className={focus ? 'AnnotationsMarker__modal__content border' : 'AnnotationsMarker__modal__content'}>
            {editMarker || create ? (
              <TextareaAutosize
                id="contentInput"
                className="content-input"
                name="content"
                aria-label="Content"
                placeholder="Content"
                onChange={handleChangeInput}
                defaultValue={item.content}
                onFocus={handleOnFocus}
                onBlur={handleOnFocus}
              />
            ) : (
              <span>{item.content}</span>
            )}
          </div>
          {errors.content ? <span className="error-alert">{errors.content}</span> : null}
          {editMarker || create ? (
            <div className="AnnotationsMarker__modal__actions">
              <button
                type="button"
                className="cancel"
                onClick={handleCancel}
              >
                Cancel <CloseIcon className="cancel" />
              </button>
              <button
                type="button"
                datatype="save"
                onClick={handleSave}
                className="save"
              >
                Save <SaveIcon />
              </button>
            </div>
          ) : null}
          <div className="AnnotationsMarker__modal__date"><ClockIcon />
            {item.update ? `${formatRelative(new Date(item.update), new Date())}` : 'Just now'}
          </div>
        </div>
      ) : null}
    </div>
  );
};

AnnotationsMarker.propTypes = {
  index: PropTypes.number,
  annotation: PropTypes.objectOf(PropTypes.any),
  create: PropTypes.bool,
  addAction: PropTypes.func,
  cancelAction: PropTypes.func,
  deleteAction: PropTypes.func,
  toggleEdit: PropTypes.func,
};

export default React.memo(AnnotationsMarker);
