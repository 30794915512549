import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

// import { ReactComponent as ImgIcon } from 'assets/notification/imgIcon.svg';
import { ReactComponent as GalleryIcon } from 'assets/_v2.0/navBar/galleryIcon.svg';
import Avatar from 'layouts/MainLayout/UserNotifications/uiComponents/Avatar';
import Time from 'layouts/MainLayout/UserNotifications/uiComponents/Time';

const UploadedType = ({ info }) => {
  return (
    <div className="Notification">
      <div className="Notification__avatar">
        <Avatar info={info} />
        <div className="galleryMarker">
          <GalleryIcon />
        </div>
      </div>
      <div className="Notification__content">
        <div className="Notification__content-head">
          <div>
            <div className="user-name">{`${info.user.name} ${info.user.surName}`}</div>
            <div className="notification-desc">Uploaded {info.uploaded.length} new images in <span>{info.project.title}:</span></div>
          </div>
        </div>
        <div className="UploadedType">
          <div className="UploadedType__list">
            {info.uploaded.map((img) => (
              <div className="UploadedType__list__item" key={img._id}>
                <img src={img.thumb} alt={img._id} />
              </div>
            ))}
          </div>
        </div>
        <Time info={info} />
      </div>
    </div>
  );
};

UploadedType.propTypes = {
  info: PropTypes.objectOf(PropTypes.any),
};

export default UploadedType;
