import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as CloseIcon } from 'assets/_v2.0/tools/closeIcon.svg';

const OffCanvasWrapper = ({
  title, titleActions, toggleShow, children, bottomAction, className,
}) => {
  const handleClose = () => toggleShow(false);

  return (
    <>
      <div className={`offCanvasWrapper ${className}`}>
        <div className="offCanvasWrapper__bg" onClick={handleClose} />
        <div className="offCanvasWrapper__content-wr">

          <div className="offCanvasWrapper__content-head">
            <div className="offCanvasWrapper__content-head-title">{title}</div>
            <div className="offCanvasWrapper__content-head-actions">
              {titleActions ? (
                <div>
                  {titleActions}
                </div>
              ) : null}
              <div onClick={handleClose}>
                <CloseIcon className="close" />
              </div>
            </div>
          </div>

          <div className="offCanvasWrapper__content scroll-wrapper">{children}</div>

          {bottomAction ? (
            <div className="offCanvasWrapper__content-actions">
              {bottomAction}
            </div>
          ) : null }
        </div>
      </div>
    </>
  );
};

OffCanvasWrapper.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  titleActions: PropTypes.node,
  bottomAction: PropTypes.node,
  toggleShow: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default OffCanvasWrapper;
