import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import Avatar from 'layouts/MainLayout/UserNotifications/uiComponents/Avatar';
import Time from 'layouts/MainLayout/UserNotifications/uiComponents/Time';

const InfoType = ({ info }) => {
  const description = () => {
    switch (info.type) {
      case 'addedToTeam':
        return (
          <div className="notification-desc">
            You have been added to a team
            <span> {info.team?.name}</span> by
            <span> {`${info.user.name} ${info.user.surName || ''}`}</span>.
          </div>
        );
      case 'projectCreated':
        return (
          <div className="notification-desc">
            You have successfully created a new project: <span>{info.project?.title}</span>.
          </div>
        );
      case 'projectDeleted':
        return (
          <div className="notification-desc">
            The project <span>{info.projectTitle}</span> has been deleted.
          </div>
        );
      case 'removedFromProject':
        return (
          <div className="notification-desc">
            You have been removed from the project
            <span> {info.project?.title}</span> by
            <span> {`${info.user.name} ${info.user.surName || ''}`}</span>.
          </div>
        );
      case 'removedFromTeam':
        return (
          <div className="notification-desc">
            You have been removed from a team
            <span> {info.project?.title}</span> by
            <span> {`${info.user.name} ${info.user.surName || ''}`}</span>.
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="Notification">
      <div className="Notification__avatar">
        <Avatar info={info} />
      </div>
      <div className="Notification__content">
        <div className="Notification__content-head">
          <div>
            <div className="user-name">{`${info.user?.name || 'Delete'} ${info.user?.surName || 'User'}`}</div>
            {description()}
          </div>
        </div>
        <Time info={info} />
      </div>
    </div>
  );
};

InfoType.propTypes = {
  info: PropTypes.objectOf(PropTypes.any),
};

export default InfoType;
