import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as CloseIcon } from 'assets/_v2.0/tools/closeIcon.svg';

const ModalWrapper = ({
  title, toggleShow, children,
}) => {
  const handleClose = () => toggleShow(false);
  return (
    <>
      <div className="ModalConfirm">
        <div className="ModalConfirm__bg" onClick={handleClose} />
        <div className="ModalConfirm__content">

          <div className="ModalConfirm__content-head">
            <div className="ModalConfirm__content-head-title">{title}</div>
            <CloseIcon onClick={handleClose} className="close" />
          </div>

          {children}

        </div>
      </div>
    </>
  );
};

ModalWrapper.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.any,
  ]).isRequired,
  toggleShow: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ModalWrapper;
