import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ClockImg } from 'assets/_v2.0/navBar/iconClock.svg';
import { formatDistanceToNowStrict } from 'date-fns';

const Time = ({ info }) => {
  return (
    <div className="notification-time">
      {!info.status && <div className="textBeforeTime">New</div>}
      <ClockImg className="clockImg" />
      {formatDistanceToNowStrict(new Date(info.created), new Date(), {
        addSuffix: true,
      })} ago
    </div>
  );
};

Time.propTypes = {
  info: PropTypes.objectOf(PropTypes.any),
};

export default Time;
