import { initialFilterImage } from 'staticFields';

export const imageSort = (el, image) => {
  const sort = el.split('|');

  if (!image.csv) return false;

  if (sort[0] === 'defectRating') {
    const rating = Math.max(...image.csv.polygons.map((polygon) => {
      if (typeof polygon.info[sort[0]] !== 'undefined') {
        return polygon.info[sort[0]];
      }
      return 0;
    })).toString();

    if (sort[1] === '4') {
      return rating === '0';
    }

    return rating === sort[1];
  }

  return image.csv.polygons
    .some((polygon) => polygon.info[sort[0]] === sort[1]);
};

export const filterImages = (images, filters) => {
  const filterKeys = Object.keys(filters);
  return images.filter((image) => {
    return filterKeys.every((key) => {
      switch (key) {
        case 's':
          if (filters[key].toLowerCase().trim().length) {
            return image.title.toLowerCase().trim().includes(filters[key].toLowerCase().trim());
          }
          break;
        case 'sort':
          if (!filters[key].length) return false;
          if (JSON.stringify(filters[key]) === JSON.stringify(initialFilterImage)) return true;
          return filters[key].some((el) => {
            return imageSort(el, image);
          });
        case 'deviseType':
          if (!filters[key]) return true;
          return image.type === filters[key];
        default:
          return true;
      }

      return true;
    });
  });
};

export const hexToRgbA = (hex) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},0.1)`;
  }
  throw new Error('Bad Hex');
};
