export default (password) => {
  let block = false;
  const errors = {};

  if (!password.oldPassword) {
    errors.oldPassword = 'Old password is Required';
    block = true;
  }

  if (!password.newPassword.trim()) {
    errors.newPassword = 'New password is Required';
    block = true;
  } else if (password.newPassword.trim().length < 6) {
    errors.newPassword = 'New password must be at least 6 characters long.';
    block = true;
  } else if (
    !/[a-zA-Z]/.test(password.newPassword)
    || !/[0-9]/.test(password.newPassword)
  ) {
    errors.newPassword = 'Password is too simple!';
    block = true;
  }

  if (!password.repeatNewPassword) {
    errors.repeatNewPassword = 'Password not match!';
    block = true;
  } else if (password.newPassword !== password.repeatNewPassword) {
    errors.repeatNewPassword = 'Password not match!';
    block = true;
  }

  return { block, errors };
};
