import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './newPhotoMarker.scss';

const NewPhotoMarker = ({
  onClick, photo, heatMap,
}) => {
  const [color, setColor] = useState(null);

  useEffect(() => {
    if (photo.csv && !color) {
      const green = photo.csv.polygons
        .filter((p) => !p.info.defectRating).length;
      const yellow = photo.csv.polygons
        .filter((p) => p.info?.defectRating && p.info.defectRating === '1').length;
      const orange = photo.csv.polygons
        .filter((p) => p.info?.defectRating && p.info.defectRating === '2').length;
      const red = photo.csv.polygons
        .filter((p) => p.info?.defectRating && p.info.defectRating === '3').length;

      if (red) {
        return setColor('red');
      }

      if (orange) {
        return setColor('orange');
      }

      if (yellow) {
        return setColor('yellow');
      }

      if (green) {
        return setColor('green');
      }
    }

    return () => {};
  }, []);

  return (
    <div className="newPhotoMarker-wrapper">
      <div
        className="newPhotoMarker"
        style={{ backgroundColor: color }}
        onClick={onClick}
      />
      {heatMap ? (
        <span
          data-color={color}
          className="heatMap"
        />
      ) : null}
    </div>
  );
};

NewPhotoMarker.propTypes = {
  onClick: PropTypes.func,
  photo: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
  ]),
  heatMap: PropTypes.bool,
};

export default React.memo(NewPhotoMarker);
