import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import fileUploader from 'utils/fileUploader';
import { useHistory } from 'react-router-dom';
import axios from 'utils/axios';
import { url } from 'config';
import { saveAs } from 'file-saver';
import useAuth from 'hooks/useAuth';
import { initialFilterImage } from 'staticFields';
import csvUploader from '../utils/csvUploader';

const initialState = {
  longBar: JSON.parse(window.localStorage.getItem('longBar')) || false,
  activeMenuTab: 'Projects Map',
  users: [],
  teams: [],
  projects: [],
  companies: [],
  favoriteImages: [],
  previewProject: false,
  imageFilters: initialFilterImage,
  showModalCreateProject: false,
  activeMode: 'view',
  showUserSetting: false,
  showUserNotifications: false,
  mapType: 'hybrid',
};

// create logic for Context
const reducer = (state, action) => {
  switch (action.type) {
    //APP
    case 'INITDATA': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'ADDPROJECT': {
      return {
        ...state,
        projects: [
          ...state.projects,
          action.payload,
        ],
      };
    }
    case 'TOGGLEUSERSETTING': {
      return {
        ...state,
        showUserSetting: !state.showUserSetting,
      };
    }
    case 'TOGGLEUSERNOTIFICATIONS': {
      return {
        ...state,
        showUserNotifications: !state.showUserNotifications,
      };
    }
    case 'SETACTIVEMODE': {
      return {
        ...state,
        activeMode: action.payload,
      };
    }
    case 'TOGGLECREATEPROJECTMODAL': {
      return {
        ...state,
        showModalCreateProject: !state.showModalCreateProject,
      };
    }
    case 'TOGGLELONGBAR': {
      return {
        ...state,
        longBar: !state.longBar,
      };
    }
    case 'CHANGEACTIVEMENUTAB': {
      return {
        ...state,
        activeMenuTab: action.payload,
      };
    }
    case 'CHANGEMAPTYPE': {
      return {
        ...state,
        mapType: action.payload,
      };
    }
    case 'TOGGLEPREVIEWPROJECT': {
      return {
        ...state,
        previewProject: !state.previewProject,
      };
    }
    //COMPANIES
    case 'COMPANIESCHANGE': {
      return {
        ...state,
        companies: [...action.payload],
      };
    }
    //USERS
    case 'USERSCHANGE': {
      return {
        ...state,
        users: [...action.payload],
      };
    }
    //TEAMS
    case 'TEAMSCHANGE': {
      return {
        ...state,
        teams: [...action.payload],
      };
    }
    //PROJECTS
    case 'PROJECTSCHANGE': {
      return {
        ...state,
        projects: [...action.payload],
      };
    }
    case 'CHANGEFAVORITEIMAGE': {
      return {
        ...state,
        favoriteImages: { ...action.payload },
      };
    }
    case 'IMAGEFILTERSCHANGE': {
      return {
        ...state,
        imageFilters: [...action.payload],
      };
    }

    default: {
      return state;
    }
  }
};

const AppContext = createContext(initialState);

export const AppProvider = ({ children }) => {
  const { appData, isAuthenticated, updateUser } = useAuth();
  const favoriteImages = JSON.parse(window.localStorage.getItem('favoriteImages')) || [];
  const [state, dispatch] = useReducer(reducer, { ...initialState, favoriteImages });
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    const initialise = async () => {
      dispatch({
        type: 'INITDATA',
        payload: { ...appData },
      });
    };
    initialise();
  }, [appData]);

  const diff = (a1, a2) => a1.filter((i) => !a2.includes(i))
    .concat(a2.filter((i) => !a1.includes(i)));

  const changeRelations = (item, arr, belongsTo, arrRelations, typeAction, clear) => {
    const newArr = arr.map((itemArr) => {
      const newItemArr = { ...itemArr };
      if (!item[arrRelations].includes(newItemArr._id) || clear) {
        newItemArr[belongsTo] = newItemArr[belongsTo].filter((id) => item._id !== id);
      } else if (
        item[arrRelations].includes(newItemArr._id)
        && !newItemArr[belongsTo].includes(item._id)
      ) {
        newItemArr[belongsTo].push(item._id);
      }

      return newItemArr;
    });
    dispatch({ type: typeAction, payload: newArr });
  };

  const createCSV = (items) => {
    const head = [
      'Type',
      'Pole Types',
      'Pole Component',
      'Defect List',
      'Defect Rating',
      'Points',
      'MinX',
      'MinY',
      'MaxX',
      'MaxY',
    ];

    const cols = items.map((item) => {
      const col = [
        item.type,
        item.info?.poleType,
        item.info?.poleComponent,
        item.info?.defectList,
        item.info?.defectRating,
      ];
      switch (item.type) {
        case 'box':
          col.push.apply(col, [
            '---',
            item.x,
            item.y,
            item.x + item.width,
            item.y + item.height,
          ]);
          break;
        case 'polygon':
          col.push.apply(col, [
            `"${item.points}"`,
            '---',
            '---',
            '---',
            '---',
          ]);
          break;
        default:
          break;
      }
      return col;
    });

    return new Blob(
      [`${head.join(',')}\r\n`, ...cols.map((col) => `${col.join(',')}\r\n`)],
      { type: 'text/csv;charset=utf-8' },
    );
  };

  //APP
  const setMapType = (type) => {
    dispatch({
      type: 'CHANGEMAPTYPE',
      payload: type,
    });
  };

  const toggleUserNotifications = () => {
    dispatch({
      type: 'TOGGLEUSERNOTIFICATIONS',
    });
  };

  const toggleUserSetting = () => {
    dispatch({
      type: 'TOGGLEUSERSETTING',
    });
  };

  const toggleModalCreateProject = () => {
    dispatch({
      type: 'TOGGLECREATEPROJECTMODAL',
    });
  };

  const setActiveMode = (mode) => {
    if (!mode) return;

    dispatch({
      type: 'SETACTIVEMODE',
      payload: mode,
    });
  };

  const toggleLongBar = () => {
    window.localStorage.setItem('longBar', !state.longBar);
    dispatch({
      type: 'TOGGLELONGBAR',
    });
  };

  const changeActiveMenuTab = (tab) => {
    dispatch({
      type: 'CHANGEACTIVEMENUTAB',
      payload: tab,
    });
  };

  const setPreviewProject = () => {
    dispatch({
      type: 'TOGGLEPREVIEWPROJECT',
    });
  };

  //COMPANIES
  const companiesAPI = {
    add: async (company) => {
      try {
        const bodyFormData = new FormData();

        if (company.logo?.file) {
          bodyFormData.append('logo', company.logo.file);
        }

        bodyFormData.append('name', company.name);
        bodyFormData.append('access', JSON.stringify(company.access));
        bodyFormData.append('description', company.description);

        const response = await axios.post(`${url}/api/company`, bodyFormData);

        dispatch({
          type: 'COMPANIESCHANGE',
          payload: [...state.companies, response.data],
        });
        enqueueSnackbar('Success!', { variant: 'success' });
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
    },
    edit: async (company) => {
      try {
        const companies = [...state.companies];
        const companyIndex = companies.findIndex((el) => el._id === company._id);

        if (JSON.stringify(companies[companyIndex]) === JSON.stringify(company)) return false;

        const bodyFormData = new FormData();

        if (company.logo?.file) {
          bodyFormData.append('logo', company.logo.file);
        }

        bodyFormData.append('name', company.name);
        bodyFormData.append('access', JSON.stringify(company.access));
        bodyFormData.append('description', company.description);

        const response = await axios.put(`${url}/api/company/${company._id}`, bodyFormData);

        companies[companyIndex] = response.data;
        dispatch({
          type: 'COMPANIESCHANGE',
          payload: companies,
        });
        enqueueSnackbar('Success!', { variant: 'success' });

        return response.data;
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
        return false;
      }
    },
    delete: async (company) => {
      try {
        const response = await axios.delete(`${url}/api/company/${company._id}`);
        const { _id } = response.data;

        if (_id) {
          const newCompanies = state.companies.filter((el) => el._id !== company._id);
          dispatch({
            type: 'COMPANIESCHANGE',
            payload: newCompanies,
          });
          enqueueSnackbar('Success!', { variant: 'success' });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
    },
    getById: (id) => state.companies.find((u) => u._id === id),
    getByIds: (arr) => {
      if (Array.isArray(arr)) {
        return state.companies.filter((u) => arr.includes(u._id));
      }

      return [];
    },
  };

  //USERS
  const usersAPI = {
    add: async (user) => {
      try {
        const bodyFormData = new FormData();

        if (user.avatar?.file) {
          bodyFormData.append('avatar', user.avatar.file);
        }

        bodyFormData.append('name', user.name);
        bodyFormData.append('surName', user.surName);
        bodyFormData.append('email', user.email);
        bodyFormData.append('phone', user.phone || '');
        bodyFormData.append('role', user.role);
        bodyFormData.append('company', user.company);

        const response = await axios.post(`${url}/api/auth/invite`, bodyFormData);

        dispatch({
          type: 'USERSCHANGE',
          payload: [...state.users, response.data],
        });

        //company update
        const companies = [...state.companies];
        const companyIndex = companies.findIndex((el) => el._id === user.company);

        companies[companyIndex].users.push(response.data._id);
        dispatch({
          type: 'COMPANIESCHANGE',
          payload: companies,
        });

        enqueueSnackbar('Success!', { variant: 'success' });
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
    },
    edit: async (user) => {
      try {
        const bodyFormData = new FormData();
        const users = [...state.users];
        const userIndex = state.users.findIndex((el) => el._id === user._id);

        if (JSON.stringify(users[userIndex]) === JSON.stringify(user)) return user;

        if (user.avatar?.file) {
          bodyFormData.append('avatar', user.avatar.file);
        }

        bodyFormData.append('name', user.name);
        bodyFormData.append('surName', user.surName);
        bodyFormData.append('email', user.email);
        bodyFormData.append('phone', user.phone);
        bodyFormData.append('role', user.role);
        bodyFormData.append('teams', JSON.stringify(user.teams));
        bodyFormData.append('follow', JSON.stringify(user.follow));

        if (diff(users[userIndex].teams, user.teams).length) {
          changeRelations(user, state.teams, 'users', 'teams', 'TEAMSCHANGE');
        }

        const response = await axios.put(`${url}/api/user/${user._id}`, bodyFormData);

        users[userIndex] = response.data;
        dispatch({
          type: 'USERSCHANGE',
          payload: users,
        });
        enqueueSnackbar('Success!', { variant: 'success' });

        return response.data;
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message || e}`, { variant: 'error' });
        return user;
      }
    },
    delete: async (user) => {
      try {
        const response = await axios.delete(`${url}/api/user/${user._id}`);
        const { _id } = response.data;

        if (_id) {
          const newUsers = state.users.filter((el) => el._id !== user._id);
          changeRelations(user, state.teams, 'users', 'teams', 'TEAMSCHANGE', true);
          dispatch({
            type: 'USERSCHANGE',
            payload: newUsers,
          });
          enqueueSnackbar('Success!', { variant: 'success' });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
    },
    getById: (id) => state.users.find((u) => u._id === id),
    getByIds: (arr) => {
      if (Array.isArray(arr)) {
        return state.users.filter((u) => arr.includes(u._id));
      }

      return [];
    },
    changePassword: async (user, oldPassword, password) => {
      try {
        const response = await axios.put(`${url}/api/user/change-password/${user._id}`, {
          oldPassword, password,
        });

        if (response.data) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
    },
  };

  //TEAMS
  const teamsAPI = {
    add: async (team) => {
      try {
        let newUsers = [...state.users];
        const response = await axios.post(`${url}/api/team`, {
          ...team,
          users: team.users ?? [],
        });

        if (response.data.users.length) {
          newUsers = newUsers.map((user) => {
            user.teams.push(response.data._id);

            return user;
          });

          dispatch({
            type: 'USERSCHANGE',
            payload: newUsers,
          });
        }

        dispatch({
          type: 'TEAMSCHANGE',
          payload: [...state.teams, response.data],
        });

        enqueueSnackbar('Success!', { variant: 'success' });
        // eslint-disable-next-line no-unreachable
      } catch (e) {
        console.log('error', e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
    },
    edit: async (team) => {
      try {
        const teams = [...state.teams];
        const teamIndex = state.teams.findIndex((el) => el._id === team._id);

        if (JSON.stringify(teams[teamIndex]) === JSON.stringify(team)) return false;

        if (diff(teams[teamIndex].users, team.users).length) {
          changeRelations(team, state.users, 'teams', 'users', 'USERSCHANGE');
        }

        const response = await axios.put(`${url}/api/team/${team._id}`, team);

        teams[teamIndex] = response.data;
        dispatch({
          type: 'TEAMSCHANGE',
          payload: teams,
        });
        enqueueSnackbar('Success!', { variant: 'success' });

        return true;
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
        return false;
      }
    },
    delete: async (team) => {
      try {
        const { teams, users } = state;

        const response = await axios.delete(`${url}/api/team/${team._id}`);
        const { _id } = response.data;

        if (_id) {
          const newTeams = teams.filter((el) => el._id !== team._id);
          changeRelations(team, users, 'teams', 'users', 'USERSCHANGE', true);
          dispatch({
            type: 'TEAMSCHANGE',
            payload: newTeams,
          });
          enqueueSnackbar('Success!', { variant: 'success' });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
    },
    getById: (id) => state.teams.find((t) => t._id === id),
    getByIds: (arr) => {
      if (Array.isArray(arr)) {
        return state.teams.filter((t) => arr.includes(t._id));
      }

      return [];
    },
  };

  //PROJECTS
  const projectAPI = {
    add: async (project, loadRef) => {
      const uuid = uuidv4();
      const {
        gisFiles, droneImages, phoneImages, ...rest
      } = project;

      const files = await fileUploader({
        gisFiles,
        droneImages,
        phoneImages,
      }, uuid, loadRef);

      const response = await axios.post(`${url}/api/project`, {
        ...rest,
        files,
        uuid,
      });

      dispatch({
        type: 'PROJECTSCHANGE',
        payload: [...state.projects, response.data],
      });

      return response;
    },
    edit: async (project) => {
      try {
        const projects = [...state.projects];
        const projectIndex = state.projects.findIndex((el) => el._id === project._id);
        if (JSON.stringify(projects[projectIndex]) !== JSON.stringify(project)) {
          const response = await axios.put(`${url}/api/project/${project._id}`, project);

          projects[projectIndex] = response.data;
          dispatch({
            type: 'PROJECTSCHANGE',
            payload: projects,
          });

          enqueueSnackbar('Success!', { variant: 'success' });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
    },
    delete: async (project) => {
      try {
        await axios.delete(`${url}/api/project/${project._id}`);
        const newProjects = state.projects.filter((el) => el._id !== project._id);
        dispatch({
          type: 'PROJECTSCHANGE',
          payload: newProjects,
        });
        enqueueSnackbar('Success!', { variant: 'success' });
        return true;
      } catch (error) {
        console.log(error.message);
        return null;
      }
    },
    getById: (id) => state.projects.find((p) => p?._id === id) || null,
    getByIds: (arr) => {
      if (Array.isArray(arr)) {
        return state.projects.filter((p) => arr.includes(p._id));
      }

      return [];
    },
    getPreview: async (id) => {
      if (isAuthenticated) {
        return history.push(`/project/${id}`);
      }
      try {
        const response = await axios(`${url}/api/project/${id}`);
        dispatch({
          type: 'PROJECTSCHANGE',
          payload: [response.data],
        });
        dispatch({ type: 'TOGGLEPREVIEWPROJECT' });
        return true;
      } catch (e) {
        console.log(e);
        return history.push('/login');
      }
    },
    annotation: {
      add: async (id, item, timeline) => {
        try {
          const projects = [...state.projects];
          const projectIndex = state.projects.findIndex((el) => el._id === id);

          const response = await axios.post(`${url}/api/annotation`, {
            ...item,
            project: id,
            timeline,
          });

          projects[projectIndex] = response.data;

          dispatch({
            type: 'PROJECTSCHANGE',
            payload: projects,
          });
        } catch (e) {
          console.log(e.message);
        }
      },
      edit: async (id, item) => {
        try {
          const projects = [...state.projects];
          const projectIndex = state.projects.findIndex((el) => el._id === id);

          const response = await axios.put(`${url}/api/annotation/${item._id}`, item);

          projects[projectIndex] = response.data;

          dispatch({
            type: 'PROJECTSCHANGE',
            payload: projects,
          });
        } catch (e) {
          console.log(e.message);
        }
      },
      delete: async (id, item) => {
        try {
          const projects = [...state.projects];
          const projectIndex = state.projects.findIndex((el) => el._id === id);

          const response = await axios.delete(`${url}/api/annotation/${item._id}`);

          projects[projectIndex] = response.data;

          dispatch({
            type: 'PROJECTSCHANGE',
            payload: projects,
          });
        } catch (e) {
          console.log(e.message);
        }
      },
    },
    images: {
      add: async (project, files, timeLineId) => {
        const projects = [...state.projects];
        const projectIndex = state.projects.findIndex((el) => el._id === project._id);

        const response = await axios.put(`${url}/api/project/uploadingFiles/${timeLineId}`, {
          files,
        });

        projects[projectIndex] = response.data;

        dispatch({
          type: 'PROJECTSCHANGE',
          payload: projects,
        });

        enqueueSnackbar('Success!', { variant: 'success' });
        return response.data;
      },
      edit: () => {},
      delete: async (id, items) => {
        if (!items.length) return false;
        try {
          const projects = [...state.projects];
          const projectIndex = state.projects.findIndex((el) => el._id === id);
          enqueueSnackbar('Loading...', { variant: 'warning' });

          const response = await axios.post(`${url}/api/project/deleteFile`, {
            images: items,
          });
          projects[projectIndex] = response.data;

          dispatch({
            type: 'PROJECTSCHANGE',
            payload: projects,
          });
          enqueueSnackbar('Success!', { variant: 'success' });
          return true;
        } catch (e) {
          console.log(e);
          enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
          return false;
        }
      },
      markers: {
        add: async (projectId, imageId, item) => {
          try {
            const projects = [...state.projects];
            const projectIndex = state.projects.findIndex((el) => el._id === projectId);

            const response = await axios.post(`${url}/api/annotation/image`, { ...item, imageId });

            projects[projectIndex] = response.data;

            dispatch({
              type: 'PROJECTSCHANGE',
              payload: projects,
            });
          } catch (e) {
            console.log(e.message);
          }
        },
        edit: async (projectId, imageId, item) => {
          try {
            const projects = [...state.projects];
            const projectIndex = state.projects.findIndex((el) => el._id === projectId);

            const response = await axios.put(`${url}/api/annotation/${item._id}`, item);

            projects[projectIndex] = response.data;

            dispatch({
              type: 'PROJECTSCHANGE',
              payload: projects,
            });
          } catch (e) {
            console.log(e.message);
          }
        },
        delete: async (projectId, imageId, item) => {
          try {
            const projects = [...state.projects];
            const projectIndex = state.projects.findIndex((el) => el._id === projectId);

            const response = await axios.delete(`${url}/api/annotation/${item._id}`);

            projects[projectIndex] = response.data;

            dispatch({
              type: 'PROJECTSCHANGE',
              payload: projects,
            });
          } catch (e) {
            console.log(e.message);
          }
        },
      },
      polygons: {
        add: async (project, image, newPolygon) => {
          try {
            let filePath = `${project._id}/csv/${image._id}.csv`;
            const polygons = [];

            const projects = await [...state.projects].map((item) => {
              const newProject = { ...item };

              newProject.timelines = newProject.timelines.map((line) => {
                const newLine = { ...line };

                if (newLine._id === image.timeline) {
                  newLine.images = newLine.images.map((img) => {
                    const newImage = { ...img };
                    if (newImage._id === image._id) {
                      newImage.csv = newImage.csv
                        ? {
                          ...newImage.csv,
                          polygons: [...newImage.csv.polygons, newPolygon],
                        }
                        : {
                          polygons: [newPolygon],
                        };
                      polygons.push.apply(polygons, [...newImage.csv.polygons]);
                    }

                    return newImage;
                  });
                  filePath = `${newLine.uuid}/csv/${image._id}.csv`;
                }

                return newLine;
              });

              return newProject;
            });

            dispatch({
              type: 'PROJECTSCHANGE',
              payload: projects,
            });

            const file = createCSV(polygons);
            const upload = await csvUploader(file, filePath);

            if (upload) {
              await axios.post(`${url}/api/project/polygon`, {
                polygon: newPolygon,
                imageId: image._id,
                ...upload,
              });
            }
          } catch (e) {
            console.log(e);
          }
        },
        edit: async (project, image, editPolygon) => {
          try {
            let currentImage = {};
            const projects = [...state.projects].map((item) => {
              const newProject = { ...item };

              newProject.timelines = newProject.timelines.map((line) => {
                const newLine = { ...line };

                if (newLine._id === image.timeline) {
                  newLine.images = newLine.images.map((img) => {
                    const newImage = { ...img };
                    if (newImage._id === image._id) {
                      newImage.csv.polygons = newImage.csv.polygons.map((polygon) => {
                        if (editPolygon._id === polygon._id) {
                          return editPolygon;
                        }
                        return polygon;
                      });
                      currentImage = newImage;
                    }

                    return newImage;
                  });
                }

                return newLine;
              });

              return newProject;
            });

            dispatch({
              type: 'PROJECTSCHANGE',
              payload: projects,
            });

            const file = createCSV(currentImage.csv.polygons);
            const upload = await csvUploader(file, currentImage.csv.Key);

            if (upload) {
              await axios.post(`${url}/api/project/editPolygon`, {
                polygon: editPolygon,
                imageId: image._id,
              });
            }
          } catch (e) {
            console.log(e);
          }
        },
        delete: async (project, image, deletePolygon) => {
          try {
            let currentImage = {};
            const projects = [...state.projects].map((item) => {
              const newProject = { ...item };

              newProject.timelines = newProject.timelines.map((line) => {
                const newLine = { ...line };

                if (newLine._id === image.timeline) {
                  newLine.images = newLine.images.map((img) => {
                    const newImage = { ...img };
                    if (newImage._id === image._id) {
                      newImage.csv.polygons = newImage.csv.polygons.filter((polygon) => {
                        return deletePolygon._id !== polygon._id;
                      });
                      currentImage = newImage;
                    }

                    return newImage;
                  });
                }

                return newLine;
              });

              return newProject;
            });

            dispatch({
              type: 'PROJECTSCHANGE',
              payload: projects,
            });

            const file = createCSV(currentImage.csv.polygons);
            const upload = await csvUploader(file, currentImage.csv.Key);

            if (upload) {
              await axios.post(`${url}/api/project/deletePolygon`, {
                polygon: deletePolygon,
                imageId: image._id,
              });
            }
          } catch (e) {
            console.log(e);
          }
        },
      },
      setFilters: (arr) => {
        dispatch({
          type: 'IMAGEFILTERSCHANGE',
          payload: arr,
        });
      },
    },
    confirmation: async (id, accept) => {
      try {
        const res = await axios.put(`${url}/api/project/confirmation/${id}`, { status: accept });
        const { project, notify } = res.data;
        if (!accept) {
          enqueueSnackbar('Success!', { variant: 'success' });
          return notify;
        }

        if (projectAPI.getById(project._id)) {
          const projects = [...state.projects];
          const projectIndex = state.projects.findIndex((el) => el._id === project._id);
          if (JSON.stringify(projects[projectIndex]) !== JSON.stringify(project)) {
            projects[projectIndex] = project;
            dispatch({
              type: 'PROJECTSCHANGE',
              payload: projects,
            });
          }
        } else {
          dispatch({
            type: 'PROJECTSCHANGE',
            payload: [...state.projects, project],
          });
        }

        enqueueSnackbar('Success!', { variant: 'success' });
        return notify;
      } catch (e) {
        console.log(e.message);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
      return [];
    },
    shareToEmail: async (id, email) => {
      try {
        await axios.post(`${url}/api/project/share/${id}`, { email });

        enqueueSnackbar('Success!', { variant: 'success' });
      } catch (e) {
        console.log(e.message);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
    },
    invite: async (data) => {
      try {
        const response = await axios.post(`${url}/api/auth/invite`, data);
        enqueueSnackbar('Success!', { variant: 'success' });
        return response;
      } catch (e) {
        console.log(e.message);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
        return false;
      }
    },
    updateUsers: async (id, userIdList) => {
      try {
        const projects = [...state.projects];
        const projectIndex = state.projects.findIndex((el) => el._id === id);
        const response = await axios.put(`${url}/api/project/updateUsers/${id}`, {
          userIdList,
        });

        projects[projectIndex] = response.data;
        dispatch({
          type: 'PROJECTSCHANGE',
          payload: projects,
        });
        enqueueSnackbar('Success!', { variant: 'success' });
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
      }
    },
    uploadGisFiles: async (project, files, timeLineId) => {
      const projects = [...state.projects];
      const projectIndex = state.projects.findIndex((el) => el._id === project._id);

      const response = await axios.put(`${url}/api/project/uploadingFiles/${timeLineId}`, {
        files,
      });

      projects[projectIndex] = response.data;

      dispatch({
        type: 'PROJECTSCHANGE',
        payload: projects,
      });

      enqueueSnackbar('Success!', { variant: 'success' });
    },
    deleteGisFile: async (project, file) => {
      if (!file) return false;
      try {
        const projects = [...state.projects];
        const projectIndex = state.projects.findIndex((el) => el._id === project._id);
        enqueueSnackbar('Loading...', { variant: 'warning' });

        const response = await axios.post(`${url}/api/project/deleteFile`, {
          gisFiles: [file],
        });
        projects[projectIndex] = response.data;

        dispatch({
          type: 'PROJECTSCHANGE',
          payload: projects,
        });
        enqueueSnackbar('Success!', { variant: 'success' });
        return true;
      } catch (e) {
        console.log(e);
        enqueueSnackbar(`Error! - ${e.message}`, { variant: 'error' });
        return false;
      }
    },
    createPDF: async (html, email, projectName, logo) => {
      try {
        const pdfName = `report_${new Date().toLocaleDateString()}.pdf`;
        const pdf = await axios.post(`${url}/api/pdf`, {
          html,
          email,
          projectName,
          logo,
        }, {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        });
        return saveAs(new Blob([pdf.data], { type: 'application/pdf' }), pdfName);
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    saveReport: async (projectId, report) => {
      try {
        const response = await axios.post(`${url}/api/project/report/${projectId}`, { report });
        updateUser(response.data);
        enqueueSnackbar('Success!', { variant: 'success' });
        return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
  };

  const favoriteImagesAPI = {
    change: (image, id) => {
      const images = { ...state.favoriteImages };

      if (images[id]) {
        if (!images[id].includes(image._id)) {
          images[id] = [...images[id], image._id];
        } else {
          images[id] = images[id].filter((item) => item !== image._id);
        }
      } else {
        images[id] = [image._id];
      }

      window.localStorage.setItem('favoriteImages', JSON.stringify(images));
      dispatch({
        type: 'CHANGEFAVORITEIMAGE',
        payload: images,
      });
    },
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        //APP
        setMapType,
        toggleUserNotifications,
        toggleUserSetting,
        toggleModalCreateProject,
        toggleLongBar,
        changeActiveMenuTab,
        setPreviewProject,
        // MODE
        setActiveMode,
        //COMPANIES
        companiesAPI,
        //USERS
        usersAPI,
        //TEAMS
        teamsAPI,
        //PROJECTS
        projectAPI,
        favoriteImagesAPI,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppContext;
