import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';

// import { ReactComponent as CloseIcon } from 'assets/adminTable/closeIcon.svg';

import useNotifications from 'hooks/useNotifications';

import UploadedType from './components/UploadedType';
import InvitedType from './components/InvitedType';
import MarkedNewIssueOnPhotoType from './components/MarkedNewIssueOnPhotoType';
import CreateNewCommentType from './components/CreateNewCommentType';
import InfoType from './components/InfoType';

const Notification = ({ info }) => {
  const {
    notifications,
    // deleteNotification,
    changeStatus,
  } = useNotifications();
  const Types = {
    uploaded: UploadedType,
    invited: InvitedType,
    markedNewIssueOnPhoto: MarkedNewIssueOnPhotoType,

    createNewComment: CreateNewCommentType,

    projectCreated: InfoType,
    projectDeleted: InfoType,
    removedFromProject: InfoType,
    removedFromTeam: InfoType,
    addedToTeam: InfoType,
  };

  const handleHoverItem = (item) => () => {
    if (!item.status) {
      changeStatus(item._id);
    }
    if (!notifications.filter((e) => {
      return e.status === 0 && typeof Types[info.type] !== 'undefined';
    }).length) {
      document.querySelector('.have-notifications')?.classList.remove('have-notifications');
    }
  };

  // const handleDelete = (item) => () => {
  //   deleteNotification(item._id);
  // };

  if (typeof Types[info.type] !== 'undefined') {
    return (
      <div
        className={`UserNotifications__list__item ${info.status ? '' : 'not-show'}`}
        onMouseEnter={handleHoverItem(info)}
      >
        {React.createElement(Types[info.type], { info })}
      </div>
    );
  }
  return null;
};

Notification.propTypes = {
  info: PropTypes.objectOf(PropTypes.any).isRequired,
};

const UserNotifications = () => {
  const {
    notifications,
  } = useNotifications();

  return (
    <div className="UserNotifications scroll-wrapper">
      {notifications.length ? (
        <div className="UserNotifications__list">
          {notifications.map((item) => <Notification info={item} key={item._id} />)}
        </div>
      ) : <p>No notifications.</p>}
    </div>
  );
};

export default UserNotifications;
