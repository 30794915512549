export const adminPanelPerm = {
  view: 'VIEW_ADMIN_PANEL',
  companies: 'ACCESS_TO_COMPANY',
  users: {
    add: 'ACCESS_ADD_USER',
    addRole: {
      FieldStaff: 'ACCESS_ADD_FIELD_STAFF',
      Engineer: 'ACCESS_ADD_ENGINEER',
      ProjectManager: 'ACCESS_ADD_PROJECT_MANAGER',
      Admin: 'ACCESS_ADD_ADMIN',
      SuperAdmin: 'ACCESS_ADD_SUPER_ADMIN',
    },
    edit: 'ACCESS_EDIT_USER',
    editRole: {
      FieldStaff: 'ACCESS_EDIT_FIELD_STAFF',
      Engineer: 'ACCESS_EDIT_ENGINEER',
      ProjectManager: 'ACCESS_EDIT_PROJECT_MANAGER',
      Admin: 'ACCESS_EDIT_ADMIN',
      SuperAdmin: 'ACCESS_EDIT_SUPER_ADMIN',
    },
    delete: 'ACCESS_DELETE_USER',
    deleteRole: {
      FieldStaff: 'ACCESS_DELETE_FIELD_STAFF',
      Engineer: 'ACCESS_DELETE_ENGINEER',
      ProjectManager: 'ACCESS_DELETE_PROJECT_MANAGER',
      Admin: 'ACCESS_DELETE_ADMIN',
      SuperAdmin: 'ACCESS_DELETE_SUPER_ADMIN',
    },
  },
  viewDashboard: 'VIEW_DASHBOARD',
};

export const projectPerm = {
  add: 'ADD_PROJECT',
  edit: 'EDIT_PROJECT',
  delete: 'DELETE_PROJECT',
  invite: 'INVITE_TO_PROJECT',
  share: 'SHARE_PROJECT',
  annotations: {
    add: 'ADD_ANNOTATIONS_PROJECT',
    edit: 'EDIT_ANNOTATIONS_PROJECT',
    delete: 'DELETE_ANNOTATIONS_PROJECT',
    view: 'VIEW_ANNOTATIONS_PROJECT',
  },
  images: {
    add: 'ADD_PROJECT_IMAGE',
    delete: 'DELETE_PROJECT_IMAGE',
    annotations: {
      add: 'ADD_ANNOTATIONS_PROJECT_IMAGE',
      edit: 'EDIT_ANNOTATIONS_PROJECT_IMAGE',
      delete: 'DELETE_ANNOTATIONS_PROJECT_IMAGE',
      view: 'VIEW_ANNOTATIONS_PROJECT_IMAGE',
    },
    polygons: {
      add: 'ADD_POLYGONS_PROJECT_IMAGE',
      edit: 'EDIT_POLYGONS_PROJECT_IMAGE',
      delete: 'DELETE_POLYGONS_PROJECT_IMAGE',
      view: 'VIEW_POLYGONS_PROJECT_IMAGE',
    },
  },
};

const FieldStaff = [
  projectPerm.annotations.view,
  projectPerm.images.annotations.view,
];

const Engineer = [
  ...FieldStaff,
  projectPerm.share,
  projectPerm.edit,
  projectPerm.annotations.add,
  projectPerm.annotations.edit,
  projectPerm.annotations.delete,
  projectPerm.images.add,
  projectPerm.images.delete,
  projectPerm.images.annotations.add,
  projectPerm.images.annotations.edit,
  projectPerm.images.annotations.delete,
  projectPerm.images.annotations.view,
  projectPerm.images.polygons.add,
  projectPerm.images.polygons.edit,
  projectPerm.images.polygons.delete,
  projectPerm.images.polygons.view,
  adminPanelPerm.viewDashboard,
];

const ProjectManager = [
  ...Engineer,
  projectPerm.add,
  projectPerm.delete,
  projectPerm.invite,
];

const Admin = [
  ...ProjectManager,
  adminPanelPerm.view,
  adminPanelPerm.users.add,
  adminPanelPerm.users.addRole.FieldStaff,
  adminPanelPerm.users.addRole.Engineer,
  adminPanelPerm.users.addRole.ProjectManager,
  adminPanelPerm.users.addRole.Admin,
  adminPanelPerm.users.edit,
  adminPanelPerm.users.editRole.FieldStaff,
  adminPanelPerm.users.editRole.Engineer,
  adminPanelPerm.users.editRole.ProjectManager,
  adminPanelPerm.users.delete,
  adminPanelPerm.users.deleteRole.FieldStaff,
  adminPanelPerm.users.deleteRole.Engineer,
  adminPanelPerm.users.deleteRole.ProjectManager,
  adminPanelPerm.users.deleteRole.Admin,
];

const SuperAdmin = [
  ...Admin,
  adminPanelPerm.companies,
  adminPanelPerm.users.editRole.Admin,
  adminPanelPerm.users.addRole.SuperAdmin,
  adminPanelPerm.users.deleteRole.SuperAdmin,
];

const usersRoles = {
  FieldStaff,
  Engineer,
  ProjectManager,
  Admin,
  SuperAdmin,
};

export const isPermission = (userRole, permission) => {
  if (!userRole) return false;

  const role = userRole.replace(/\s/g, '');
  if (!usersRoles[role]) return false;

  if (typeof permission === 'string') {
    return usersRoles[role].includes(permission);
  }
  if (Array.isArray(permission)) {
    return permission.every((item) => usersRoles[role].includes(item));
  }

  return false;
};
