import React, { useState, useEffect } from 'react';
import { ReactComponent as SearchIcon } from 'assets/creatingProject/searchIcon.svg';
import propTypes from './_types';
import Navigation from './Navigation';
import { isValidInviteUsers } from './_validation';
import useApp from '../../../hooks/useApp';
import TeamComponent from '../Components/TeamComponent';
import UserComponent from '../Components/UserComponent';

const Invite = ({
  activeStep,
  setActiveStep,
  newProject,
}) => {
  const {
    teams: teamsData,
    users: UsersData,
  } = useApp();
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const isValid = isValidInviteUsers(newProject.get);

  useEffect(() => {
    setFilteredUsers(
      UsersData.filter((user) => user.name.toLowerCase().includes(search.toLowerCase())),
    );
  }, [search, UsersData]);

  return (
    <>
      <div className="Invite">
        <div className="Invite__Title">Select users to invite them:</div>
        <form className="Invite__form">
          <input
            className="Invite__form-input"
            placeholder="Search users.."
            type="text"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="Invite__form-input-searchIcon">
            <div className="verticalLine" />
            <SearchIcon />
          </div>
          {errors.title && <span>{errors.title}</span>}
        </form>
        <div className="Invite__UserWrap">
          {search && filteredUsers.map((user, idx) => (
            <UserComponent key={idx} user={user} newProject={newProject} />
          ))}
          {!search && teamsData.map((team, indx) => (
            <TeamComponent team={team} key={indx} indx={indx} newProject={newProject} />
          ))}
        </div>
      </div>
      <Navigation
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        next="проверяет есть ли инвайт"
        handlerError={() => setErrors(isValid.errors)}
      />
    </>
  );
};

Invite.propTypes = propTypes;

export default Invite;
