import React from 'react';
import PropTypes from 'prop-types';
import './uploadingDoneModal.scss';

// eslint-disable-next-line no-unused-vars
import { ReactComponent as CheckMark } from 'assets/uploadsFiles/checkMark.svg';
// eslint-disable-next-line no-unused-vars
import { ReactComponent as CloseIcon } from 'assets/creatingProject/x.svg';

import ModalWrapper from 'uiComponents/ModalWrapper';
import { ReactComponent as CheckMarkIcon } from 'assets/creatingProject/checkMarkIcon.svg';
import { ReactComponent as Close } from '../../../../assets/creatingProject/x.svg';

// eslint-disable-next-line no-unused-vars
const UploadingDoneModal = ({ close, fileCounter }) => {
  const handleChangeModal = () => {
    close(false);
  };
  return (
    <div className="uploadingDoneModal">
      <ModalWrapper
        toggleShow={close}
        title="Uploading done"
      >
        <div className="uploadingDoneMod">
          <div className="loadingDoneBar">
            <div className="tickIconInBox">
              <CheckMarkIcon />
            </div>
            <div className="textButtonWrap">
              <div className="textLoadingBar">
                Your files have been uploaded successfully!
              </div>
              <div
                className="button"
                onClick={handleChangeModal}
              >
                Close
                <Close />
              </div>
            </div>
          </div>
          <div className="numberUploadFiles">
            <div>
              <div className="numberUploadFilesTitle margin">
                Uploaded:
              </div>
              <div className="numberUploadFilesDesc">{fileCounter}/{fileCounter} files</div>
            </div>
            <div>
              <div className="numberUploadFilesTitle">Time passed:</div>
              <div className="numberUploadFilesDesc">4s</div>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

UploadingDoneModal.propTypes = {
  fileCounter: PropTypes.number,
  close: PropTypes.func.isRequired,
};

export default UploadingDoneModal;

// <div className="loadingInformation">
//   Uploaded {fileCounter} file(s)
// </div>
