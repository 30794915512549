import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './navBar.scss';

import logoText from 'assets/_v2.0/navBar/logo-txt.png';
import { ReactComponent as Logo } from 'assets/_v2.0/navBar/logo.svg';
import { ReactComponent as ArrowIcon } from 'assets/_v2.0/navBar/arrow.svg';
import { ReactComponent as MapIcon } from 'assets/_v2.0/navBar/map.svg';
import { ReactComponent as AdminPanelIcon } from 'assets/_v2.0/navBar/adminPanelIcon.svg';
import { ReactComponent as ChartsIcon } from 'assets/_v2.0/navBar/charts.svg';
import { ReactComponent as NotificationsIcon } from 'assets/_v2.0/navBar/notifications.svg';
import { ReactComponent as SettingIcon } from 'assets/_v2.0/navBar/settingIcon.svg';
import { ReactComponent as ExitIcon } from 'assets/_v2.0/navBar/exitIcon.svg';
import { ReactComponent as AddIcon } from 'assets/_v2.0/navBar/add.svg';
import { ReactComponent as BellIcon } from 'assets/_v2.0/navBar/bellIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/navBar/close.svg';

import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import { url } from 'config';
import { adminPanelPerm, projectPerm, isPermission } from 'components/Guards/permissions';
import useNotifications from 'hooks/useNotifications';
import UserNotifications from '../UserNotifications';

const NavBar = ({ longBar, toggleLong, match }) => {
  const {
    toggleModalCreateProject,
    activeMode,
    toggleUserSetting,
    showUserSetting,
  } = useApp();
  const { logout, user } = useAuth();
  const { notifications } = useNotifications();
  const [activeBtn, setActiveBtn] = useState(false);
  const history = useHistory();

  const haveNotifications = notifications.filter((item) => !item.status).length ? ' have-notifications' : '';

  const handleToggleModal = () => {
    document.getElementById('notificationModal').classList.toggle('active');
    setActiveBtn(!activeBtn);
  };

  return (
    <div className={longBar ? 'nav-bar long-nav' : 'nav-bar'}>
      {activeMode !== 'create' ? (
        <div
          className="longNavButton longNavButtonScale"
          onClick={toggleLong}
        >
          <ArrowIcon />
        </div>
      ) : null}

      <div className="blockInNavBar">
        <div className="logo">
          <Logo className="logoInNavBar" />
          {longBar && <img alt="textNearLogo" className="textNearLogo" src={logoText} />}
        </div>
        <div className="navBarLink_list">
          <Link
            className="navBarLink"
            to="/"
            title="Projects Map"
            data-active={match.url === '/'}
          >
            <MapIcon />
            {longBar && <span>Projects Map</span>}
          </Link>
          {isPermission(user.role, adminPanelPerm.view)
            ? (
              <Link
                className="navBarLink"
                to="/admin/company"
                title="Admin Panel"
                data-active={match.url === '/admin'}
              >
                <AdminPanelIcon />
                {longBar && <span>Admin Panel</span>}
              </Link>
            ) : <div />}
          {isPermission(user.role, adminPanelPerm.viewDashboard)
            ? (
              <Link
                className="navBarLink"
                to="/dashboard"
                title="Reports"
                data-active={match.url === '/dashboard'}
              >
                <ChartsIcon />
                {longBar && <span>Reports</span>}
              </Link>
            ) : <div />}
          {isPermission(user.role, projectPerm.add) ? (
            <div className="addProject">
              <div
                className="navBarLink"
                onClick={() => {
                  if (activeMode === 'create') return;
                  history.push('/');
                  toggleModalCreateProject();
                }}
              >
                <AddIcon />
                {longBar && <span>New Project</span>}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="blockInNavBar">
        <div
          className={`navBarLink ${activeBtn ? 'activeBtn' : ''} ${haveNotifications}`}
          onClick={handleToggleModal}
        >
          <NotificationsIcon />
          {longBar && <span>Notifications</span>}
        </div>
        <div id="notificationModal" className="notificationModal">
          <div className="notificationWrap">
            <div className="notificationIconTitle">
              <div className="notificationBellTitle">
                <BellIcon className="notificationIcon" />
                <div className="notificationTitle">Notification</div>
              </div>
              <CloseIcon
                className="closeIconBtn"
                onClick={handleToggleModal}
              />
            </div>
          </div>
          <UserNotifications className="UserNotificationsInModal" />
        </div>
        <div className="setting__wr">
          <div
            className={`navBarLink ${showUserSetting ? 'active' : ''}`}
            onClick={toggleUserSetting}
          >
            <SettingIcon />
            {longBar && <span>Settings</span>}
          </div>
        </div>
        <div className="navBarLink no-hover">
          <div className="avatarButton">
            {user?.avatar ? (
              <img alt={`${user.name || ''} ${user.surName || ''}`} className={longBar ? 'longAvatar' : 'avatar'} src={`${url}/${user.avatar}`} />
            ) : (
              <span>{user?.name ? user?.name[0].toUpperCase() : ''}</span>
            )}
          </div>
          {longBar && <span>{`${user.name || ''} ${user.surName || ''}`}<div>{user.role}</div></span>}
        </div>
        <div
          className="navBarLink exit"
          onClick={logout}
        >
          <ExitIcon />
          {longBar && <span>Sign Out</span>}
        </div>
      </div>
    </div>
  );
};

NavBar.propTypes = {
  longBar: PropTypes.bool.isRequired,
  toggleLong: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any),
};

export default NavBar;
