import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { ReactComponent as DotsIcon } from 'assets/_v2.0/project/dotsIcon.svg';
import { ReactComponent as EditIcon } from 'assets/_v2.0/project/editIcon.svg';
import { ReactComponent as AddUsersIcon } from 'assets/_v2.0/project/addUsersIcon.svg';
import { ReactComponent as ShareIcon } from 'assets/_v2.0/project/shareIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/_v2.0/project/deleteIcon.svg';
// TODO old svg
import { ReactComponent as LinkIcon } from 'assets/project/linkIcon.svg';
import { ReactComponent as MailIcon } from 'assets/project/mailIcon.svg';

import useAuth from 'hooks/useAuth';
import useApp from 'hooks/useApp';

import { url } from 'config';
import { useSnackbar } from 'notistack';
import Portal from '@material-ui/core/Portal';
import ModalWrapper from 'uiComponents/ModalWrapper';
import Switch from 'uiComponents/Switch';
import Select from 'uiComponents/Select';
import { validateEmail } from 'views/admin/helpers/validation';
import ProjectSetting from '../../views/project/components/ProjectSetting';

const ProjectActions = ({ project, actionDelete }) => {
  const { user, updateUser } = useAuth();
  const { usersAPI, projectAPI } = useApp();
  const { enqueueSnackbar } = useSnackbar();
  const shareToEmail = useRef(null);
  const inviteUser = useRef(null);
  const [modal, toggleModal] = useState('');
  const [role, setRole] = useState('');

  const handleToggleModal = (e) => {
    e.target.parentNode.classList.toggle('active');
    document.getElementById(project._id).classList.toggle('active');
  };

  const handleChangeFollow = async (e) => {
    e.target.disabled = true;

    const newUser = { ...user };
    if (newUser.follow.includes(project._id)) {
      newUser.follow = newUser.follow.filter((id) => id !== project._id);
    } else {
      newUser.follow = [...newUser.follow, project._id];
    }

    const updatedUser = await usersAPI.edit(newUser);
    updateUser(updatedUser);
    e.target.disabled = false;
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${url}/view/${project._id}`).then(() => {
      enqueueSnackbar('Copy Success!', { variant: 'success' });
    });
  };

  const handleSendToEmail = (e) => {
    const email = shareToEmail.current.value;
    if (validateEmail(email)) {
      shareToEmail.current.style.borderColor = '#6369A7';
      e.target.disabled = true;
      projectAPI.shareToEmail(project._id, email)
        .then(() => {
          e.target.disabled = false;
          shareToEmail.current.value = '';
        });
    } else {
      shareToEmail.current.style.borderColor = 'red';
    }
  };

  const handleSendInvite = (e) => {
    const email = inviteUser.current.value;
    if (validateEmail(email)) {
      inviteUser.current.style.borderColor = '#6369A7';
      e.target.disabled = true;
      projectAPI.invite({
        role,
        email,
        project: project._id,
      })
        .then(() => {
          e.target.disabled = false;
          inviteUser.current.value = '';
        });
    } else {
      inviteUser.current.style.borderColor = 'red';
    }
  };

  const handleShowSetting = () => {
    ReactDOM.render(
      <ProjectSetting
        project={project}
        projectAPI={projectAPI}
        user={user}
      />,
      document.getElementById('offCanvas-portal'),
    );
  };

  return (
    <>
      <div className="projectItem__actions">
        <div className="projectItem__actions-toggle" onClick={handleToggleModal}><DotsIcon /></div>
        <div className="projectItem__actions-modal">
          <div className="projectItem__actions-item">
            <span>Notifications</span>
            <Switch
              checked={user.follow.includes(project._id)}
              onChange={handleChangeFollow}
              name="pointLine"
            />
          </div>
          <div
            className="projectItem__actions-item"
            onClick={handleShowSetting}
          >
            <EditIcon /> Project details
          </div>
          <div
            className="projectItem__actions-item"
            onClick={() => toggleModal('addUser')}
          >
            <AddUsersIcon />Invite users
          </div>
          <div
            className="projectItem__actions-item"
            onClick={() => toggleModal('share')}
          >
            <ShareIcon /> Share project
          </div>
          <div
            className="projectItem__actions-item"
            onClick={() => actionDelete(project)}
          >
            <DeleteIcon /> Delete project
          </div>
        </div>
      </div>
      {modal === 'share' ? (
        <Portal container={document && document.getElementById('modal-portal')}>
          <div className="projectItem__modal">
            <ModalWrapper toggleShow={() => toggleModal('')} title="Share project">
              <div className="projectItem__actions-item__modal">

                <div className="projectItem__actions-item__modal-content">

                  <div className="projectItem__actions-item__modal-content__box">
                    <span>Link:</span>
                    <input type="text" disabled value={`${url}/view/${project._id}`} />
                  </div>

                  <div className="projectItem__actions-item__modal-content__box">
                    <span>Email:</span>
                    <input
                      type="email"
                      placeholder="Enter user email"
                      ref={shareToEmail}
                    />
                    <div>
                      <button
                        type="button"
                        onClick={handleSendToEmail}
                      >
                        Send Link <MailIcon />
                      </button>
                      <button type="button" onClick={handleCopyLink}>
                        Copy Link <LinkIcon />
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </ModalWrapper>
          </div>
        </Portal>
      ) : null}
      {modal === 'addUser' ? (
        <Portal container={document && document.getElementById('modal-portal')}>
          <div className="projectItem__modal">
            <ModalWrapper toggleShow={() => toggleModal('')} title="Invite to project">
              <div className="projectItem__actions-item__modal">

                <div className="projectItem__actions-item__modal-content">

                  <div className="projectItem__actions-item__modal-content__box">
                    <span>Invite As</span>
                    <Select
                      name="user-role"
                      options={[
                        { value: 'Field Staff', label: 'Field Staff' },
                        { value: 'Engineer', label: 'Engineer' },
                        { value: 'Project Manager', label: 'Project Manager' },
                      ]}
                      onChange={(value) => setRole(value)}
                      placeholder="Select Role"
                      selected={role}
                    />
                  </div>

                  <div className="projectItem__actions-item__modal-content__box">
                    <span>Email:</span>
                    <input
                      type="email"
                      placeholder="Enter user email"
                      ref={inviteUser}
                      disabled={!role}
                    />
                    <button
                      type="button"
                      onClick={handleSendInvite}
                    >
                      Send invite <MailIcon />
                    </button>
                  </div>

                </div>
              </div>
            </ModalWrapper>
          </div>
        </Portal>
      ) : null}
    </>
  );
};

ProjectActions.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  actionDelete: PropTypes.func,
};

export default ProjectActions;
