import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconGisFile } from 'assets/creatingProject/iconGisFile.svg';
// import { ReactComponent as IconMap } from 'assets/creatingProject/iconMap.svg';
import { ReactComponent as IconDelete } from 'assets/creatingProject/iconDelete.svg';
import { ReactComponent as CurveMap } from 'assets/creatingProject/curveMap.svg';

import './uploadedGisFile.scss';
import convertSize from 'utils/convertSize';

const UploadedGisFile = ({
  // eslint-disable-next-line react/prop-types
  item, onClick, toggleShowMap, i,
}) => {
  const [hoverEffect, setHoverEffect] = useState(false);
  const handleMouseEnter = () => {
    setHoverEffect(true);
  };
  const handleMouseLeave = () => {
    setHoverEffect(false);
  };
  return (
    <div className="UploadedFile">
      <div
        className="UploadedFile-wrapper"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={hoverEffect ? 'UploadedFile-wrapper__iconFile active' : 'UploadedFile-wrapper__iconFile disable'}>
          <IconGisFile className={hoverEffect && 'active'} />
        </div>
        <div className="UploadedFile-wrapper__title">
          {/* eslint-disable-next-line react/prop-types */}
          <div className="UploadedFile-wrapper__title-name">{i.name}</div>
          {/* eslint-disable-next-line react/prop-types */}
          <div className="UploadedFile-wrapper__title-fileSize">{convertSize(i.size)}</div>
        </div>
        <div className="UploadedFile-wrapper__iconMap" onClick={() => toggleShowMap(true)}>
          <CurveMap />
        </div>
        <div className="UploadedFile-wrapper__iconDelete" onClick={onClick(item)}>
          <IconDelete />
        </div>
      </div>
    </div>
  );
};

UploadedGisFile.propTypes = {
  item: PropTypes.string,
  onClick: PropTypes.func,
  toggleShowMap: PropTypes.bool,
};

export default UploadedGisFile;
