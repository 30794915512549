import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './newMarker.scss';

import { ReactComponent as Compass } from 'assets/creatingProject/coms.svg';
import { ReactComponent as Close } from 'assets/creatingProject/x.svg';
import { ReactComponent as TargetIcon } from 'assets/creatingProject/targetIcon.svg';
import { ReactComponent as CopyIcon } from 'assets/creatingProject/copyIcon.svg';

// eslint-disable-next-line no-unused-vars
const NewMarker = ({ lng, lat }) => {
  const [open, setOpen] = useState(true);

  const handleCopy = () => {
    const copyText = document.getElementById('mail');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
  };

  if (!lng && !lat) return null;

  return (
    <div className="marker">
      <div
        className="targetMarker"
        onClick={() => {
          setOpen(true);
        }}
      >
        <TargetIcon />
      </div>
      {open
        && (
        <div className="markerInfo">
          <div className="constituents">
            <div className="geoTitle">
              <div className="geoTitleWrap">
                <Compass className="compass" />
                <div className="GeotagsTitle">
                  Geotags:
                </div>
              </div>
              <div className="close-wrapper" onClick={() => setOpen(false)}>
                <Close className="close" />
              </div>
            </div>
            <div className="geoData">
              <div className="coords">
                <input
                  readOnly
                  value={lat.toFixed(6)}
                  id="mail"
                  className="lat"
                />
                <input
                  readOnly
                  value={lng.toFixed(6)}
                  id="mail2"
                  className="lng"
                />
              </div>
              <CopyIcon
                onClick={handleCopy}
                className="copyIcon"
              />
            </div>
          </div>
        </div>
        )}
    </div>
  );
};

NewMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export default NewMarker;
