import React from 'react';
import PropTypes from 'prop-types';
import { url } from 'config';

const Avatar = ({ info }) => {
  return (
    <div className="avatar">
      {info.user?.avatar ? (
        <img src={`${url}/${info.user?.avatar}`} alt={info.user.name} />
      ) : (
        <span>{info.user?.name ? info.user?.name[0].toUpperCase() : '?'}</span>
      )}
    </div>
  );
};

Avatar.propTypes = {
  info: PropTypes.objectOf(PropTypes.any),
};

export default Avatar;
