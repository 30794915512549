import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import LoadingScreen from 'components/LoadingScreen';
import { url } from 'config';
import axios from 'utils/axios';
import * as Sentry from '@sentry/react';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {
    role: 'Guest',
  },
  appData: {
    users: [],
    teams: [],
  },
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('AADC_accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('AADC_accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATEUSER': {
      return {
        ...state,
        user: action.payload,
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        isInitialized: true,
        ...action.payload,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'DONEINIT': {
      return {
        ...state,
        isInitialized: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => {},
  updateUser: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('AADC_accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`${url}/api/auth/initialization`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const {
            user, users, teams, projects, companies,
          } = response.data;

          dispatch({
            type: 'LOGIN',
            payload: {
              user,
              appData: {
                users,
                teams,
                projects,
                companies,
              },
            },
          });
          Sentry.setContext('authUser', user);
        } else {
          dispatch({ type: 'DONEINIT' });
          Sentry.setContext('authUser', {
            name: 'Guest',
          });
        }
      } catch (err) {
        console.log('error', err);
        setSession(null);
        dispatch({ type: 'LOGOUT' });
      }
    };
    initialize();
  }, []);

  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  const login = async (email, password, keep) => {
    const response = await axios.post(`${url}/api/auth/login`, {
      email: email.toLowerCase(),
      password,
      keep,
    });
    const {
      accessToken,
      user,
      users,
      teams,
      projects,
      companies,
    } = response.data;
    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        appData: {
          users,
          teams,
          projects,
          companies,
        },
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const updateUser = (user) => {
    dispatch({
      type: 'UPDATEUSER',
      payload: user,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
