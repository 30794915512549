import React from 'react';
import PropTypes from 'prop-types';
import leftImg from 'assets/backgroundLeft.svg';
import rightImg from 'assets/backgroundRight.svg';
import './style.scss';

const AuthLayout = ({ children }) => {
  return (
    <div className="authorization-layout">
      <img className="leftImg" src={leftImg} alt="leftImg" />
      <img className="rightImg" src={rightImg} alt="rightImg" />
      {/*<div className="authorization-layout-image" />*/}
      <div className="authorization-layout-form">
        {children}
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
