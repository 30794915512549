import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './savedProjectModal.scss';
import { formatDistance } from 'date-fns';

import { ReactComponent as CloseIcon } from 'assets/navBar/close.svg';

import ModalWrapper from 'uiComponents/ModalWrapper';
import { abortUpload } from 'utils/fileUploader';

const SavedProjectModal = ({ close, loadRef, setSuccessfully }) => {
  const handleCloseModal = () => {
    abortUpload();
    close(false);
  };
  const [date, setDate] = useState(null);
  const [dateTwo, setDateTwo] = useState(null);
  useEffect(() => {
    setDate(new Date());
  }, []);
  const timer = setInterval(() => { setDateTwo(new Date()); }, 1000);

  if (setSuccessfully === true) {
    clearInterval(timer);
  }

  return (
    <div className="savedProjectModalWrapper">
      <ModalWrapper
        toggleShow={handleCloseModal}
        title="Uploading files"
      >
        <div className="uploadingDone">
          <div className="uploadingDone__LoadBar">
            <div ref={loadRef} className="uploadingDone__LoadBar-progressBar" />
            <div className="uploadingDone__LoadBar-uploadText">
              <div className="uploadingDone__LoadBar-uploadText-text">Uploading...</div>
              <div
                className="uploadingDone__LoadBar-uploadText-closeButton"
                onClick={handleCloseModal}
              >
                Close
                <CloseIcon className="closeIcon" />
              </div>
            </div>
          </div>
          <div className="uploadingDone__UploadedAllFiles">
            <div className="uploadingDone__UploadedAllFiles-upload">
              <div className="title">Time passed:</div>
              <div className="subtitle">{date && dateTwo && formatDistance(date, dateTwo, { includeSeconds: true })}</div>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

SavedProjectModal.propTypes = {
  close: PropTypes.func,
  loadRef: PropTypes.objectOf(PropTypes.any),
  setSuccessfully: PropTypes.func,
};

export default SavedProjectModal;
