import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const GeoAutocomplete = ({
  value, onChange, placeholder, className,
}) => {
  const handleChangeGeo = (address) => {
    onChange({
      address,
      lat: null,
      lng: null,
    });
  };

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        onChange({
          address,
          lat: latLng.lat,
          lng: latLng.lng,
        });
      })
      .catch((error) => console.error('Error', error));
  };

  if (!window.google) return null;

  return (
    <PlacesAutocomplete
      onChange={handleChangeGeo}
      onSelect={handleSelect}
      onError={(err) => {
        console.log(err);
      }}
      value={value || ''}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
      }) => (
        <div className={`autocomplete__wr ${suggestions.length > 0 ? 'active' : ''}`}>
          <input
            {...getInputProps({ placeholder, className })}
          />
          <div className="autocomplete-dropdown-container">
            {suggestions.map((suggestion) => (
              <div key={suggestion.index} data-active={suggestion.active} className="autocomplete-dropdown-container-item" {...getSuggestionItemProps(suggestion)}>
                <span>
                  {suggestion.description}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

GeoAutocomplete.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default GeoAutocomplete;
