import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as SearchBtn } from 'assets/creatingProject/searchBtn.svg';
// import { ReactComponent as TickIcon } from 'assets/creatingProject/tickIcon.svg';
import { ReactComponent as SatelliteIcon } from 'assets/_v2.0/headerIcons/satellite.svg';
import { ReactComponent as RoadmapIcon } from 'assets/_v2.0/headerIcons/roadmap.svg';
import { ReactComponent as HybridIcon } from 'assets/_v2.0/headerIcons/hybrid.svg';
import { ReactComponent as Plus } from 'assets/creatingProject/plus.svg';

import Map from 'views/home/GoogleMap';
import useApp from 'hooks/useApp';
import GeoAutocomplete from 'uiComponents/GeoAutocomplete/GeoAutocomplete';

const MapTools = ({ newProject, toggleShowMap }) => {
  const {
    mapType,
    setMapType,
  } = useApp();

  const mapView = useRef();
  const types = {
    satellite: (
      <span className="mapView_options-item__txt">
        <SatelliteIcon />
        <span>Satellite</span>
      </span>
    ),
    roadmap: (
      <span className="mapView_options-item__txt">
        <RoadmapIcon />
        <span>Roadmap</span>
      </span>
    ),
    hybrid: (
      <span className="mapView_options-item__txt">
        <HybridIcon />
        <span>Hybrid</span>
      </span>
    ),
  };

  const handleClick = () => {
    if (mapView && mapView.current) {
      mapView.current.classList.toggle('active');
    }
  };

  const handleChange = (e) => {
    setMapType(e.target.value);
    handleClick();
  };

  return (
    <div className="projectCreate__map">
      <div className="projectCreate__map-head">
        <div className="projectCreate__map-head-tools">
          <div className="projectCreate__map-head-tools__search">
            <GeoAutocomplete
              value={newProject.get.location?.address || ''}
              placeholder="Search location..."
              className="searchField"
              onChange={({ address, lat, lng }) => newProject.set((prevState) => ({
                ...prevState,
                location: {
                  address,
                  coords: { lat, lng },
                },
              }))}
            />
            <SearchBtn />
          </div>
          <div className="tools">
            <div className="toolsText">Tools:</div>
            <div className="verticalLine" />
            <div className="mapView" ref={mapView}>
              <div className="mapView_selected" onClick={handleClick}>{types[mapType]}</div>
              <div className="mapView_options">
                {Object.keys(types).map((key) => (
                  <label className="mapView_options-item" key={key}>
                    <input
                      type="radio"
                      name="mapType"
                      hidden
                      value={key}
                      defaultChecked={mapType === key}
                      onChange={handleChange}
                    />
                    {types[key]}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Map
        createProject={{
          get: newProject.get.location.coords,
          set: (geo) => {
            newProject.set({
              ...newProject.get,
              location: {
                coords: geo,
              },
            });
          },
        }}
      />
      <div className="confirmButtonWrap">
        <button
          type="button"
          className="cancelBtn"
          onClick={() => toggleShowMap(false)}
        >
          Cancel
          <Plus />
        </button>
        <button
          type="button"
          className="confirmBtn"
          onClick={() => toggleShowMap(false)}
          disabled={!newProject.get.location.coords}
        >
          Confirm
          <Plus />
        </button>
      </div>
    </div>
  );
};

MapTools.propTypes = {
  newProject: PropTypes.objectOf(PropTypes.any),
  toggleShowMap: PropTypes.func,
};

export default MapTools;
