import React from 'react';
import PropTypes from 'prop-types';
import './successfullySavedModal.scss';

import { ReactComponent as CheckMark } from 'assets/creatingProject/checkMarkIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/creatingProject/x.svg';

import ModalWrapper from 'uiComponents/ModalWrapper';
import useApp from '../../../../hooks/useApp';

const SuccessfullySavedModal = ({ close, newProject }) => {
  const {
    // eslint-disable-next-line no-unused-vars
    toggleModalCreateProject,
  } = useApp();
  const handleCloseModal = () => {
    close();
    toggleModalCreateProject();
  };
  const allFiles = newProject.get.droneImages.length + newProject.get.phoneImages.length + newProject.get.gisFiles.length;
  return (
    <div className="successfullySavedModal">
      <ModalWrapper
        toggleShow={handleCloseModal}
        title="Save complete"
      >
        <div className="successfullySaved">
          <div className="successfullySaved__LoadBar">
            <div className="successfullySaved__LoadBar-progressBar">
              <CheckMark />
            </div>
            <div className="successfullySaved__LoadBar-uploadText">
              <div className="successfullySaved__LoadBar-uploadText-text"> your project has been successfully saved</div>
              <div
                className="successfullySaved__LoadBar-uploadText-closeButton"
                onClick={handleCloseModal}
              >
                Close
                <CloseIcon className="closeIcon" />
              </div>
            </div>
          </div>
          <div className="successfullySaved__UploadedAllFiles">
            <div className="successfullySaved__UploadedAllFiles-upload margin">
              <div className="title">Uploaded: </div>
              <div className="subtitle">{allFiles} files</div>
            </div>
            {/*<div className="successfullySaved__UploadedAllFiles-upload">*/}
            {/*  <div className="title">Remaining</div>*/}
            {/*  <div className="subtitle">time</div>*/}
            {/*</div>*/}
          </div>
        </div>
      </ModalWrapper>
    </div>

  );
};

SuccessfullySavedModal.propTypes = {
  close: PropTypes.func,
  newProject: PropTypes.objectOf(PropTypes.any),
};

export default SuccessfullySavedModal;
