import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import Switch from 'uiComponents/Switch';

const Notifications = ({ user, changeUser, projects }) => {
  const [state, setState] = useState(user.follow || []);
  const [active, setActive] = useState(user.follow.length > 0);

  const handleChange = (e) => {
    let newArray = [...state, e.target.name];
    if (state.includes(e.target.name)) {
      newArray = newArray.filter((id) => id !== e.target.name);
    }
    setState(newArray);
    changeUser({ ...user, follow: newArray });
  };

  console.log(state);

  return (
    <div className="Notifications">
      <div className="Notifications__all">
        <Switch
          checked={active}
          onChange={(e) => {
            console.log(e.target.checked);
            setActive(e.target.checked);
            if (!e.target.checked) {
              setState([]);
              changeUser({ ...user, follow: [] });
            }
          }}
          black
        />
      </div>
      {projects.length ? (
        <div className="Notifications__list-projects">

          {!active ? (
            <div className="Notifications__list-projects-disable" />
          ) : null}

          {projects.map((project) => (
            <div className="Notifications__list-projects__item" key={project._id}>
              <Switch
                checked={state.includes(project._id)}
                onChange={handleChange}
                name={project._id}
                black
              />
              <span>{project.title}</span>
            </div>
          ))}

        </div>
      ) : null}
    </div>
  );
};

Notifications.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  changeUser: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.any),
};

export default Notifications;
