import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const FormGroup = ({ children }) => <div className="formGroup">{children}</div>;

FormGroup.propTypes = {
  children: PropTypes.node,
};

export default FormGroup;
