import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { adminPanelPerm, isPermission } from './permissions';

const AdminGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }
  if (!isPermission(user.role, adminPanelPerm.view)) {
    return <Redirect to="/" />;
  }
  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AdminGuard;
