import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import MaterialCollapse from '@material-ui/core/Collapse';

const Collapse = ({
  title, children, className,
}) => {
  const [open, toggleOpen] = useState(true);

  return (
    <div className={`projectsList__collapse ${className || ''}`}>
      <div
        className={open ? 'projectsList__collapse-title' : 'projectsList__collapse-title close'}
        onClick={() => toggleOpen(!open)}
      >
        <div className="title-collapse">{title}</div>
      </div>
      <MaterialCollapse in={open} timeout="auto" unmountOnExit>
        {children}
      </MaterialCollapse>
    </div>
  );
};

Collapse.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Collapse;
