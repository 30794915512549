import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/newLogoImg.svg';

import './loadingScreen.scss';

const LoadingScreen = ({ loadRef }) => {
  return (
    <div className="loadingScreen">
      <div className="loading">
        <img alt="imgLoading" className="imgLoading" src={logo} />
        loading...
        <div ref={loadRef} />
        <div className="spinner" />
      </div>
    </div>
  );
};

LoadingScreen.propTypes = {
  loadRef: PropTypes.objectOf(PropTypes.any),
};

export default LoadingScreen;
