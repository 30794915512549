import React, { useState } from 'react';
import './style.scss';

import { ReactComponent as CloseIcon } from 'assets/_v2.0/tools/closeIcon.svg';
import { ReactComponent as AddFileIcon } from 'assets/creatingProject/addFileIcon.svg';

import Portal from '@material-ui/core/Portal';
import Steps from './Steps';
import MapTools from './Components/MapTools';
import ConfirmDeleteModal from './Modal/ConfirmDeleteModal';

const initProject = {
  title: '',
  location: {
    coords: null,
    address: '',
  },
  gisFiles: [],
  droneImages: [],
  phoneImages: [],
  teamMembers: [],
  company: null,
  type: null,
};

const projectCreate = () => {
  const [newProject, setNewProject] = useState(initProject);
  const [showMap, toggleShowMap] = useState(false);
  const [confirmDeleteProject, setConfirmDeleteProject] = useState(false);

  const handleClose = () => {
    setConfirmDeleteProject(true);
  };

  return (
    <div className="projectCreate">
      {confirmDeleteProject ? (
        <Portal container={document && document.getElementById('confirmDeleteModal')}>
          <ConfirmDeleteModal setNewProject={setNewProject} close={setConfirmDeleteProject} />
        </Portal>
      ) : null}
      <div className="projectCreate__bg" onClick={handleClose} />
      <div className="projectCreate__content">
        <div className="projectCreate__content-head">
          <div className="projectCreate__content-head-wrapper">
            <AddFileIcon className="addFileIcon" />
            <div className="projectCreate__content-head-wrapper-title">Create project</div>
          </div>
          <div className="projectCreate__content-head-close" onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        <Steps
          newProject={{
            get: newProject,
            set: setNewProject,
          }}
          toggleShowMap={toggleShowMap}
          setConfirmDeleteProject={setConfirmDeleteProject}
        />
      </div>
      {showMap ? (
        <MapTools
          newProject={{
            get: newProject,
            set: setNewProject,
          }}
          toggleShowMap={toggleShowMap}
        />
      ) : null}
    </div>
  );
};

export default projectCreate;
