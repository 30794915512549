const convertSize = (size) => {
  const kiloBytes = 1024;
  const megaBytes = 1024 * 1024;
  const gigaBytes = 1024 * 1024 * 1024;

  if (size < kiloBytes) return `${size} B`;
  if (size < megaBytes) return `${(size / kiloBytes).toFixed(0)} KB`;
  if (size < gigaBytes) return `${(size / megaBytes).toFixed(2)} MB`;
  return `${size} B`;
};

export default convertSize;
