import React from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import PropTypes from 'prop-types';

import { ReactComponent as ResetPasswordIcon } from 'assets/_v2.0/admin/resetPasswordIcon.svg';
import { ReactComponent as UploadIcon } from 'assets/uploadIcon.svg';

import { url } from 'config';
import { toBase64 } from 'views/admin/components/AdminOffCanvas/helper';
import { useSnackbar } from 'notistack';
import FormGroup from 'views/admin/uiComponetns/FormGroup';
import ResetPasswordConfirm from 'uiComponents/ResetPasswordConfirm';
import useApp from 'hooks/useApp';

const UserInfoForm = ({ user, changeUser, usersAPI }) => {
  const { enqueueSnackbar } = useSnackbar();
  const company = useApp();
  const companyName = company.companiesAPI.getById(user.company);

  const handleInputChange = (e) => {
    changeUser((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  const handleResetPassword = () => {
    ReactDOM.render((
      <ResetPasswordConfirm
        user={user}
        usersAPI={usersAPI}
        toggleShow={() => ReactDOM.unmountComponentAtNode(document.getElementById('modal-portal'))}
      />
    ), document.getElementById('modal-portal'));
  };

  const handleUploadAvatar = async (e) => {
    const imgFile = e.target.files[0];
    const imgPreview = await toBase64(imgFile);

    if (
      imgFile.type === 'image/jpeg'
      || imgFile.type === 'image/png'
      || imgFile.type === 'image/jpg'
    ) {
      changeUser((prevState) => ({
        ...prevState,
        avatar: {
          file: imgFile,
          preview: imgPreview,
        },
      }));
    } else {
      enqueueSnackbar('Upload only jpeg/png/jpg type avatar', { variant: 'error' });
    }
  };

  const avatarImg = () => {
    if (typeof user.avatar === 'string' || user.avatar instanceof String) {
      return <img src={`${url}/${user.avatar}`} alt={`${user.name} ${user.surName}`} />;
    }
    if (user.avatar?.preview) {
      return <img src={user.avatar.preview} alt={`${user.name} ${user.surName}`} />;
    }

    return null;
  };

  return (
    <div className="UserInfoForm">
      <div className="UserInfoForm__avatar">
        <div className="UserInfoForm__avatar-wr">
          {avatarImg()}
        </div>
        <label className="UserInfoForm__avatar-upload">
          <input type="file" onChange={handleUploadAvatar} accept=".jpeg,.jpg,.JPG,.png" />
          <UploadIcon />
        </label>
      </div>
      <div className="UserInfoForm__form-fields">

        <FormGroup>
          <div className="label">First Name:</div>
          <input
            name="name"
            type="text"
            placeholder="Enter name"
            value={user.name || ''}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <div className="label">Last name:</div>
          <input
            name="surName"
            type="text"
            placeholder="Enter Second Name"
            value={user.surName || ''}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <div className="label">Email:</div>
          <input
            name="email"
            type="email"
            placeholder="Enter email"
            value={user.email || ''}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <div className="label">Phone number:</div>
          <input
            name="phone"
            type="phone"
            placeholder="Enter phone"
            value={user.phone || ''}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup>
          <div className="label">Company:</div>
          <input type="text" value={user.role === 'Super Admin' ? 'N/A' : companyName.name} readOnly disabled />
        </FormGroup>

        <FormGroup>
          <div className="label">Role:</div>
          <input type="text" value={user.role} readOnly disabled />
        </FormGroup>

        <button
          type="button"
          className="UserInfoForm__reset-password"
          onClick={handleResetPassword}
        >
          Reset password <ResetPasswordIcon />
        </button>

      </div>
    </div>
  );
};

UserInfoForm.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  usersAPI: PropTypes.objectOf(PropTypes.any).isRequired,
  changeUser: PropTypes.func.isRequired,
};

export default UserInfoForm;
