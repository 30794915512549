import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as IconT } from 'assets/creatingProject/iconT.svg';
import { ReactComponent as Compass } from 'assets/creatingProject/coms.svg';
import { ReactComponent as PenIcon } from 'assets/creatingProject/penIcon.svg';
import { ReactComponent as GisFilesIcon } from 'assets/creatingProject/gisFilesIcon.svg';
import { ReactComponent as PhoneIcon } from 'assets/creatingProject/phoneIcon.svg';
import { ReactComponent as DroneIcon } from 'assets/creatingProject/droneIcon.svg';
import { ReactComponent as DateCreate } from 'assets/creatingProject/dateCreate.svg';
import { ReactComponent as Solar } from 'assets/creatingProject/iconProjectType.svg';
import { ReactComponent as Transmissions } from 'assets/creatingProject/transmissions.svg';
import { ReactComponent as Widn } from 'assets/creatingProject/winTurbine.svg';
import { ReactComponent as SizeIcon } from 'assets/creatingProject/iconSize.svg';

import useApp from 'hooks/useApp';
import convertSize from 'utils/convertSize';
import Navigation from './Navigation';
import UploadFiles from '../Components/UploadFiles';
import UserComponent from '../Components/UserComponent';
import types from './_types';

const GeneralInfo = ({
  activeStep,
  setActiveStep,
  newProject,
  toggleShowMap,
  navigation,
}) => {
  const { users } = useApp();
  const [title, setTitle] = useState(newProject.get.title);
  const [activeInput, setActiveInput] = useState(false);
  const [choiceOfUploadType, setChoiceOfUploadType] = useState('');
  const [uploadModalShow, setUploadModalShow] = useState(false);
  const [hoverEffect, setHoverEffect] = useState(false);
  const [hoverEffect2, setHoverEffect2] = useState(false);
  const [hoverEffect3, setHoverEffect3] = useState(false);
  const [date, setDate] = useState('');

  const typeProject = {
    solar: {
      icon: <Solar />,
      label: 'Solar Panel',
    },
    wind: {
      icon: <Widn />,
      label: 'Wind',
    },
    transmission: {
      icon: <Transmissions />,
      label: 'Transmissions',
    },
  };

  const formattedDate = () => {
    const d = new Date();
    const dateNow = [d.getDate(), d.getMonth() + 1, d.getFullYear()]
      .map((n) => (n < 10 ? `0${n}` : `${n}`)).join('/');
    setDate(dateNow);
  };

  useEffect(() => {
    formattedDate();
  });

  const showUploadModal = (modalType) => {
    setChoiceOfUploadType(modalType);
    setUploadModalShow(true);
  };

  const handleChangeState = () => {
    setActiveInput(!activeInput);
  };

  const handleOnBlur = () => {
    newProject.set((prevState) => ({
      ...prevState,
      title,
    }));
    setActiveInput(false);
  };

  const getAllSize = (files) => {
    if (!files.length) return 0;
    return convertSize(files.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0));
  };

  const onMouseMove = (type) => {
    if (type === 'phone') {
      setHoverEffect(true);
    } else if (type === 'drone') {
      setHoverEffect2(true);
    } else if (type === 'gis') {
      setHoverEffect3(true);
    }
  };

  const onMouseLeave = (type) => {
    if (type === 'phone') {
      setHoverEffect(false);
    } else if (type === 'drone') {
      setHoverEffect2(false);
    } else if (type === 'gis') {
      setHoverEffect3(false);
    }
  };
  return (
    <>
      <div className="GeneralInfo">
        {uploadModalShow && (
          <UploadFiles
            choiceOfUploadType={choiceOfUploadType}
            setUploadModalShow={setUploadModalShow}
            setFiles={newProject.set}
            toggleShowMap={toggleShowMap}
            newProject={newProject.get}
          />
        )}
        <div className="GeneralInfo__ProjectName">
          {!activeInput
            ? (
              <div
                className="GeneralInfo__ProjectName-divName"
                style={{ width: (newProject.get.title.length + 10) * 9 }}
              >
                {title.length > 0 ? title : newProject.get.title}
              </div>
            )
            : (
              <input
                className="GeneralInfo__ProjectName-inputName"
                defaultValue={title.length > 0 ? title : newProject.get.title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={handleOnBlur}
                style={{ width: (newProject.get.title.length + 10) * 9 }}
              />
            )}
          <div
            className="GeneralInfo__ProjectName-Rename"
            onClick={handleChangeState}
          >
            Rename
            <IconT />
          </div>
        </div>
        <div className="GeneralInfo__Shell">
          <div className="GeneralInfo__Shell-Wrapper">
            <div className="GeneralInfo__Shell-Wrapper-InsideInfo">
              <div className="GeneralInfo__Shell-Wrapper-InsideInfo-GeneralInfoTitle">General Info</div>
              <div className="GeneralInfo__Shell-Wrapper-InsideInfo-Line" />
              <div className="GeneralInfo__Shell-Wrapper-InsideInfo-InformationProject">
                <div className="wrapperCoords margin">
                  <div className="title">Coordinates:</div>
                  <div className="wrap">
                    <Compass />
                    <div className="text">{newProject.get.location.coords ? `${newProject.get.location.coords.lat.toFixed(6)}, ${newProject.get.location.coords.lng.toFixed(6)}` : newProject.get.location.address}</div>
                    <PenIcon className="penIcon" onClick={() => toggleShowMap(true)} />
                  </div>
                </div>
                <div className="wrapperCoords margin">
                  <div className="title">Project Type:</div>
                  <div className="wrap">
                    {typeProject[newProject.get.type].icon}
                    <div className="text">{typeProject[newProject.get.type].label}</div>
                    <PenIcon className="penIcon" onClick={() => setActiveStep('properties')} />
                  </div>
                </div>
                <div className="wrapperCoords">
                  <div className="title">Create Date:</div>
                  <div className="wrap">
                    <DateCreate />
                    <div className="text">{date}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="GeneralInfo__Shell-Wrapper-ProjectFiles">
              <div className="GeneralInfo__Shell-Wrapper-ProjectFiles-wrap one">
                <div className="GeneralInfo__Shell-Wrapper-ProjectFiles-wrap-title">Project Files:</div>
                <div className="line" />
                <div className="fileStorage">
                  <div
                    className="Upload__uploadBlock-imagesStorage"
                    onClick={() => showUploadModal({
                      title: 'Phone Images',
                      type: 'phoneImages',
                      files: newProject.get.phoneImages,
                    })}
                    onMouseEnter={() => onMouseMove('phone')}
                    onMouseLeave={() => onMouseLeave('phone')}
                  >
                    <div className="Upload__uploadBlock-imagesStorage-typeFileIcon">
                      <PhoneIcon className={hoverEffect ? 'phoneIconActive' : 'phoneIcon'} />
                    </div>
                    <div className={hoverEffect ? 'Upload__uploadBlock-imagesStorage-title activeColor' : 'Upload__uploadBlock-imagesStorage-title disableColor'}>Phone Images</div>
                    <div className="Upload__uploadBlock-imagesStorage-information">
                      <div className={hoverEffect ? 'filesNumber activeColor' : 'filesNumber'}>{newProject.get.phoneImages.length} files</div>
                      <div className={hoverEffect ? 'sizeIconBlock activeColor' : 'sizeIconBlock'}>
                        <SizeIcon className={hoverEffect ? 'sizeIcon whiteIcon' : 'sizeIcon'} />
                        {getAllSize(newProject.get.phoneImages)}
                      </div>
                    </div>
                  </div>
                  <div
                    className="Upload__uploadBlock-imagesStorage margin"
                    onClick={() => showUploadModal({
                      title: 'Drone Images',
                      type: 'droneImages',
                      files: newProject.get.droneImages,
                    })}
                    onMouseEnter={() => onMouseMove('drone')}
                    onMouseLeave={() => onMouseLeave('drone')}
                  >
                    <div className="Upload__uploadBlock-imagesStorage-typeFileIcon">
                      <DroneIcon className={hoverEffect2 ? 'phoneIconActive' : 'phoneIcon'} />
                    </div>
                    <div className={hoverEffect2 ? 'Upload__uploadBlock-imagesStorage-title activeColor' : 'Upload__uploadBlock-imagesStorage-title disableColor'}>Drone Images</div>
                    <div className="Upload__uploadBlock-imagesStorage-information">
                      <div className={hoverEffect2 ? 'filesNumber activeColor' : 'filesNumber'}>{newProject.get.droneImages.length} files</div>
                      <div className={hoverEffect2 ? 'sizeIconBlock activeColor' : 'sizeIconBlock'}>
                        <SizeIcon className={hoverEffect2 ? 'sizeIcon whiteIcon' : 'sizeIcon'} />
                        {getAllSize(newProject.get.droneImages)}
                      </div>
                    </div>
                  </div>
                  <div
                    className="Upload__uploadBlock-imagesStorage"
                    onClick={() => showUploadModal({
                      title: 'GIS Line Files',
                      type: 'gisFiles',
                      files: newProject.get.gisFiles,
                    })}
                    onMouseEnter={() => onMouseMove('gis')}
                    onMouseLeave={() => onMouseLeave('gis')}
                  >
                    <div className="Upload__uploadBlock-imagesStorage-typeFileIcon">
                      <GisFilesIcon className={hoverEffect3 ? 'phoneIconActive' : 'phoneIcon'} />
                    </div>
                    <div className={hoverEffect3 ? 'Upload__uploadBlock-imagesStorage-title activeColor' : 'Upload__uploadBlock-imagesStorage-title disableColor'}>GIS Line Files</div>
                    <div className="Upload__uploadBlock-imagesStorage-information">
                      <div className={hoverEffect3 ? 'filesNumber activeColor' : 'filesNumber'}>{newProject.get.gisFiles.length} files</div>
                      <div className={hoverEffect3 ? 'sizeIconBlock activeColor' : 'sizeIconBlock'}>
                        <SizeIcon className={hoverEffect3 ? 'sizeIcon whiteIcon' : 'sizeIcon'} />
                        {getAllSize(newProject.get.gisFiles)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="GeneralInfo__Shell-InvitedUsers">
            <div className="GeneralInfo__Shell-InvitedUsers-title">Invited Users:</div>
            <div className="GeneralInfo__Shell-InvitedUsers-line" />
            <div className="GeneralInfo__Shell-InvitedUsers-wrap scroll-wrapper">
              {users.filter((item) => newProject.get.teamMembers.includes(item._id)).map((user) => (
                <UserComponent key={user._id} user={user} newProject={newProject} />
              ))}
            </div>
          </div>
        </div>
      </div>
      {!uploadModalShow && !navigation ? (
        <Navigation
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          newProject={newProject}
          next="условие перехода"
          handlerError={() => console.log('функция если переход не доступен')}
          // create={createProject}
        />
      ) : <div />}
      { !uploadModalShow && navigation
        ? navigation
        : <div /> }
    </>
  );
};

GeneralInfo.propTypes = {
  ...types,
  newProject: PropTypes.objectOf(PropTypes.any),
};

export default GeneralInfo;
