export const url = window.location.origin;

export const awsConf = {
  REGION: process.env.REACT_APP_REGION || 'us-east-2',
  S3_BUCKET: process.env.REACT_APP_S3_BUCKET || 'ohl-platform',
  accessKeyId: process.env.REACT_APP_accessKeyId || 'AKIAIR7FK3RVHVL7PQPQ',
  secretAccessKey: process.env.REACT_APP_secretAccessKey || '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',
  url: `https://${process.env.REACT_APP_S3_BUCKET || 'ohl-platform'}.s3.${process.env.REACT_APP_REGION || 'us-east-2'}.amazonaws.com`,
};

export const ApiKey = process.env.REACT_APP_ || 'AIzaSyCT3NJ_r9ZhQGRipApE0I33ad_yZCFIR2I';
