import AWS from 'aws-sdk';
import { awsConf } from 'config';

AWS.config.update({
  accessKeyId: awsConf.accessKeyId,
  secretAccessKey: awsConf.secretAccessKey,
});
const myBucket = new AWS.S3({
  params: { Bucket: awsConf.S3_BUCKET },
  region: awsConf.REGION,
});

const csvUploader = (file, filePath) => {
  return new Promise((res) => {
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: awsConf.S3_BUCKET,
      Key: filePath,
    };

    myBucket.upload(params, (error, data) => {
      res({ Key: data.Key, url: data.Location });
    });
  });
};

export default csvUploader;
