import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './uploadFiles.scss';

import { ReactComponent as ArrowLeftIcon } from 'assets/creatingProject/arrowLeft2.svg';
import { ReactComponent as IconList } from 'assets/creatingProject/iconList.svg';
import { ReactComponent as TickIcon } from 'assets/tick.svg';
import { ReactComponent as DeleteBucket } from 'assets/creatingProject/iconBucketDelete.svg';
import { ReactComponent as UploadIcon } from 'assets/creatingProject/uploadIcon.svg';

import UploadedGisFile from './components/UploadedGisFile';
import UploadedImageFile from './components/UploadedImageFile';
import UploadNewFileModal from '../../Modal/UploadNewFileModal';
import UploadingDoneModal from '../../Modal/UploadingDoneModal';
import NotGeoInPhoto from '../../Modal/NotGeoInPhoto';
import MaxFileSizeModal from '../../Modal/MaxFileSizeModal';

const UploadFiles = ({
  choiceOfUploadType,
  setUploadModalShow,
  setFiles,
  toggleShowMap,
  newProject,
}) => {
  const [deleteList, setDeleteList] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showUploadedDone, setShowUploadedDone] = useState(false);
  const [notGeoInPhoto, setNotGeoInPhoto] = useState(false);
  const [maxFileSizeModal, setMaxFileSizeModal] = useState(false);
  const [filesType, setFilesType] = useState('');
  const [fileCounter, setFileCounter] = useState(0);
  const [formats, setFormats] = useState([]);
  const [typePhoto, setTypePhoto] = useState('');
  const loadRef = useRef();

  const handlerChangeDeleteList = (e) => {
    let state = [];
    const val = e.target.value;

    if (!deleteList.includes(val)) {
      state = [...deleteList, val];
    } else {
      state = deleteList.filter((index) => index !== val);
    }
    if (state.length) {
      setDeleteList(state);
    } else {
      setDeleteList([]);
    }
  };

  const handlerDelete = () => {
    const files = newProject[choiceOfUploadType.type].filter((i, index) => !deleteList.includes(index.toString()));
    setFiles((state) => ({
      ...state,
      [choiceOfUploadType.type]: files,
    }));
    setDeleteList([]);
  };

  const handlerGisDelete = (idx) => () => {
    const files = newProject[choiceOfUploadType.type].filter((i, index) => index !== idx);
    setFiles((state) => ({
      ...state,
      [choiceOfUploadType.type]: files,
    }));
    setDeleteList([]);
  };

  const handleUploadFilesModalOpen = (type) => {
    switch (choiceOfUploadType.type) {
      case 'phoneImages':
        setTypePhoto(type);
        setFilesType('phoneImages');
        setShowUploadModal(true);
        setFormats(['.jpeg', '.jpg', '.JPG', '.png']);
        break;
      case 'droneImages':
        setTypePhoto(type);
        setFilesType('droneImages');
        setShowUploadModal(true);
        break;
      case 'gisFiles':
        setTypePhoto(type);
        setFilesType('gisFiles');
        setShowUploadModal(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (choiceOfUploadType.type !== 'gisFiles') {
      if (typePhoto === 'LI') {
        setFormats(['.las', '.laz', '.pcd']);
      } else {
        setFormats(['.jpeg', '.jpg', '.JPG', '.png']);
      }
    } else {
      setFormats(['.kmz', '.kml', '.shp']);
    }
  }, [typePhoto]);

  return (
    <>
      {showUploadModal && (
        <UploadNewFileModal
          type={typePhoto}
          close={setShowUploadModal}
          fieldForChange={filesType}
          setFileCounter={setFileCounter}
          formats={formats}
          loadRef={loadRef}
          setNewProjectData={setFiles}
          setShowUploadedDone={setShowUploadedDone}
          setNotGeoInPhoto={setNotGeoInPhoto}
          setShowUploadModal={setShowUploadModal}
          newProject={newProject}
          setMaxFileSizeModal={setMaxFileSizeModal}
        />
      )}
      {showUploadedDone && (
        <UploadingDoneModal
          close={setShowUploadedDone}
          fileCounter={fileCounter}
        />
      )}
      {maxFileSizeModal && (
        <MaxFileSizeModal
          close={setMaxFileSizeModal}
          fieldForChange={typePhoto}
        />
      )}
      {notGeoInPhoto && (
        <NotGeoInPhoto
          close={setNotGeoInPhoto}
        />
      )}
      <div className="UploadFiles">
        <div className="UploadFiles__uploadBlock">
          <div className="UploadFiles__uploadBlock-uploadBlockTitle">
            <div className="UploadFiles__uploadBlock-uploadBlockTitle-backBtn" onClick={() => setUploadModalShow(false)}>
              <ArrowLeftIcon className="arrowLeftIcon" />
            </div>
            <div className="UploadFiles__uploadBlock-uploadBlockTitle-title">{choiceOfUploadType.title}</div>
            <div className="UploadFiles__uploadBlock-uploadBlockTitle-quantityFiles">
              {choiceOfUploadType.type === 'phoneImages'
                && (
                  (`${newProject.phoneImages.length} file`)
                )}
              {choiceOfUploadType.type === 'droneImages'
              && (
                (`${newProject?.droneImages.length} file`)
              )}
              {choiceOfUploadType.type === 'gisFiles'
              && (
                (`${newProject.gisFiles.length} file`)
              )}
            </div>
            { deleteList.length ? (
              <div className="UploadFiles__uploadBlock-uploadBlockTitle-deleteBtn" onClick={handlerDelete}>
                <DeleteBucket />
              </div>
            ) : <div /> }
          </div>
          {choiceOfUploadType.type !== 'gisFiles' ? (
            <div className="UploadFiles__uploadBlock-button">
              <div
                className="UploadFiles__uploadBlock-button-uploadBtn margin"
                onClick={() => handleUploadFilesModalOpen('TH')}
              >
                <div className="UploadFiles__uploadBlock-button-uploadBtn-text">Upload TH</div>
              </div>
              <div
                className="UploadFiles__uploadBlock-button-uploadBtn"
                onClick={() => handleUploadFilesModalOpen('HD')}
              >
                <div className="UploadFiles__uploadBlock-button-uploadBtn-text">Upload HD</div>
              </div>
              {choiceOfUploadType.type === 'droneImages' && (
                <div
                  className="UploadFiles__uploadBlock-button-uploadBtn marginLeft"
                  onClick={() => handleUploadFilesModalOpen('LI')}
                >
                  <div className="UploadFiles__uploadBlock-button-uploadBtn-text">Upload LI</div>
                </div>
              )}
            </div>
          ) : (
            <div
              className="UploadFiles__uploadBlock-button-uploadBtn margin"
              onClick={() => handleUploadFilesModalOpen('gisFiles')}
            >
              <div className="UploadFiles__uploadBlock-button-uploadBtn-text">
                Upload GIS
                <UploadIcon className="uploadIcon" />
              </div>
            </div>
          )}
        </div>
        { choiceOfUploadType.type === 'gisFiles' ? (
          <div className="UploadFiles__containerWithGisFiles scroll-wrapper">
            {newProject[choiceOfUploadType.type].map((item, index) => (
              <UploadedGisFile
                key={index}
                i={item}
                item={index}
                toggleShowMap={toggleShowMap}
                onClick={handlerGisDelete}
              />
            ))}
          </div>
        ) : <div /> }
        { choiceOfUploadType.type !== 'gisFiles' ? (
          <div className="UploadFiles__containerWithPhoneFiles scroll-wrapper">
            <div className="UploadFiles__containerWithPhoneFiles-wrapper">
              <div className="UploadFiles__containerWithPhoneFiles-wrapper-header">
                <div className="checkMarkBox">
                  <label className="UploadFiles__containerWithPhoneFiles-checkLabel">
                    <input
                      value={deleteList.length}
                      type="checkbox"
                      checked={newProject[choiceOfUploadType.type].length === deleteList.length
                      && newProject[choiceOfUploadType.type].length !== 0}
                      onChange={(e) => {
                        if (e.target.value === newProject[choiceOfUploadType.type].length.toString()) {
                          setDeleteList([]);
                        } else {
                          setDeleteList(newProject[choiceOfUploadType.type].map((_, index) => index.toString()));
                        }
                      }}
                    />
                    <i><TickIcon /></i>
                  </label>
                  <div>Type</div>
                </div>
                <div className="denomination">
                  Image Title
                  <div className="iconList">
                    <IconList />
                  </div>
                </div>
                <div className="denomination">
                  Image Size
                  <div className="iconList">
                    <IconList />
                  </div>
                </div>
                <div>
                  Actions
                </div>
              </div>
            </div>
            <div className="UploadFiles__containerWithPhoneFiles-boxWithFiles">
              {newProject[choiceOfUploadType.type].map((item, index) => (
                <UploadedImageFile
                  key={index}
                  newProject={newProject}
                  item={index}
                  i={item}
                  toggleShowMap={toggleShowMap}
                  active={deleteList.includes(index.toString())}
                  onChange={handlerChangeDeleteList}
                  onClick={handlerGisDelete}
                />
              ))}
            </div>
          </div>
        ) : <div /> }
      </div>
    </>
  );
};

UploadFiles.propTypes = {
  choiceOfUploadType: PropTypes.objectOf(PropTypes.any),
  setUploadModalShow: PropTypes.func,
  setFiles: PropTypes.func,
  toggleShowMap: PropTypes.func,
  newProject: PropTypes.objectOf(PropTypes.any),
};

export default UploadFiles;
