import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

// import { ReactComponent as CommentIcon } from 'assets/notification/commentIcon.svg';
import { ReactComponent as NewCommentIcon } from 'assets/_v2.0/navBar/createNewCommentIcon.svg';
import { ReactComponent as UnionArrow } from 'assets/_v2.0/navBar/unionArrow.svg';

import Avatar from 'layouts/MainLayout/UserNotifications/uiComponents/Avatar';
import Time from 'layouts/MainLayout/UserNotifications/uiComponents/Time';

const CreateNewCommentType = ({ info }) => {
  const description = () => {
    if (info.parent) {
      return (
        <>
          Created a new comment on
          <span> {info.parent.title}</span> in
          <span> {info.project.title}:</span>
        </>
      );
    }
    return (
      <>
        Created a new comment on the map in <span>{info.project.title}:</span>
      </>
    );
  };

  return (
    <div className="Notification">
      <div className="Notification__avatar">
        <Avatar info={info} />
        <div className="avatarMarker">
          <NewCommentIcon />
        </div>
      </div>
      <div className="Notification__content">
        <div className="Notification__content-head">
          <div>
            <div className="user-name">{`${info.user.name} ${info.user.surName}`}</div>
            <div className="notification-desc">{description()}</div>
          </div>
        </div>
        <div className="CreateNewCommentType">
          <div className="unionArrowWrap">
            <UnionArrow className="unionArrow" />
          </div>
          <span>{info.comment.except}</span>
        </div>
        <Time info={info} />
      </div>
    </div>
  );
};

CreateNewCommentType.propTypes = {
  info: PropTypes.objectOf(PropTypes.any),
};

export default CreateNewCommentType;
