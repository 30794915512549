import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as MarkerIcon } from 'assets/notification/markerIcon.svg';

import { url, ApiKey } from 'config';
import Avatar from 'layouts/MainLayout/UserNotifications/uiComponents/Avatar';
import Time from 'layouts/MainLayout/UserNotifications/uiComponents/Time';

const MarkedNewIssueOnPhotoType = ({ info }) => {
  const mapScreen = [];

  if (info.parent.geo) {
    mapScreen.push('https://maps.googleapis.com/maps/api/staticmap');
    mapScreen.push(`?center=${info.parent.geo.latitude},${info.parent.geo.longitude}`);
    mapScreen.push(`&markers=icon:${url}/markerToImage${info.info.defectRating || 0}.png`);
    mapScreen.push(`|${info.parent.geo.latitude},${info.parent.geo.longitude}`);
    mapScreen.push('&zoom=13');
    mapScreen.push('&size=110x110');
    mapScreen.push(`&key=${ApiKey}`);
  }

  const defectRating = () => {
    switch (info.info.defectRating) {
      case '1':
        return 'Low';
      case '2':
        return 'Medium';
      case '3':
        return 'High';
      default:
        return '';
    }
  };

  const colorIcon = () => {
    switch (info.info.defectRating) {
      case '1':
        return 'yellow';
      case '2':
        return 'orange';
      case '3':
        return 'red';
      default:
        return 'green';
    }
  };

  return (
    <div className="Notification">
      <div className="Notification__avatar">
        <Avatar info={info} />
        <div className="avatarMarker">
          <MarkerIcon className={colorIcon()} />
        </div>
      </div>
      <div className="Notification__content">
        <div className="Notification__content-head">
          <div>
            <div className="user-name">{`${info.user.name} ${info.user.surName}`}</div>
            <div className="notification-desc">
              has identified an issue with defect rating
              <span> {defectRating()} </span>
              on image
              <span> {info.parent.title}</span> in
              <span> {info.project.title}:</span>
            </div>
          </div>
        </div>
        <div className="MarkedNewIssueOnPhotoType">
          <div className="MarkedNewIssueOnPhotoType__head">
            {mapScreen.length ? (
              <div className="MarkedNewIssueOnPhotoType__head-mapPhoto">
                <img
                  src={mapScreen.join('')}
                  alt="mapPasdasdhoto"
                />
              </div>
            ) : null}
            <div className="MarkedNewIssueOnPhotoType__head-photo" data-no-map={!mapScreen.length}>
              <a href={`${url}/project/${info.project._id}/${info.parent._id}`}>
                <img src={info.parent.thumb} alt="defect" />
              </a>
            </div>
          </div>
          <div className="MarkedNewIssueOnPhotoType__table">
            {info?.info?.poleTypes ? (
              <div className="MarkedNewIssueOnPhotoType__table__item">
                <div>Pole Types</div>
                <div>{info.info.poleTypes}</div>
              </div>
            ) : null}
            {info?.info?.poleComponent ? (
              <div className="MarkedNewIssueOnPhotoType__table__item">
                <div>Pole Component</div>
                <div>{info.info.poleComponent}</div>
              </div>
            ) : null}
            {info?.info?.defectList ? (
              <div className="MarkedNewIssueOnPhotoType__table__item">
                <div>Defect List</div>
                <div>{info.info.defectList}</div>
              </div>
            ) : null}
            {info?.info?.defectRating ? (
              <div className="MarkedNewIssueOnPhotoType__table__item">
                <div>Defect Rating</div>
                <div>{defectRating()}</div>
              </div>
            ) : null}
          </div>
        </div>
        <Time info={info} />
      </div>
    </div>
  );
};

MarkedNewIssueOnPhotoType.propTypes = {
  info: PropTypes.objectOf(PropTypes.any),
};

export default MarkedNewIssueOnPhotoType;
