import React from 'react';
import PropTypes from 'prop-types';
import './notGeoInPhoto.scss';

import { ReactComponent as Exclamation } from 'assets/exclamation-circle.svg';
import { ReactComponent as CloseIcon } from 'assets/navBar/close.svg';

import ModalWrapper from 'uiComponents/ModalWrapper';

const NotGeoInPhoto = ({ close }) => {
  const handleCloseModal = () => {
    close(false);
  };
  return (
    <div className="notGeoInPhotoModalWrap">
      <ModalWrapper
        title="Not Geo In Photo"
        toggleShow={close}
      >
        <div className="notGeoInPhoto">
          <div className="exclamationBox">
            <div className="exclamation">
              <Exclamation />
            </div>
            <div className="notGeoText">
              Some of the image(s) that you have uploaded do not contain a Geolocation on them. They will not be visible on the map.
            </div>
          </div>
          <div
            className="closeButton"
            onClick={handleCloseModal}
          >
            Close
            <CloseIcon className="closeIcon" />
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

NotGeoInPhoto.propTypes = {
  close: PropTypes.func,
};

export default NotGeoInPhoto;
