import React, { useEffect, useState } from 'react';
import propTypes from 'views/projectCreate/Steps/_types';

import { ReactComponent as SolarPanelIcon } from 'assets/creatingProject/solarPanelIcon.svg';
import { ReactComponent as Transmissions } from 'assets/creatingProject/transmissions.svg';
import { ReactComponent as WinTurbine } from 'assets/creatingProject/winTurbine.svg';

import { adminPanelPerm, isPermission } from 'components/Guards/permissions';
import Select from 'views/admin/uiComponetns/Select';
import useApp from 'hooks/useApp';
import useAuth from 'hooks/useAuth';
import Navigation from './Navigation';
import { isValidProperties } from './_validation';

const Properties = ({
  activeStep,
  setActiveStep,
  newProject,
  setNewProject,
  setConfirmDeleteProject,
}) => {
  const { user } = useAuth();
  const {
    companies,
    companiesAPI,
  } = useApp();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!newProject.get.company && !isPermission(user.role, adminPanelPerm.companies)) {
      newProject.set((prevState) => ({ ...prevState, company: user.company }));
    }
  }, [newProject.get.company]);

  const isValid = isValidProperties(newProject.get);

  return (
    <>
      <div className="Properties">
        <div className="Properties__wrapper">
          <div className="Properties__wrapper-title">Main project properties:</div>
          <div className="Properties__wrapper-form">
            <div className="Properties__wrapper-form-headline">Project Title</div>
            <input
              className="Properties__wrapper-form-input"
              placeholder="ex. 8101 Mia Circle"
              value={newProject.get.title}
              onChange={(e) => newProject.set({ ...newProject.get, title: e.target.value })}
              minLength={3}
              maxLength={16}
            />
            {errors.title && <span className="error-alert">{errors.title}</span>}
          </div>
          {isPermission(user.role, adminPanelPerm.companies) ? (
            <div className="Properties__wrapper-projectCompany">
              <Select
                action={(company) => {
                  newProject.set({ ...newProject.get, company, type: null });
                }}
                items={[
                  { value: '0', label: 'Select Company' },
                  ...companies.map((company) => ({ value: company._id, label: company.name })),
                ]}
                selected={newProject.get.company || 0}
              />
              {errors.company && <span className="error-alert">{errors.company}</span>}
            </div>
          ) : null}
          {newProject.get.company ? (
            <div className="Properties__wrapper-projectType">
              <div className="Properties__wrapper-projectType-headline">Project type</div>
              <div className="Properties__wrapper-projectType-block">
                <button
                  type="button"
                  className={`Properties__wrapper-projectType-block__item ${newProject.get.type === 'solar' ? 'active' : ''}`}
                  onClick={() => newProject.set({ ...newProject.get, type: 'solar' })}
                  disabled={!companiesAPI.getById(newProject.get.company).access.includes('solar')}
                  data-title="You can't create this type of project"
                >
                  <SolarPanelIcon />
                  <span className="projectType-title">Solar Panel</span>
                </button>
                <button
                  type="button"
                  className={`Properties__wrapper-projectType-block__item ${newProject.get.type === 'transmission' ? 'active' : ''}`}
                  onClick={() => newProject.set({ ...newProject.get, type: 'transmission' })}
                  disabled={!companiesAPI.getById(newProject.get.company).access.includes('transmission')}
                >
                  <Transmissions />
                  <span className="projectType-title">Transmission</span>
                </button>
                <button
                  type="button"
                  className={`Properties__wrapper-projectType-block__item ${newProject.get.type === 'wind' ? 'active' : ''}`}
                  onClick={() => newProject.set({ ...newProject.get, type: 'wind' })}
                  disabled={!companiesAPI.getById(newProject.get.company).access.includes('wind')}
                >
                  <WinTurbine />
                  <span className="projectType-title">Wind Turbine</span>
                </button>
              </div>
              {errors.type && <span className="error-alert">{errors.type}</span>}
            </div>
          ) : null}
        </div>
      </div>
      <Navigation
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        next={!isValid.block}
        handlerError={() => setErrors(isValid.errors)}
        setNewProject={setNewProject}
        setConfirmDeleteProject={setConfirmDeleteProject}
      />
    </>
  );
};

Properties.propTypes = propTypes;

export default Properties;
