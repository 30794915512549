import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as SaveIcon } from 'assets/_v2.0/admin/saveIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/adminTable/closeIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/adminFilters/actionDeleteIcon.svg';

import OffCanvasWrapper from 'uiComponents/OffCanvasWrapper';
import ConfirmDelete from 'uiComponents/ConfirmDelete';
import FormGroup from 'views/admin/uiComponetns/FormGroup';
import { isPermission, projectPerm } from 'components/Guards/permissions';

const ProjectSetting = ({ project, projectAPI, user }) => {
  const [editProject, changeEditProject] = useState(project);
  const handleDeleteClick = () => {
    ReactDOM.render((
      <ConfirmDelete
        title="project"
        name={project.title}
        toggleShow={() => ReactDOM.unmountComponentAtNode(document.getElementById('modal-portal'))}
        action={() => projectAPI.delete(project)}
      />
    ), document.getElementById('modal-portal'));
  };
  const closeOffCanvas = () => ReactDOM.unmountComponentAtNode(document.getElementById('offCanvas-portal'));
  const handleSave = () => {
    projectAPI.edit(editProject);
    closeOffCanvas();
  };
  const handleChangeInput = (e) => {
    changeEditProject({ ...editProject, [e.target.name]: e.target.value });
  };
  const DeleteBtn = isPermission(user.role, projectPerm.delete)
    ? <DeleteIcon onClick={handleDeleteClick} />
    : null;

  return (
    <div className="ProjectSetting">
      <OffCanvasWrapper
        toggleShow={closeOffCanvas}
        title="Edit Project"
        titleActions={DeleteBtn}
        bottomAction={(
          <>
            <button type="button" className="actions-btn clean" onClick={closeOffCanvas}>Cancel <CloseIcon /></button>
            <button type="button" className="actions-btn accent" onClick={handleSave}>Save Change <SaveIcon /></button>
          </>
        )}
      >
        <FormGroup>
          <div className="label">Title:</div>
          <input
            autoComplete="on"
            name="title"
            type="text"
            placeholder="Project title"
            value={editProject.title}
            onChange={handleChangeInput}
          />
        </FormGroup>
        <FormGroup>
          <div className="label">Description:</div>
          <textarea
            autoComplete="on"
            name="description"
            placeholder="Project description"
            value={editProject.description}
            onChange={handleChangeInput}
          />
        </FormGroup>
      </OffCanvasWrapper>
    </div>
  );
};

ProjectSetting.propTypes = {
  project: PropTypes.objectOf(PropTypes.any),
  projectAPI: PropTypes.objectOf(PropTypes.any),
  user: PropTypes.objectOf(PropTypes.any),
};

export default ProjectSetting;
