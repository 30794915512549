import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { url } from 'config';
import useAuth from '../hooks/useAuth';
import axios from '../utils/axios';

let socket = null;

const initialState = {
  notifications: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD': {
      return {
        ...state,
        notifications: [
          ...action.payload,
          ...state.notifications,
        ],

      };
    }
    case 'INIT': {
      return {
        ...state,
        notifications: [...action.payload],
      };
    }
    case 'CONFIRMATIONPROJECT': {
      return {
        ...state,
        notifications: state.notifications.map((item) => {
          if (item._id === action.payload._id) return action.payload;
          return item;
        }),
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

const NotificationsContext = createContext({
  ...initialState,
});

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    const initialize = () => {
      if (socket) socket.disconnect();
      socket = io(url, {
        transports: ['websocket', 'polling', 'flashsocket'],
        query: `user=${user._id}`,
      });
      socket.on('connect', () => {
        console.log('socket connect');
      });

      socket.on('disconnect', (reason) => {
        if (reason === 'io server disconnect') {
          socket.connect();
        }
      });

      socket.on('connect_error', (error) => {
        console.log('socket error : ', error.message);
      });

      socket.on('init', (data) => {
        dispatch({
          type: 'INIT',
          payload: Array.isArray(data) ? data : [data],
        });
      });

      socket.on('notification', (data) => {
        dispatch({
          type: 'ADD',
          payload: Array.isArray(data) ? data : [data],
        });
      });
    };
    const reset = () => {
      dispatch({
        type: 'INIT',
        payload: [],
      });
    };

    if (isAuthenticated) {
      initialize();
    } else {
      reset();
    }
  }, [isAuthenticated]);

  const confirmationProject = (notification) => {
    dispatch({
      type: 'CONFIRMATIONPROJECT',
      payload: notification,
    });
  };

  const deleteNotification = (id) => {
    try {
      axios.delete(`${url}/api/notification/${id}`);
      const notifications = state.notifications.filter((notif) => notif._id !== id);

      dispatch({
        type: 'INIT',
        payload: notifications,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const changeStatus = (id) => {
    try {
      const notifications = [...state.notifications];
      const notificationIndex = state.notifications.findIndex((el) => el._id === id);
      axios.put(`${url}/api/notification/viewed/${id}`);
      notifications[notificationIndex].status = 1;
      dispatch({
        type: 'INIT',
        payload: notifications,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        ...state,
        confirmationProject,
        deleteNotification,
        changeStatus,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationsContext;
