/* eslint-disable react/prop-types */
import React, { memo } from 'react';

import './style.scss';

const InfoWindow = memo(({ data, close }) => {
  return (
    <div className="infoWindow-wrapper">
      <div className="infoWindow">
        <div className="card-panel">
          <div className="infoWindow-bold">Pole info</div>
          <div className="infoWindow-close" onClick={close} />
        </div>
        {Object.keys(data).map((key) => (
          <div key={key} className="card-panel">
            <div>{key}</div>
            <div className="infoWindow-bold">{data[key]}</div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default InfoWindow;
