import { url } from 'config';
import axios from 'utils/axios';

const shp2kml = async (shp) => {
  const data = new FormData();
  data.append('shapefile', shp);
  try {
    const kml = await axios.post(`${url}/api/convert/shp2kml`, data, { responseType: 'arraybuffer' });
    kml.data.name = `${shp.name.split('.')[0]}.kml`;
    kml.data.size = shp.size;
    return kml.data;
  } catch (err) {
    return Promise.reject(new Error(JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err))).message || 'Something went wrong'));
  }
};

export default shp2kml;
