export const getDistance = (dist, unit) => {
  if (!dist) {
    return 0;
  }

  let distance = dist;

  switch (unit) {
    case 'm':
      //выводит в метрах
      break;
    case 'ft':
      distance *= 3.28084;
      break;
    case 'km':
      distance /= 1000;
      break;
    case 'mi':
      distance *= 0.000621371;
      break;
    default:
      break;
  }

  return distance.toFixed(2);
};

export const getArea = (a, unit) => {
  if (!a) {
    return 0;
  }

  let area = a;

  switch (unit) {
    case 'm':

      break;
    case 'ft':
      area *= 10.763910417;
      break;
    case 'km':
      area = (area / 10000) / 100;
      break;
    case 'mi':
      area = (area / 10000 / 2.589988110336) / 100;
      break;
    default:
      break;
  }

  return area.toFixed(2);
};

export const filterProject = (projects, filters) => {
  const filterKeys = Object.keys(filters);
  return projects.filter((project) => {
    return filterKeys.every((key) => {
      switch (key) {
        case 's':
          if (!filters[key]) return true;
          return project.title.toLowerCase().includes(filters[key].toLowerCase());
        case 'status':
          if (!filters[key].length) return true;
          return filters[key].some((type) => {
            return project.status === type;
          });
        case 'technologies':
          if (!filters[key].length) return true;
          return filters[key].some((type) => {
            switch (type) {
              case 'droneImages':
                if (!project.timelines[0].images.length) return false;
                return project.timelines[0].images.filter((img) => {
                  return img.type === 'droneImages';
                }).length;
              case 'phoneImages':
                if (!project.timelines[0].images.length) return false;
                return project.timelines[0].images.filter((img) => {
                  return img.type === 'phoneImages';
                }).length;
              case 'combine':
                if (!project.timelines[0].images.length) return false;
                return project.timelines[0].images.map((_) => _.type).includes('droneImages')
                  && project.timelines[0].images.map((_) => _.type).includes('phoneImages');
              case 'other':
                return project.timelines[0].images.length === 0;
              default:
                return true;
            }
          });
        case 'type':
          if (!filters[key].length) return true;
          return filters[key].some((type) => {
            return project.type === type;
          });
        case 'dateRange':
          if (!filters[key]) return true;
          return (
            new Date(project.timelines[0].dateScan) > filters[key][0]
            && new Date(project.timelines[0].dateScan) < filters[key][1]
          );
        default:
          return true;
      }
    });
  });
};
