import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { queue } from 'async-es';
import convertSize from 'utils/convertSize';
import { awsConf, url } from 'config';
import axios from './axios';

AWS.config.update({
  accessKeyId: awsConf.accessKeyId,
  secretAccessKey: awsConf.secretAccessKey,
});
const myBucket = new AWS.S3({
  params: { Bucket: awsConf.S3_BUCKET },
  region: awsConf.REGION,
});
let asyncUpload = null;
let _uuid;

const fileUploader = (files, uuid, loadRef) => {
  _uuid = uuid;
  asyncUpload = queue((task, callback) => {
    const params = {
      ACL: 'public-read',
      Body: task.item,
      Bucket: awsConf.S3_BUCKET,
      Key: `${uuid}/${task.key}/${uuidv4()}.${task.item.name.split('.').pop()}`,
    };

    myBucket.upload(params, (error, data) => {
      if (error) {
        return callback(error, null);
      }
      return callback(error, {
        url: data.Location,
        Key: data.Key,
        geo: task.item.geo || '',
        resolution: task.item.resolution || '',
        size: convertSize(task.item.size),
        title: task.item.name,
        exif: task.item.exif || {},
        type: task.key,
        fileType: task.item.fileType,
        thumb: task.key !== 'gisFiles' && task.item.fileType !== 'LI' ? data.Location.split('.s3.').join('-resized.s3.') : '',
        low: task.key !== 'gisFiles' && task.item.fileType !== 'LI' ? data.Location.split('.s3.').join('-resized.s3.').split(data.Key)[0] + data.Key.split('.').map((item, index) => (!index ? `${item}-low` : item)).join('.') : '',
        medium: task.key !== 'gisFiles' && task.item.fileType !== 'LI' ? data.Location.split('.s3.').join('-resized.s3.').split(data.Key)[0] + data.Key.split('.').map((item, index) => (!index ? `${item}-medium` : item)).join('.') : '',
      });
    });
  }, 3);

  let fileList = [];
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const key in files) {
    fileList = [...fileList, ...files[key].map((item) => ({ key, item }))];
  }
  const list = [];
  return new Promise((res) => {
    asyncUpload.push(fileList, (error, data) => {
      if (!error) {
        list.push(data);
        if (loadRef?.current) {
          const percent = (list.length * 100) / fileList.length;
          // eslint-disable-next-line no-param-reassign
          loadRef.current.innerHTML = `<div style="position: relative">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="23.529411764705884 23.529411764705884 44 47.05882352941177"
               style="transform: rotate(-90deg);">
               <circle
                 fill="transparent"
                 cx="47.05882352941177"
                 cy="47.05882352941177"
                 r="20"
                 stroke-width="1.5"
                 stroke-dasharray="125.664"
                 stroke-dashoffset="0"
                 stroke="blue"
                 >
               </circle>
              <circle
                fill="transparent"
                cx="47.05882352941177"
                cy="47.05882352941177"
                r="20"
                stroke-width="1.5"
                stroke-dasharray= ${2 * 20 * Math.PI}
                stroke-dashoffset=${(percent / 100) * 2 * 20 * Math.PI}
                stroke="gray"
              >
              </circle>
            </svg>
            <span
                style="position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                width: 40px;
                justify-content: center;
                white-space: nowrap;"
            >
              ${list.length} / ${fileList.length}
            </span>
          <div>`;
        }
      }
    });

    asyncUpload.drain(() => {
      asyncUpload.kill();
      asyncUpload = null;
      res(list);
    });
  });
};
export const abortUpload = () => {
  if (!asyncUpload) return;
  asyncUpload.kill();
  asyncUpload = null;
  axios.post(`${url}/api/project/cleaningUpload/${_uuid}`);
};

export default fileUploader;
