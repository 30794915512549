import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as MarkersIcon } from 'assets/_v2.0/headerIcons/markers.svg';
import { ReactComponent as WindIcon } from 'assets/_v2.0/project/windIcon.svg';
import { ReactComponent as PhoneIcon } from 'assets/_v2.0/project/phoneIcon.svg';
import { ReactComponent as DroneIcon } from 'assets/_v2.0/project/droneIcon.svg';
import { ReactComponent as CalendarIcon } from 'assets/_v2.0/project/calendarIcon.svg';
import { ReactComponent as TransmissionsIcon } from 'assets/_v2.0/project/transmissionsIcon.svg';
import { ReactComponent as SolarIcon } from 'assets/_v2.0/project/solarIcon.svg';

import { ReactComponent as ArrowIcon } from 'assets/project/arrowIcon.svg';

import useApp from 'hooks/useApp';
import { url } from 'config';
import ProjectActions from './ProjectActions';

const ProjectItem = (props) => {
  const {
    project, setActive, active, showOnMap,
  } = props;
  const { usersAPI } = useApp();
  const drone = project.timelines[0].images.filter((img) => img.type === 'droneImages');
  const phone = project.timelines[0].images.filter((img) => img.type === 'phoneImages');

  const renderUsers = (data) => {
    const items = usersAPI.getByIds(data);

    if (!items.length) return '-';

    return items.map((user, index) => {
      if (index < 3) {
        return (
          <div key={user._id} className="projectItem__users-item" title={user.name || ''}>
            {user.avatar ? (
              <img alt={user.name || ''} src={`${url}/${user.avatar}`} />
            ) : (
              <span>{user.name[0].toUpperCase()}</span>
            )}
          </div>
        );
      }

      if (index >= 2 && index === items.length - 1) {
        return (
          <div key={user._id} className="projectItem__users-item">
            <span>+{items.length - 3}</span>
          </div>
        );
      }

      return null;
    });
  };

  const types = {
    transmission: <TransmissionsIcon />,
    solar: <SolarIcon />,
    wind: <WindIcon />,
  };

  const itemClasses = ['projectItemWrapper'];

  itemClasses.push(project.status.toLowerCase().replace(/\s/g, ''));

  if (active) {
    itemClasses.push('selected');
  }

  return (
    <div className={itemClasses.join(' ')} id={project._id}>
      <div className="projectItem__row">
        <div className="projectItem__col">
          <div className="projectItem__photo-type-icons">
            <div className="projectItem__photo-type-icons__type">
              <div className="projectItem__photo-type-icons__item">
                {types[project.type]}
              </div>
            </div>
            {drone.length || phone.length ? (
              <div className="projectItem__photo-type-icons__images">
                {drone.length ? (
                  <div className="projectItem__photo-type-icons__item"><DroneIcon /></div>
                ) : null}
                {phone.length ? (
                  <div className="projectItem__photo-type-icons__item"><PhoneIcon /></div>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="projectItem__badges">
            <div className="projectItem__badges-scan">
              <CalendarIcon />
              <span>Last scan: {new Date(project.timelines[0].dateScan).toLocaleDateString()}</span>
            </div>
            <div className="projectItem__badges-name">
              <i>{project.status}</i>
              <span>{project.title}</span>
            </div>
          </div>
          <div className="projectItem__users">
            {renderUsers(project.users)}
          </div>
        </div>
        <div className="projectItem__col">
          {showOnMap ? <ProjectActions {...props} /> : <div />}
          <div>
            {showOnMap ? (
              <div
                className={`projectItem__set-on-map ${active ? 'active' : ''}`}
                onClick={() => {
                  if (setActive) {
                    if (!active) return setActive(project);
                    return setActive({});
                  }
                  return false;
                }}
              >
                <MarkersIcon />
              </div>
            ) : null}
            <Link
              className="projectItem__link"
              to={`/project/${project._id}`}
              title="Single Page"
            >
              <ArrowIcon />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectItem.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  actionDelete: PropTypes.func,
  setActive: PropTypes.func,
  active: PropTypes.bool,
  showOnMap: PropTypes.bool,
};

export default ProjectItem;
