import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import { ReactComponent as DeleteIcon } from 'assets/_v2.0/project/deleteIcon.svg';

import ModalWrapper from 'uiComponents/ModalWrapper';

const ConfirmDelete = ({
  title, toggleShow, action,
}) => {
  const handleClose = () => toggleShow(false);
  const handleAccept = () => action();

  return (
    <ModalWrapper
      title={<><DeleteIcon /> Delete {title}</>}
      toggleShow={toggleShow}
    >
      <div className="ModalConfirm__content-info">
        <p>Do you want to delete this {title}?</p>
      </div>
      <div className="ModalConfirm__content-action">
        <div className="action-btn cancel" onClick={handleClose}>Cancel</div>
        <div className="action-btn delete" onClick={handleAccept}>Yes, Delete <DeleteIcon /></div>
      </div>
    </ModalWrapper>
  );
};

ConfirmDelete.propTypes = {
  title: PropTypes.string.isRequired,
  toggleShow: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

export default ConfirmDelete;
